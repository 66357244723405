import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
  salesinvoiceList,
  salesinvoiceUpdate,
  salesinvoiceDelete,
  salesinvoiceAdd,
  salesinvoiceById,
  salesinvoicePDF,
  salesinvoiceProforma,
  salesInvoiceDraft,
  salesInvoice,
  selesData,
  getInvoiceNumber,
  getPayments,
  getInvoiceDetail,
  createPayment,
  getpaymentStat,
  zatcaValidation,
  zatcaView,
  searchInvoice,
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from "../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import alertmessage from "../../utils/alert";
const namespace = "salesinvoice";

const initialState = {
  data: null,
  status: "idle",
  error: null,
  salesinvoiceCount: null,
  salesinvoiceDatas: null,
  salesinvoiceDatasByID: null,
  pdfMessage: "",
  generateLoading: false,
  loading: "initial",
  addloading: "initial",
  deleteloading: "initial",
  viewZatcaloading: "initial",
  setProformaLoading: "initial",
  setDraftLoading: "initial",
  setInvoiceStatLoading: "initial",
  StatDatas: '',
  getSalesStatLoading: "initial",
  getInvoiceloading: "initial",
  getPaymentListloading: "initial",
  getInvoiceDetailsloading: "initial",
  createPaymentDetailsloading: "initial",
  getZatcaValidationloading: "initial",
  paymentStatloading: "initial",
  invoice_num: "",
  payments: [],
  invoiceDetails: [],
  statData: [],
  zatcaData: []
};

export const salesinvoiceData = createAsyncThunk(
  `${namespace}/salesinvoiceData`,
  async (
    { query = "", page = 0, limit = 0, sortby = "description", order = "asc" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await salesinvoiceList(query, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const salesinvoiceDataByID = createAsyncThunk(
  `${namespace}/salesinvoiceDataByID`,
  async ({ editID }, { rejectWithValue, dispatch }) => {
    try {
      console.log("interdata123");
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await salesinvoiceById(editID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const getInvoiceReducer = createAsyncThunk(
  `${namespace}/getInvoiceNumber`,
  async ({ editID }, { rejectWithValue, dispatch }) => {
    try {
      console.log("Get DATYA");
      const data = await getInvoiceNumber();
      console.log(data, 'io data');
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const getZatcaValidation = createAsyncThunk(
  `${namespace}/getZatcaValidation`,
  async ({ ID }, { rejectWithValue, dispatch }) => {
    console.log(ID, 'ZATCA REDUCER ID')
    try {
      console.log("Get zatca validation");
      const data = await zatcaValidation(ID);
      console.log(data, 'io data');
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const createPaymentDetails = createAsyncThunk(
  `${namespace}/createPaymentDetails`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "Get DATYA");
      const data = await createPayment(formData);
      console.log(data, 'io data');
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const viewZatca = createAsyncThunk(
  `${namespace}/viewZatca`,
  async (ID, { rejectWithValue, dispatch }) => {
    console.log(ID, 'Zatca View Reducer')
    try {
      const data = await zatcaView(ID);
      return data
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
)

export const getPaymentList = createAsyncThunk(
  `${namespace}/getPaymentList`,
  async ({ editID }, { rejectWithValue, dispatch }) => {
    try {
      console.log("Get DATYA");
      const data = await getPayments();
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const getInvoiceDetails = createAsyncThunk(
  `${namespace}/getInvoiceDetails`,
  async ({ query = "", page = 0, limit = 0, sortby = "description", order = "asc" }, { rejectWithValue, dispatch }) => {
    try {
      console.log("Get DATYA");
      const data = await getInvoiceDetail(query, page, limit, sortby, order);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);


export const UpdateSalesinvoice = createAsyncThunk(
  `${namespace}/UpdateSalesinvoice`,
  async ({ postData, editID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(editID, "interdata");
      let payload = EncryptDecrypt.encryptdata(postData, secretKey);
      const data = await salesinvoiceUpdate({ payload: payload }, editID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const AddSalesinvoice = createAsyncThunk(
  `${namespace}/AddSalesinvoice`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "interdata");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await salesinvoiceAdd({ payload: payload });
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const DeleteSalesinvoice = createAsyncThunk(
  `${namespace}/DeleteSalesinvoice`,
  async ({ deleteID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(deleteID, "interdata");
      const data = await salesinvoiceDelete(deleteID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const searchInvoiceDetails = createAsyncThunk(
  `${namespace}/search`,
  async (query, { rejectWithValue, dispatch }) => {
    console.log('search query', query)
    try {
      const data = await searchInvoice(query?.e);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
)

export const getSalesStat = createAsyncThunk(
  `${namespace}/getSalesStat`,
  async ({ deleteID }, { rejectWithValue, dispatch }) => {
    try {
      const data = await selesData();
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const generatePdf = createAsyncThunk(
  `${namespace}/generatepdf`,
  async (ID, { rejectWithValue, dispatch }) => {
    try {
      const data = await salesinvoicePDF(ID);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const setProforma = createAsyncThunk(
  `${namespace}/proforma`,
  async (ID, { rejectWithValue, dispatch }) => {
    try {
      const data = await salesinvoiceProforma(ID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setDraft = createAsyncThunk(
  `${namespace}/draft`,
  async (ID, { rejectWithValue, dispatch }) => {
    try {
      const data = await salesInvoiceDraft(ID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const setInvoiceStat = createAsyncThunk(
  `${namespace}/invoice`,
  async (ID, { rejectWithValue, dispatch }) => {
    try {
      const data = await salesInvoice(ID);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const paymentStat = createAsyncThunk(
  `${namespace}/paymentstat`,
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const data = await getpaymentStat()
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(data?.data?.data, secretKey)
      );
      return payloaddatas;
    } catch (error) {
      return rejectWithValue(error.response)
    }
  }
)

export const salesinvoiceSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearSinvLoadingDatas: (state) => {
      state.loading = "initial";
      state.addloading = "initial";
      state.deleteloading = "initial";
      state.setProformaLoading = "initial";
      state.setDraftLoading = "initial";
      state.setInvoiceStatLoading = "initial";
      state.getSalesStatLoading = "initial";
      state.updateloading = "initial";
      state.getInvoiceloading = "initial";
      state.getPaymentListloading = "initial";
      state.createPaymentDetailsloading = "initial";
      state.getZatcaValidationloading = "initial";
      state.viewZatcaloading = "initial";
    },
    clearSinvUpdateLoading: (state) => {
      state.updateloading = "initial";
    },
    clearSinvDeleteLoading: (state) => {
      state.deleteloading = "initial";
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
    clearPdfData: (state) => {
      state.pdfMessage = "";
      state.generateLoading = false;
    },
  },
  extraReducers: (builder) => {
    console.log(builder, "payload");
    builder.addCase(salesinvoiceData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(salesinvoiceData.fulfilled, (state, { payload }) => {
      console.log(payload, "payload");
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.salesinvoiceCount = payloaddatas?.count;
      state.salesinvoiceDatas = payloaddatas?.data;
    });
    builder.addCase(salesinvoiceData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(salesinvoiceDataByID.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(salesinvoiceDataByID.fulfilled, (state, { payload }) => {
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, "payload");
      state.salesinvoiceDatasByID = payloaddatas?.response;
    });
    builder.addCase(salesinvoiceDataByID.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AddSalesinvoice.pending, (state) => {
      state.addloading = API_STATUS.PENDING;
    });
    builder.addCase(AddSalesinvoice.fulfilled, (state, { payload }) => {
      state.addloading = API_STATUS.FULFILLED;
    });
    builder.addCase(AddSalesinvoice.rejected, (state, action) => {
      state.addloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(searchInvoiceDetails.pending, (state) => {
      state.addloading = API_STATUS.PENDING;
    });
    builder.addCase(searchInvoiceDetails.fulfilled, (state, { payload }) => {
      state.addloading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.salesinvoiceCount = payloaddatas?.count;
      state.salesinvoiceDatas = payloaddatas?.data;
    });
    builder.addCase(searchInvoiceDetails.rejected, (state, action) => {
      state.addloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(setProforma.pending, (state) => {
      state.setProformaLoading = API_STATUS.PENDING;
    });
    builder.addCase(setProforma.fulfilled, (state, { payload }) => {
      state.setProformaLoading = API_STATUS.FULFILLED;
      // toastr.success("Updated");
    });
    builder.addCase(setProforma.rejected, (state, action) => {
      state.setProformaLoading = API_STATUS.REJECTED;
      // state.errorMessage = action?.payload?.data;
    });
    builder.addCase(setInvoiceStat.pending, (state) => {
      state.setInvoiceStatLoading = API_STATUS.PENDING;
    });
    builder.addCase(setInvoiceStat.fulfilled, (state, { payload }) => {
      state.setInvoiceStatLoading = API_STATUS.FULFILLED;
      // toastr.success("Updated");
    });
    builder.addCase(setInvoiceStat.rejected, (state, action) => {
      state.setInvoiceStatLoading = API_STATUS.REJECTED;
      // state.errorMessage = action?.payload?.data;
    });
    builder.addCase(setDraft.pending, (state) => {
      state.setDraftLoading = API_STATUS.PENDING;
    });
    builder.addCase(setDraft.fulfilled, (state, { payload }) => {
      state.setDraftLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(setDraft.rejected, (state, action) => {
      state.setDraftLoading = API_STATUS.REJECTED;
      // state.errorMessage = action?.payload?.data;
    });
    builder.addCase(generatePdf.pending, (state) => {
      state.generateLoading = API_STATUS.PENDING;
      toastr.warning("PDF loading please wait");
    });
    builder.addCase(generatePdf.fulfilled, (state, { payload }) => {
      state.generateLoading = API_STATUS.FULFILLED;
      state.pdfMessage = payload.data?.file_name;
      toastr.success("PDF opened in new tab");
    });
    builder.addCase(generatePdf.rejected, (state, action) => {
      state.generateLoading = API_STATUS.REJECTED;
      // state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getSalesStat.pending, (state) => {
      state.getSalesStatLoading = API_STATUS.PENDING;
    });
    builder.addCase(getSalesStat.fulfilled, (state, { payload }) => {
      state.getSalesStatLoading = API_STATUS.FULFILLED;
      console.log(payload, 'dtaaaa');
      let data = JSON.parse(
        EncryptDecrypt.decryptdata(payload.data?.stats, secretKey)
      );
      state.StatDatas = data;
    });
    builder.addCase(getSalesStat.rejected, (state, action) => {
      state.getSalesStatLoading = API_STATUS.REJECTED;
      // state.errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateSalesinvoice.pending, (state) => {
      state.updateloading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateSalesinvoice.fulfilled, (state, { payload }) => {
      state.updateloading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateSalesinvoice.rejected, (state, action) => {
      state.updateloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(DeleteSalesinvoice.pending, (state) => {
      state.deleteloading = API_STATUS.PENDING;
    });
    builder.addCase(DeleteSalesinvoice.fulfilled, (state, { payload }) => {
      state.deleteloading = API_STATUS.FULFILLED;
    });
    builder.addCase(DeleteSalesinvoice.rejected, (state, action) => {
      state.deleteloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(viewZatca.pending, (state) => {
      state.viewZatcaloading = API_STATUS.PENDING;
    });
    builder.addCase(viewZatca.fulfilled, (state, { payload }) => {
      state.viewZatcaloading = API_STATUS.FULFILLED;
      let data = JSON.parse(
        EncryptDecrypt.decryptdata(payload.data, secretKey)
      );
      state.zatcaData = data;
      console.log('payload of data zatca', data);

    });
    builder.addCase(viewZatca.rejected, (state, action) => {
      state.viewZatcaloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getInvoiceReducer.pending, (state) => {
      state.getInvoiceloading = API_STATUS.PENDING;
    });
    builder.addCase(getInvoiceReducer.fulfilled, (state, { payload }) => {
      state.getInvoiceloading = API_STATUS.FULFILLED;
      state.invoice_num = payload?.data;
    });
    builder.addCase(getInvoiceReducer.rejected, (state, action) => {
      state.getInvoiceloading = API_STATUS.REJECTED;
    });
    builder.addCase(getPaymentList.pending, (state) => {
      state.getPaymentListloading = API_STATUS.PENDING;
    });
    builder.addCase(getPaymentList.fulfilled, (state, { payload }) => {
      state.getPaymentListloading = API_STATUS.FULFILLED;
      console.log('payload of payment', payload);
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.data, secretKey)
      );
      state.payments = payloaddatas?.data;
    });
    builder.addCase(getPaymentList.rejected, (state, action) => {
      state.getPaymentListloading = API_STATUS.REJECTED;
    });
    builder.addCase(getInvoiceDetails.pending, (state) => {
      state.getInvoiceDetailsloading = API_STATUS.PENDING;
    });
    builder.addCase(getInvoiceDetails.fulfilled, (state, { payload }) => {
      state.getInvoiceDetailsloading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.data, secretKey)
      );
      console.log('payload of payment', payloaddatas?.data);
      state.invoiceDetails = payloaddatas?.data;
    });
    builder.addCase(getInvoiceDetails.rejected, (state, action) => {
      state.getInvoiceDetailsloading = API_STATUS.REJECTED;
    });
    builder.addCase(createPaymentDetails.pending, (state) => {
      state.createPaymentDetailsloading = API_STATUS.PENDING;
    });
    builder.addCase(createPaymentDetails.fulfilled, (state, { payload }) => {
      state.createPaymentDetailsloading = API_STATUS.FULFILLED;
    });
    builder.addCase(createPaymentDetails.rejected, (state, action) => {
      state.createPaymentDetailsloading = API_STATUS.REJECTED;
    });
    builder.addCase(getZatcaValidation.pending, (state) => {
      state.getZatcaValidationloading = API_STATUS.PENDING;
    });
    builder.addCase(getZatcaValidation.fulfilled, (state, { payload }) => {
      state.getZatcaValidationloading = API_STATUS.FULFILLED;
    });
    builder.addCase(getZatcaValidation.rejected, (state, action) => {
      state.getZatcaValidationloading = API_STATUS.REJECTED;
    });
    builder.addCase(paymentStat.pending, (state) => {
      state.paymentStatloading = API_STATUS.PENDING;
    });
    builder.addCase(paymentStat.fulfilled, (state, { payload }) => {
      state.paymentStatloading = API_STATUS.FULFILLED;
      console.log(payload, 'payment stat payload');
      state.statData = payload;
    });
    builder.addCase(paymentStat.rejected, (state, action) => {
      state.paymentStatloading = API_STATUS.REJECTED;
    });
  },
});

export const {
  clearData,
  clearSinvUpdateLoading,
  clearSinvLoadingDatas,
  clearSinvDeleteLoading,
  clearErrormsg,
  clearPdfData,
} = salesinvoiceSlice.actions;

export const salesinvoiceSelector = (state) => state.salesinvoice;

export default salesinvoiceSlice.reducer;
