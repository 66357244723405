import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constants";
import { signIn } from "../../services/api";

const namespace = "signInConfiguration";

const initialState = {
  loading: "initial",
  errorMessage: "",
  signInData: null,
  signInLoading: "initial",
  updateSchedulerLoading: "initial",
  count: 0,
};

export const signInScheduler = createAsyncThunk(
  `${namespace}/signInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await signIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


const signInConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignInData: () => {
      return initialState;
    },
    logOut: () => {
        localStorage.clear();
        window.location.reload(true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInScheduler.pending, (state) => {
      state.signInLoading = API_STATUS.PENDING;
    });
    builder.addCase(signInScheduler.fulfilled, (state, { payload }) => {
      state.signInLoading = API_STATUS.FULFILLED;
      state.signInData = payload;
      console.log(payload);
      localStorage.setItem("authToken", payload?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payload?.username);
    });
    builder.addCase(signInScheduler.rejected, (state, action) => {
      state.signInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearSignInData,logOut } = signInConfigSlice.actions;

export const signInSelector = (state) => state.signInConfiguration;

export default signInConfigSlice.reducer;
