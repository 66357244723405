import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";

const AppRoute = () => {
  const navigate = useNavigate();
  console.log("inasdsad");
  if (document.body) document.body.setAttribute("data-sidebar", "dark");
  const isAuth = sessionStorage.getItem("isAuthenticated");
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const roleID = localStorage.getItem("role_id");

  const template = localStorage.getItem("settings");
  console.log(isAuthenticated, "roleID==");
  if (!isAuthenticated) {
  localStorage.clear();
  return <Navigate to="/login" replace={true} />;
  } else {
    if (!template)
      localStorage.setItem("template", JSON.stringify({ sidebar: false }));
    return (
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    );
  }
};
//(props) => (!isAuthenticated ? <Navigate to="/login" replace={true} /> : <Component {...props} />)

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AppRoute);
