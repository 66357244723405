import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signInValidationSchema } from "../utils/Validation";
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
// import images
import logodark from "../assets/images/logo-dark.png";
import logolight from "../assets/images/logo-light.png";

import {
  signInScheduler,
  signInSelector,
} from "../store/reducer/SignInReducer";
import { API_STATUS } from "../utils/constants";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signInData, signInLoading } = useSelector(signInSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signInValidationSchema),
  });
  const REDIRECT_URI = "http://localhost:3000/signup";
  const onSubmitHandler = (formData) => {
    console.log({ formData });
    dispatch(signInScheduler({ formData }));
    // reset();
  };

  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false",
    recaptchaLoaded: false,
  });
  const recaptchaRef = React.createRef();

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const handleChange = (value) => {
    setCaptcha({ value });
    if (value === null) setCaptcha({ expired: "true" });
  };

  const asyncScriptOnLoad = () => {
    setCaptcha({ callback: "called!", recaptchaLoaded: true });
  };

  useEffect(() => {
    if (signInLoading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Loggedin Successfully!");
      reset();
      navigate("/");
    }
    if (signInLoading === API_STATUS.REJECTED) {
      toastr.clear();
      toastr.error("Login Failed! Please check username and password");
    }
  }, [signInLoading]);

  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" class="">
                              <img
                                src={logodark}
                                alt=""
                                height="20"
                                class="auth-logo logo-dark mx-auto"
                              />
                              <img
                                src={logolight}
                                alt=""
                                height="20"
                                class="auth-logo logo-light mx-auto"
                              />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                          <p className="text-muted">
                            Sign in to continue to Invoice Application.
                          </p>
                        </div>

                        <div className="p-2 mt-5">
                          <form
                            className="form-horizontal"
                            onSubmit={handleSubmit(onSubmitHandler)}
                          >
                            <div class="input-container">
                              <input
                                type="text"
                                placeholder=" "
                                id="email"
                                name="email"
                                {...register("email")}
                              />
                              <label for="email">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>{" "}
                                Email
                              </label>
                            </div>
                            <div class="input-container">
                              <input
                                type="password"
                                placeholder=" "
                                id="password"
                                name="password"
                                {...register("password")}
                              />
                              <label for="password">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>{" "}
                                Password
                              </label>
                            </div>
                            {errors?.password?.message && (
                              <p className="error">{errors.password.message}</p>
                            )}

                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <Label
                                className="form-check-label label mx-1 mt-1"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </Label>
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </Button>
                            </div>

                            <div className="mt-4 text-center">
                              <Link
                                to="#"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1"></i> Forgot
                                your password?
                              </Link>
                            </div>
                          </form>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            © 2023 Digital Garage. 
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SignIn;
