import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  customersList,
  customerUpdate,
  customerDelete,
  customerAdd,
  customerEdit,
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import alertmessage from "./../../utils/alert";
const namespace = "customers";

const initialState = {
  loading: "initial",
  addloading: "initial",
  editloading: "initial",
  updateloading: "initial",
  deleteloading: "initial",
  errorMessage: null,
  customer_details: null,
  customerDatas: null,
  customerDataById: null,
  customerCount: 0,
};

export const customerData = createAsyncThunk(
  `${namespace}/customerData`,
  async (
    { query = "", page = 0, limit = 0, sortby = "customer_name", order = "asc" },
    { rejectWithValue, dispatch }
  ) => {
    try {      
      //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
      const data = await customersList(query, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateCustomer = createAsyncThunk(
  `${namespace}/UpdateCustomer`,
  async ({ formData, updateID }, { rejectWithValue, dispatch }) => {
    try {      
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);      
      const data = await customerUpdate({ payload: payload }, updateID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const EditCustomer = createAsyncThunk(
  `${namespace}/EditCustomer`,
  async ({ custID }, { rejectWithValue, dispatch }) => {
    try {      
      // let payload = EncryptDecrypt.encryptdata(custID, secretKey);
      const data = await customerEdit(custID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const AddCustomer = createAsyncThunk(
  `${namespace}/AddCustomer`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await customerAdd({ payload: payload });
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const DeleteCustomer = createAsyncThunk(
  `${namespace}/DeleteCustomer`,
  async ({ deleteID }, { rejectWithValue, dispatch }) => {
    try {
      const data = await customerDelete(deleteID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

const customersSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearCustLoadingDatas: (state) => {
      state.loading = "initial";
      state.addloading = "initial";
      state.deleteloading = "initial";
      state.updateloading = "initial";
    },
    clearCustUpdateLoading: (state) => {
      state.updateloading = "initial";
    },
    clearCustEditLoading: (state) => {
      state.editloading = "initial";
    },
    clearCustDeleteLoading: (state) => {
      state.deleteloading = "initial";
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(customerData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(customerData.fulfilled, (state, { payload }) => {
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.customerCount = payloaddatas?.count;
      state.customerDatas = payloaddatas?.data;
      console.log(payloaddatas,'Customer payload')
    });
    builder.addCase(customerData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AddCustomer.pending, (state) => {
      state.addloading = API_STATUS.PENDING;
    });
    builder.addCase(AddCustomer.fulfilled, (state, { payload }) => {
      state.addloading = API_STATUS.FULFILLED;
    });
    builder.addCase(AddCustomer.rejected, (state, action) => {
      state.addloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(EditCustomer.pending, (state) => {
      state.editloading = API_STATUS.PENDING;
    });
    builder.addCase(EditCustomer.fulfilled, (state, { payload }) => {
      state.editloading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data, secretKey)
      );
      state.customer_details = payloaddatas?.data;
    });
    builder.addCase(EditCustomer.rejected, (state, action) => {
      state.editloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateCustomer.pending, (state) => {
      state.updateloading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateCustomer.fulfilled, (state, { payload }) => {
      state.updateloading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateCustomer.rejected, (state, action) => {
      state.updateloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(DeleteCustomer.pending, (state) => {
      state.deleteloading = API_STATUS.PENDING;
    });
    builder.addCase(DeleteCustomer.fulfilled, (state, { payload }) => {
      state.deleteloading = API_STATUS.FULFILLED;
    });
    builder.addCase(DeleteCustomer.rejected, (state, action) => {
      state.deleteloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const {
  clearData,
  clearCustUpdateLoading,
  clearCustLoadingDatas,
  clearCustDeleteLoading,
  clearErrormsg,
} = customersSlice.actions;

export const customersSelector = (state) => state.customers;

export default customersSlice.reducer;
