import React, { useState, useEffect } from "react";

import { Card, CardBody, Col, Row, Container, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import {
	productsSelector, postProductDetails, EditProduct, UpdateProduct,
	clearProdLoadingDatas,
	clearErrormsg,
} from "../../store/reducer/ProductReducer"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import "toastr/build/toastr.min.css";
import { productValidationSchema } from "../../utils/Validation";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import toastr from "toastr";
import { API_STATUS } from "../../utils/constants";
import "../../App.css"
import "toastr/build/toastr.min.css";
import { toInteger } from "lodash";

function ProductEdit() {
	let { productId } = useParams();
	const dispatch = useDispatch()
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(EditProduct({ productId }));
	}, [])
	const {
		product_details,
		updateloading,
		errorMessage,
	} = useSelector(productsSelector);
	const [productDetails, setProductDetails] = useState({
		description: product_details?.description,
		product_category: product_details?.product_category,
		product_type: product_details?.product_type,
		purchase_description: product_details?.purchase_description,
		stock_unit: product_details?.stock_unit,
		selling_price: product_details?.selling_price,
		product_cost: product_details?.product_cost,
		market_cost: product_details?.market_cost,
		product_barcode: product_details?.product_barcode,
		part_no: product_details?.part_no,
		financial_category: product_details?.financial_category,
		hs_code: product_details?.hs_code,
		brand: product_details?.brand,
		manufacturer: product_details?.manufacturer,
		rack: product_details?.rack,
		length: product_details?.length,
		height: product_details?.height,
		width: product_details?.width,
		weight: product_details?.weight,
		color: product_details?.color,
		temperature: product_details?.temperature,
		long_description: product_details?.long_description,
		warrenty_days: product_details?.warrenty_days,
		warrenty_type: product_details?.warrenty_type,
		warrenty_remarks: product_details?.warrenty_remarks,
		term_condition: product_details?.term_condition,
		quotation_image: product_details?.quotation_image,
		pos_image: product_details?.pos_image,
		inactive: product_details?.inactive,
		batch_code_status: product_details?.batch_code_status,
		awt_status: product_details?.awt_status,
		barcode_print_purchase_status: product_details?.barcode_print_purchase_status,
		barcode_print_sales_status: product_details?.barcode_print_sales_status,
		installation_status: product_details?.installation_status,
		hide_in_invoice_status: product_details?.hide_in_invoice_status,
		purchase_status: product_details?.purchase_status,
		sold_status: product_details?.sold_status,
		raw_material_status: product_details?.raw_material_status,
		created_by: product_details?.created_by,
		updated_by: product_details?.updated_by,
		trash: product_details?.trash,
		status: product_details?.status,
		created_at: product_details?.created_at,
		updated_at: product_details?.updated_at,
	})
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm({
		resolver: yupResolver(productValidationSchema),
	});

	const [activeTab, setActiveTab] = useState("5")
	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	}
	const handleInput = (e, dest, type) => {
		const { value } = e.target;
		productDetails[dest] = type !== '' ? "" : value;
		setProductDetails({
			...productDetails,
		})
	}

	const onSubmitHandler = (formData) => {
		console.log(formData, productId, "productId");
		formData.created_by = (formData?.created_by) ? parseInt(formData.created_by) : 1;
		formData.updated_by = (formData?.updated_by) ? parseInt(formData.updated_by) : 1;

		delete formData.updated_at;
		delete formData.created_at;
		delete formData.created_by;
		delete formData.updated_by;
		delete formData.trash;
		delete formData.status;
		console.log(formData, "FormData");

		dispatch(UpdateProduct({ formData, productId }));
		// reset();
	};
	useEffect(() => {
		if (product_details) {
			reset(product_details);
		}
	}, [product_details])

	useEffect(() => {
		if (updateloading === API_STATUS.FULFILLED) {
			toastr.clear();
			toastr.success("Product Updated Successfully!");
			navigate('/product_list');
			dispatch(clearProdLoadingDatas());
			reset();
		}
		if (errorMessage) {
			toastr.clear();
			if (errorMessage.message) toastr.error(errorMessage.message);
			else if (errorMessage.errors) {
				let data = "";
				errorMessage?.errors.map((err) => {
					data += err.message + " ";
				});
				toastr.error(data);
			}
			dispatch(clearErrormsg());
		}
	}, [updateloading, errorMessage]);
	const footer = (prev) => {
		return (
			<>
				<div className={prev === 'np' ? "d-flex justify-content-end" : "d-flex justify-content-between mt-2"}>
					<Button className={prev === 'np' ? "d-none" : "d-block"} onClick={() => {
						setActiveTab((Number(activeTab) - 1).toString())
					}}>Previous</Button>
					{
						prev === 'nn' ?
							<Button onClick={() => {
								// dispatch(postProductDetails({ productDetails }))
							}}>
								Save
							</Button> :
							<Button onClick={() => {
								setActiveTab((Number(activeTab) + 1).toString())
							}}>{prev === 'nn' ? 'Save' : 'Next'}</Button>
					}

				</div>
			</>
		)
	}
	return (
		<>
			<div className="page-content">
				<Container fluid>

					<div className="row">
						<div className="col-md-6">
							<h4>
								<Breadcrumb>
									<BreadcrumbItem><a href="product_list">Product</a></BreadcrumbItem>
									<BreadcrumbItem active>Product Edit</BreadcrumbItem>
								</Breadcrumb>
							</h4>
						</div>
						<div className="col-md-6">
							<a href="/product_list" className="flot-right">
								<button type="button" className="btn btn-secondary mr-1  float-right"> Back
								</button>
							</a>
						</div>
					</div>
					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<form onSubmit={handleSubmit(onSubmitHandler)}>
										<Row>
											<Col md="4">
												<div className="input-container">
													<input type="text" id="description" {...register("description")} placeholder="" defaultValue={product_details?.description} />
													<label for="description" className="required">Product Description</label>
												</div>
											</Col>
											<Col md="4">
												<div className="input-container">
													<input type="text" id="product_category"  {...register("product_category")} placeholder="" defaultValue={product_details?.product_category} />

													<label for="product_category" className="required">Product Category</label>
												</div>
											</Col>
											<Col md="4">
												<div className="input-container">
													<input type="text" id="product_type" {...register("product_type")} placeholder="" defaultValue={product_details?.product_type} />
													<label for="product_type" className="required">Product Type</label>
												</div>
											</Col>
										</Row>
										<Nav pills className="navtab-bg nav-justified">
											<NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: activeTab === "5"
													})}
													onClick={() => {
														toggleTab("5");
													}}
												>
													General
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: activeTab === "6"
													})}
													onClick={() => {
														toggleTab("6");
													}}
												>
													Other Fields
												</NavLink>
											</NavItem>
											{/* <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: activeTab === "7"
													})}
													onClick={() => {
														toggleTab("7");
													}}
												>
													Images
												</NavLink>
											</NavItem> */}
											<NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: activeTab === "8"
													})}
													onClick={() => {
														toggleTab("8");
													}}
												>
													Warrenty
												</NavLink>
											</NavItem>
											{/* <NavItem>
												<NavLink
													style={{ cursor: "pointer" }}
													className={classnames({
														active: activeTab === "9"
													})}
													onClick={() => {
														toggleTab("9");
													}}
												>
													Settings
												</NavLink>
											</NavItem> */}
										</Nav>
										<TabContent activeTab={activeTab}>
											<TabPane tabId="5">
												<Card className="mt-1 p-1">
													<CardBody className="m-0">
														<Row>
															<Col sm="12">
																<Row>
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" id="stock_unit" placeholder="" defaultValue={product_details?.stock_unit} onChange={(e) => handleInput(e, 'stock_unit', '')} />
																			<label for="stock_unit" className="required">Stock Unit</label>
																		</div>
																	</Col>
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" onChange={(e) => handleInput(e, 'selling_price', '')} id="selling_price" placeholder="" defaultValue={product_details?.selling_price} {...register("selling_price")} />
																			<label for="selling_price" className="">Selling Price</label>
																		</div>
																	</Col>
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" id="product_cost" placeholder="" defaultValue={product_details?.product_cost} {...register("product_cost")} />
																			<label for="product_cost" className="">Product Cost</label>
																		</div>
																	</Col>
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" id="market_cost" placeholder="" defaultValue={product_details?.market_cost} {...register("market_cost")} />
																			<label for="market_cost">Market Cost</label>
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
														<Row>
															<Col sm="12">
																<Row>
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" id="purchase_description" placeholder="" defaultValue={product_details?.purchase_description} {...register("purchase_description")} />
																			<label for="purchase_description">Purchase Description</label>
																		</div>
																	</Col>
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" id="product_barcode" placeholder="" defaultValue={product_details?.product_barcode} {...register("product_barcode")} />
																			<label for="product_barcode">Product Barcode</label>
																		</div>
																	</Col>
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" id="product_barpart_nocode" placeholder="" defaultValue={product_details?.part_no} {...register("part_no")} />
																			<label for="part_no">Part No</label>
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
														<Row>
															<Col sm="12">
																<Row >
																	<Col md="3">
																		<div className="input-container">
																			<input type="text" id="financial_category" placeholder="" defaultValue={product_details?.financial_category} />
																			<label for="financial_category" className="required">Financial Category</label>
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</TabPane>
											<TabPane tabId="6" className="p-3">
												<Card className="mt-1 p-1">
													<CardBody className="m-0">
														<Row>
															<Col sm="12">
																<Row className="mt-4">
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="hs_code" placeholder="" defaultValue={product_details?.hs_code} {...register("hs_code")} />
																			<label for="hs_code">HS Code</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="brand" placeholder="" defaultValue={product_details?.brand} {...register("brand")} />
																			<label for="brand">Brand</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="manufacturer" placeholder="" defaultValue={product_details?.manufacturer}  {...register("manufacturer")} />
																			<label for="manufacturer">Manufacturer</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="rack" name="rack" placeholder="" defaultValue={product_details?.rack} {...register("rack")} />
																			<label for="rack">Rack</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="shelf_expiry" placeholder="" defaultValue={product_details?.shelf_expiry} {...register("shelf_expiry")} />
																			<label for="shelf_expiry">Shelf Expiry</label>
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
														<Row className="mt-4">
															<Col sm="12">
																<h3>Dimension</h3>
																<Row className="mt-2">
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="length" placeholder="" defaultValue={product_details?.length} {...register("length")} />
																			<label for="length">Length</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="height" placeholder="" defaultValue={product_details?.height} {...register("height")} />
																			<label for="height">Height</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="width" placeholder="" defaultValue={product_details?.width} {...register("width")} />
																			<label for="width">Width</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="weight" placeholder="" defaultValue={product_details?.weight} {...register("weight")} />
																			<label for="weight">Weight</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="color" placeholder="" defaultValue={product_details?.color} {...register("color")} />
																			<label for="color">Color</label>
																		</div>
																	</Col>
																	<Col md="2">
																		<div className="input-container">
																			<input type="text" id="temperature" placeholder="" defaultValue={product_details?.temperature} {...register("temperature")} />
																			<label for="temperature">Temperature</label>
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
														<Row className="mt-4">
															<Col sm="12">

																<Row className="mt-2">
																	<Col md="8">
																		<div className="input-container">
																			<input type="text" id="long_description" name="long_description" placeholder="" defaultValue={product_details?.long_description} {...register("long_description")} />
																			<label for="long_description" >Long Description</label>
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</TabPane>
											<TabPane tabId="7" className="p-3">
												<Card className="mt-1 p-1">
													<CardBody className="m-0">
														<Row>
															<Col sm="12">

																<Row className="mt-4">
																	<Col md="6">
																		<b>Image to Print Quotation</b>
																		<div className="input-group">
																			<input type="file" id="quotation_image" placeholder="" />
																		</div>
																	</Col>
																	<Col md="6">
																		<b>Image to Print POS</b>
																		<div className="input-group">
																			<input type="file" id="pos_image" placeholder="" />
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</TabPane>
											<TabPane tabId="8" className="p-3">
												<Card className="mt-1 p-1">
													<CardBody className="m-0">
														<Row>
															<Col sm="12">
																<Row className="mt-4">
																	<Col md="4">
																		<div className="input-container">
																			<input type="text" id="warrenty_days" name="warrenty_days" placeholder="" defaultValue={product_details?.warrenty_days} {...register("warrenty_days")} />
																			<label for="warrenty_days">Warrenty period in Days</label>
																		</div>
																	</Col>
																	<Col md="4">
																		<div className="input-container">
																			<input type="text" id="warrenty_type" name="warrenty_type" placeholder="" defaultValue={product_details?.warrenty_type} {...register("warrenty_type")} />
																			<label for="warrenty_type">Warrenty Type</label>
																		</div>
																	</Col>
																</Row>
																<Row className="mt-4">
																	<Col sm="12">
																		<Row className="mt-2">
																			<Col md="6">
																				<div className="input-container">
																					<input type="text" id="warrenty_remarks" placeholder="" defaultValue={product_details?.warrenty_remarks} {...register("warrenty_remarks")} />
																					<label for="warrenty_remarks">Remarks</label>
																				</div>
																			</Col>
																			<Col md="6">
																				<div className="input-container">
																					<input type="text" id="term_condition" placeholder="" defaultValue={product_details?.term_condition} {...register("term_condition")} />
																					<label for="term_condition">T&C</label>
																				</div>
																			</Col>
																		</Row>
																	</Col>
																</Row>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</TabPane>
											<TabPane tabId="9" className="p-3">
												<Card className="mt-1 p-1">
													<CardBody className="m-0">
														<Row>
															<Col sm="12">
																<Row className="mt-4">
																	<Col md="4">
																		<div className="form-check form-check-right">
																			<label className="form-check-label" htmlFor="inactive" style={{ paddingLeft: "20px" }}>
																				Inactive
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="inactive" />
																		</div>
																	</Col>
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="batch_code_status" style={{ paddingLeft: "20px" }}>
																				Use Batch code for this product
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="batch_code_status" />
																		</div>
																	</Col>
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="awt_status" style={{ paddingLeft: "20px" }}>
																				Enable Awt
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="awt_status" />
																		</div>
																	</Col>
																</Row>
																<Row className="mt-4">
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="barcode_print_purchase_status" style={{ paddingLeft: "20px" }}>
																				Print Barcode while Purchase
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="barcode_print_purchase_status" />

																		</div>
																	</Col>
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="barcode_print_sales_status" style={{ paddingLeft: "20px" }}>
																				Print Barcode after Sales
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="barcode_print_sales_status" />

																		</div>
																	</Col>
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="installation_status" style={{ paddingLeft: "20px" }}>
																				Installation required
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="installation_status" />

																		</div>
																	</Col>
																</Row>
																<Row className="mt-4">
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="hide_in_invoice_status" style={{ paddingLeft: "20px" }}>
																				Hide in Invoice
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="hide_in_invoice_status" />

																		</div>
																	</Col>
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="purchase_status" style={{ paddingLeft: "20px" }}>
																				Can be Purchased
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="purchase_status" defaultChecked />

																		</div>
																	</Col>
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="sold_status" style={{ paddingLeft: "20px" }}>
																				Can be Sold
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="sold_status" defaultChecked />

																		</div>
																	</Col>
																</Row>
																<Row className="mt-4">
																	<Col md="4">
																		<div className="form-check">
																			<label className="form-check-label" htmlFor="raw_material_status" style={{ paddingLeft: "20px" }}>
																				Can be Raw Material
																			</label>
																			<input className="form-check-input" type="checkbox" value="" id="raw_material_status" />
																		</div>
																	</Col>
																</Row>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</TabPane>
										</TabContent>
										<div className="text-end">
											<Button
												color="primary"
												className=" waves-effect waves-light me-1"
											>
												Save
											</Button>
										</div>
									</form>
								</CardBody>
							</Card>
						</Col>
					</Row>

				</Container>
			</div >
		</>
	);
}
export default ProductEdit;
