import React, { useMemo, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { assestURL } from "../../services/config";


import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    Collapse,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
    Spinner,
} from "reactstrap";
import {
    reportinvoiceData,
    dashboardSelector,
    clearExcelData,
    reportDownload,
} from "../../store/reducer/DashboardReducer";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SalesInvoiceReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let [sortBy, setSortBy] = useState("status");
    let [activesortBy, setActiveSortBy] = useState("status");
    let [page, setPage] = useState(0);
    const [info, setInfo] = useState({ 0: false });

    const invoiceStatus = [
        { id: "", name: "Search Status" },
        { id: 0, name: "Draft" },
        { id: 1, name: "Proforma" },
        { id: 2, name: "Invoice" },
    ]

    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("asc");
    let [searchFromDateQuery, setsearchFromDateQuery] = useState("");
    let [searchToDateQuery, setsearchToDateQuery] = useState("");
    let [searchStatusQuery, setsearchStatusQuery] = useState("");
    let [searchStatusarray, setsearchStatusarray] = useState([{ id: "", name: "Search Status" }]);
    const MySwal = withReactContent(Swal);
    const {
        reportinvoiceDatas,
        reportinvoiceCount,
        excelMessage,
        generateLoading,
    } = useSelector(dashboardSelector);

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        dashboardDispatch();
    };
    const {
        formState: { errors },
        control,
        reset,
    } = useForm({});

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const searchFromData = (e) => {
        searchFromDateQuery = e.target.value;
        setsearchFromDateQuery((e.target.value));
        setPage(0);
    };
    const searchToData = (e) => {
        searchToDateQuery = e.target.value;
        setsearchToDateQuery((e.target.value));
        setPage(0);
    };
    const searchStatusData = (e) => {
        searchStatusQuery = e[0].id;
        searchStatusarray = (e);
        setsearchStatusQuery(e[0].id);
        setsearchStatusarray(e);
        setPage(0);
    };

    const searchData = () => {
        setsearchFromDateQuery(searchFromDateQuery);
        setsearchToDateQuery(searchToDateQuery);
        setsearchStatusQuery(searchStatusQuery);
        setPage(0);
        dashboardDispatch();
    };

    const searchReset = () => {
        searchFromDateQuery = "";
        searchToDateQuery = "";
        searchStatusQuery = "";
        setsearchFromDateQuery(searchFromDateQuery);
        setsearchToDateQuery(searchToDateQuery);
        setsearchStatusQuery(searchStatusQuery);
        searchStatusData([{ id: "", name: "Search Status" }]);
        setPage(0);
        dashboardDispatch();
    };

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }

        setSortBy(key);
        dashboardDispatch();
    };

    useEffect(() => {
        function downloadFile(filePath) {
            var link = document.createElement('a');
            link.href = filePath;
            link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
            link.click();
        }
        console.log(generateLoading, "generateLoading");

        if (generateLoading === "Fulfilled") {
            downloadFile(`${assestURL}${excelMessage}`);
        }
        dispatch(clearExcelData());
    }, [excelMessage]);

    const columns = useMemo(
        () => [
            {
                Header: "Product Name",
                accessor: "desciption",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Product Category",
                accessor: "product_category",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Product Type",
                accessor: "product_type",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Stock Unit",
                accessor: "stock_unit",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Part No",
                accessor: "part_no",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Selling Price",
                accessor: "selling_price",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Tables", link: "/" },
        { title: "Data Tables", link: "#" },
    ];
    useEffect(() => {
        dashboardDispatch();
    }, []);

    const downLoadReport = () => {
        dispatch(
            reportDownload({
                query: searchStatusQuery,
                from_date: searchFromDateQuery,
                to_date: searchToDateQuery,
            })
        )
    };

    const dashboardDispatch = () => {
        dispatch(
            reportinvoiceData({
                query: searchStatusQuery,
                from_date: searchFromDateQuery,
                to_date: searchToDateQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );


    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-content-between">
                        <h2>Sales Invoice Report</h2>

                        <button
                            type="button"
                            className="waves-effect waves-light btn btn-success mb-2"
                            onClick={() => {
                                downLoadReport();
                            }}
                        >
                            Download Report
                        </button>
                    </div>

                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-4"><input
                                    type="date"
                                    name="search"
                                    className="form-control"
                                    style={{ float: "right" }}
                                    value={searchFromDateQuery}
                                    onChange={(e) => {
                                        searchFromData(e);
                                    }}
                                    placeholder="Search From Date"
                                /></div>
                                <div className="col-md-4">   <input
                                    type="date"
                                    name="search"
                                    className="form-control"
                                    style={{ float: "right" }}
                                    value={searchToDateQuery}
                                    onChange={(e) => {
                                        searchToData(e);
                                    }}
                                    placeholder="Search To Date"
                                /></div>
                                <div className="col-md-4 row">

                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                className="js-states select-control"
                                                placeholder="Search Status"
                                                options={invoiceStatus}
                                                {...field}
                                                disabled={false}
                                                dropdownHandle={true}
                                                searchable={true}
                                                labelField={"name"}
                                                valueField={"id"}
                                                name="status"
                                                values={searchStatusarray}
                                                onChange={(e) => {
                                                    searchStatusData(e);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-10"></div>
                                <div className="col-md-1" style={{ float: "right" }} >
                                    <input
                                        type="button"
                                        name="search"
                                        className="form-control btn btn-primary"
                                        onClick={() => {
                                            searchData();
                                        }}
                                        value="Search"
                                    /></div>
                                <div className="col-md-1">
                                    <input
                                        type="button"
                                        name="reset"
                                        className="form-control btn btn-danger"
                                        onClick={() => {
                                            searchReset();
                                        }}
                                        value="Reset"
                                    /></div>
                            </div>
                            <div className="table-responsive react-table">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead className="table-light table-nowrap">
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "description" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("description");
                                                        }}
                                                    >
                                                        S.No
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "product_category" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("product_category");
                                                        }}
                                                    >
                                                        Invoice Date
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "product_type" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("product_type");
                                                        }}
                                                    >
                                                        Invoice No
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "part_no" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("part_no");
                                                        }}
                                                    >
                                                        Customer
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "status" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("status");
                                                        }}
                                                    >
                                                        Status
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "selling_price" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("selling_price");
                                                        }}
                                                    >
                                                        Amount
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "selling_price" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("selling_price");
                                                        }}
                                                    >
                                                        Other Charges
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "selling_price" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("selling_price");
                                                        }}
                                                    >
                                                        Tax Amount
                                                    </TableSortLabel>
                                                </StyledTableCell>

                                                <StyledTableCell align="">Net Amount</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportinvoiceDatas &&
                                                reportinvoiceDatas.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {index = index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {dayjs(row?.invoice_date).format("DD-MM-YYYY")}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.invoice_no}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.customer?.customer_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.status === 0
                                                                ? "Draft"
                                                                : row.status === 1
                                                                    ? "Proforma"
                                                                    : "Invoice"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.sub_total}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {(row?.rounded_amount) ? row?.rounded_amount : 0}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.tax_amount}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.total}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={8}
                                                    count={reportinvoiceCount}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>
                        </CardBody>
                    </Card>
                </Container >
            </div >
        </>
    );
};

export default SalesInvoiceReport;
