import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const signIn = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signin", postData);
};
export const forgotPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/forgot-password", postData);
};
export const resetPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/reset-password", postData);
};

/* Dashboard */
export const dashboardCards = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/dashboard_cards/", Http.getAuthToken());
};
export const dashboardInvoices = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/dashboard_invoice_list?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const dashboardChart = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/dashboard_pie_chart/", Http.getAuthToken());
};
/* Customers */
export const customersList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/customerlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const customerAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/create_customer", postData, Http.getAuthToken());
};
export const companyList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/company", Http.getAuthToken());
};
export const customerUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/user/update_customer/" + updateID, postData, Http.getAuthToken());
};
export const customerDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/delete_customer/" + deleteID, Http.getAuthToken());
};

export const customerEdit = (cutomerID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/customer/" + cutomerID, Http.getAuthToken());
};

/* Products */
export const productsList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/product/productlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const productEdit = (productId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/product/edit/" + productId, Http.getAuthToken());
};
export const productAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/product/create", postData, Http.getAuthToken());
};
export const companyAdd = (formData) => {
    console.log(formData, 'formData  ');
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/company/create", formData, Http.getAuthToken());
};
export const productUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/product/update/" + updateID, postData, Http.getAuthToken());
};
export const productDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/product/delete/" + deleteID, Http.getAuthToken());
};

/* Users */
export const userList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/userlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const userAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/create_user", postData, Http.getAuthToken());
};

export const userEdit = (userId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/edit/" + userId, Http.getAuthToken());
};
export const userUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/user/update_user/" + updateID, postData, Http.getAuthToken());
};
export const userDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/delete_user/" + deleteID, Http.getAuthToken());
};


/* Vendor */
export const vendorsList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/vendorlist?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const vendorAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/create_vendor", postData, Http.getAuthToken());
};
export const vendorEdit = (vendorId) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/vendor/" + vendorId, Http.getAuthToken());
};
export const vendorUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/user/update_vendor/" + updateID, postData, Http.getAuthToken());
};
export const vendorDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/delete_vendor/" + deleteID, Http.getAuthToken());
};

/* Products */
export const salesinvoiceList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/list?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const salesinvoiceById = (editID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/edit/" + editID, Http.getAuthToken());
};
export const getInvoiceNumber = (editID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/get_invoice_number", Http.getAuthToken());
};

export const getpaymentStat = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/payment/stat", Http.getAuthToken());
};

export const createPayment = (data) => {
    console.log(data, 'data');
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/salesinvoice/payment/create", data, Http.getAuthToken());
};
export const getPayments = (editID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/payment", Http.getAuthToken());
};

export const getInvoiceDetail = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/get_invoice_details/?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};

export const salesinvoiceAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/salesinvoice/create", postData, Http.getAuthToken());
};
export const salesinvoiceUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/update/" + updateID, postData, Http.getAuthToken());
};
export const salesinvoiceDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/salesinvoice/delete/" + deleteID, Http.getAuthToken());
};

export const salesinvoicePDF = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/generate_invoice/" + ID, Http.getAuthToken());
};

export const selesData = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/getdata/", Http.getAuthToken());
};

export const salesinvoiceProforma = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/setproforma", ID, Http.getAuthToken());
};

export const salesInvoiceDraft = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/set_draft", ID, Http.getAuthToken());
};

export const salesInvoice = (ID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/salesinvoice/set_invoice", ID, Http.getAuthToken());
};
/** Sales Invoice Report */
export const reportinvoiceList = (query, from_date, to_date, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/report_invoice_list/?query=" + query + "&from_date=" + from_date + "&to_date=" + to_date + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};

export const salesReportDownload = (query, from_date, to_date) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/downloadExcel/?query=" + query + "&from_date=" + from_date + "&to_date=", Http.getAuthToken());
}

export const zatcaValidation = (id) => {
    console.log(id, 'ZATCA API ID')
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/auth/signout/?query=" + id, Http.getAuthToken());
}

export const zatcaView = (id) => {
    console.log(id,'Zatca View Reducer API')
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/auth//zatca/view?query=" + id?.ID, Http.getAuthToken());
}

export const searchInvoice = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/salesinvoice/search?query=" + query, Http.getAuthToken());
}


export const getCountry = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/settings/countrylist" + query, Http.getAuthToken());
}

export const getCurrency = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/settings/currency_list" + query, Http.getAuthToken());
}