import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  vendorsList,
  vendorUpdate,
  vendorDelete,
  vendorEdit,
  vendorAdd,
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from "./../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";
import alertmessage from "./../../utils/alert";
const namespace = "vendors";

const initialState = {
  loading: "initial",
  addloading: "initial",
  updateloading: "initial",
  deleteloading: "initial",
  editloading: "initial",
  errorMessage: null,
  vendorDatas: null,
  vendor_details: null,
  vendorDataById: null,
  vendorCount: 0,
};

export const vendorData = createAsyncThunk(
  `${namespace}/vendorData`,
  async (
    { query = "", page = 0, limit = 0, sortby = "", order = "" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("interdata123");
      //let payload = EncryptDecrypt.encryptdata(formData, secretKey)
      const data = await vendorsList(query, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const EditVendor = createAsyncThunk(
  `${namespace}/EditVendor`,
  async ({ vendorID }, { rejectWithValue, dispatch }) => {
    try {      
      // let payload = EncryptDecrypt.encryptdata(VendorID, secretKey);
      const data = await vendorEdit(vendorID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateVendor = createAsyncThunk(
  `${namespace}/UpdateVendor`,
  async ({ formData, updateID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(updateID, "interdata");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await vendorUpdate({ payload: payload }, updateID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const AddVendor = createAsyncThunk(
  `${namespace}/AddVendor`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "interdata");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await vendorAdd({ payload: payload });
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const DeleteVendor = createAsyncThunk(
  `${namespace}/DeleteVendor`,
  async ({ deleteID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(deleteID, "interdata");
      const data = await vendorDelete(deleteID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

const vendorsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearVendorLoadingDatas: (state) => {
      state.loading = "initial";
      state.addloading = "initial";
      state.deleteloading = "initial";
    },
    clearVendorUpdateLoading: (state) => {
      state.updateloading = "initial";
    },
    clearVendorDeleteLoading: (state) => {
      state.deleteloading = "initial";
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(vendorData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(vendorData.fulfilled, (state, { payload }) => {
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      state.vendorCount = payloaddatas?.count;
      state.vendorDatas = payloaddatas?.data;
    });
    builder.addCase(vendorData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AddVendor.pending, (state) => {
      state.addloading = API_STATUS.PENDING;
    });
    builder.addCase(AddVendor.fulfilled, (state, { payload }) => {
      state.addloading = API_STATUS.FULFILLED;
    });
    builder.addCase(AddVendor.rejected, (state, action) => {
      state.addloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(EditVendor.pending, (state) => {
      state.editloading = API_STATUS.PENDING;
    });
    builder.addCase(EditVendor.fulfilled, (state, { payload }) => {
      state.editloading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data, secretKey)
      );
      console.log(payloaddatas,"payloaddatas");
      state.vendor_details = payloaddatas?.data;
    });
    builder.addCase(EditVendor.rejected, (state, action) => {
      state.editloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateVendor.pending, (state) => {
      state.updateloading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateVendor.fulfilled, (state, { payload }) => {
      state.updateloading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateVendor.rejected, (state, action) => {
      state.updateloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(DeleteVendor.pending, (state) => {
      state.deleteloading = API_STATUS.PENDING;
    });
    builder.addCase(DeleteVendor.fulfilled, (state, { payload }) => {
      state.deleteloading = API_STATUS.FULFILLED;
    });
    builder.addCase(DeleteVendor.rejected, (state, action) => {
      state.deleteloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const {
  clearData,
  clearVendorUpdateLoading,
  clearVendorLoadingDatas,
  clearVendorDeleteLoading,
  clearErrormsg,
} = vendorsSlice.actions;

export const vendorsSelector = (state) => state.vendors;

export default vendorsSlice.reducer;
