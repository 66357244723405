import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { MetisMenu } from "metismenujs";

function Sidebar() {
  let template_settings = JSON.parse(localStorage.getItem("template"));
  const initMenu = () => {
    MetisMenu.attach("#side-menu");
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    console.log(matchingMenuItem, "matchingMenuItem");
    if (matchingMenuItem) {
      //   activateParentDropdown(matchingMenuItem);
    }
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };
  useEffect(() => {
    initMenu();
  }, []);
  return (
    <>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {template_settings.sidebar ? (
            <SimpleBar style={{ maxHeight: "100%" }}>
              <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                  <li className="menu-title">{"Menu"}</li>

                  <li>
                    <Link to="/dashboard" className="waves-effect">
                      <i className="ri-dashboard-line"></i>
                      {/* <span className="badge rounded-pill bg-success float-end">
                        3
                      </span> */}
                      <span className="ms-1">{"Dashboard"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className="ri-building-3-line"></i>
                      <span className="ms-1">{"Masters"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">{"Users"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Customers"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Vendor"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Company"}</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className=" ri-shopping-cart-fill"></i>
                      <span className="ms-1">{"Inventory"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/product_list">{"Products"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Stock"}</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className="ri-store-2-line"></i>
                      <span className="ms-1">{"Purchase"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">{"Purchase Order"}</Link>
                      </li>
                      <li>
                        <Link to="/purchase_invoice">
                          {"Purchase Invoice"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">
                          {"Purchase Return"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Payment"}</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className="ri-store-3-line"></i>
                      <span className="ms-1">{"Sales"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">
                          {"Sales Quotation"}
                        </Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Sales Order"}</Link>
                      </li>
                      <li>
                        <Link to="/sales_invoice">{"Sales Invoice"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Sales Return"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Payment"}</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link
                      to="/#"
                      className="has-arrow waves-effect"
                      aria-expanded="true"
                    >
                      <i className=" ri-file-list-3-line"></i>
                      <span className="ms-1">{"Reports"}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/ecommerce-products">{"Sales"}</Link>
                      </li>
                      <li>
                        <Link to="/ecommerce-products">{"Purchase"}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </SimpleBar>
          ) : (
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{"Menu"}</li>

                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="ri-dashboard-line"></i>
                    {/* <span className="badge rounded-pill bg-success float-end">
                      3
                    </span> */}
                    <span className="ms-1">{"Dashboard"}</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="/#"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i className="ri-building-3-line"></i>
                    <span className="ms-1">{"Masters"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/userList">{"Users"}</Link>
                    </li>
                    <li>
                      <Link to="/customerlist">{"Customers"}</Link>
                    </li>
                    {/* <li>
                      <Link to="/vendorList">{"Vendor"}</Link>
                    </li> */}
                    <li>
                      <Link to="/company">{"Company"}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link
                    to="/product_list"
                    className=" waves-effect"
                  >
                    <i className=" ri-shopping-cart-fill"></i>
                    <span className="ms-1">{"Inventory"}</span>
                  </Link>
                  {/* <ul className="sub-menu">
                    <li>
                      <Link to="/product_list">{"Products"}</Link>
                    </li> */}
                  {/* <li>
                      <Link to="/stock">{"Stock"}</Link>
                    </li> */}
                  {/* </ul> */}
                </li>
                {/* <li>
                  <Link
                    to="/#"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i className="ri-store-2-line"></i>
                    <span className="ms-1">{"Purchase"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="#">{"Purchase Order"}</Link>
                    </li>
                    <li>
                      <Link to="/purchase_invoice">{"Purchase Invoice"}</Link>
                    </li>
                    <li>
                      <Link to="#">{"Purchase Return"}</Link>
                    </li>
                    <li>
                      <Link to="#">{"Payment"}</Link>
                    </li>
                  </ul>
                </li> */}

                <li>
                  <Link
                    to="/sales_invoicelist"
                    className="has-arrow waves-effect"
                  >
                    <i className="ri-store-3-line"></i>
                    <span className="ms-1">{"Sales Invoice"}</span>
                  </Link>
                  <ul className="sub-menu">
                    {/* <li>
                      <Link to="/sales">{"Sales Quotation"}</Link>
                    </li> */}
                    <li>
                      <Link to="/sales_invoicelist">{"Sales Invoice"}</Link>
                    </li>
                    <li>
                      <Link to="/sales/payment">{"Payment"}</Link>
                    </li>
                    {/* <li>
                      <Link to="#">{"Purchase Return"}</Link>
                    </li> */}
                    {/* <li>
                      <Link to="#">{"Payment"}</Link>
                    </li> */}
                  </ul>
                </li>  {/**/}

                {/* <li>
                  <Link
                    to="/sales_invoicelist"
                    className="waves-effect"
                  >
                    <i className="ri-store-3-line"></i>
                    <span className="ms-1">{"Sales Invoice"}</span>
                  </Link>
                  {/* <ul className="sub-menu"> */}
                  {/* <li>
                      <Link to="/sales">{"Sales Quotation"}</Link>
                    </li>
                    <li>
                      <Link to="#">{"Sales Order"}</Link>
                    </li> */}
                {/* <li>
                      <Link to="/sales_invoicelist">{"Sales Invoice"}</Link>
                    </li> */}
                {/* <li>
                      <Link to="#">{"Sales Return"}</Link>
                    </li>
                    <li>
                      <Link to="/sales/payment">{"Payment"}</Link>
                    </li> */}
                {/* </ul> */}
                {/* </li> */}

                {/* <li>
                  <Link
                    to="/#"
                    className="has-arrow waves-effect"
                    aria-expanded="true"
                  >
                    <i className=" ri-file-list-3-line"></i>
                    <span className="ms-1">{"Reports"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/reports/sales">{"Sales"}</Link>
                    </li>
                    <li>
                      <Link to="/reports/purchase">{"Purchase"}</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Sidebar;
