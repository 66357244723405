import React, { useEffect, useState } from "react";
import "../../App.css";

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  productData,
  UpdateProduct,
  DeleteProduct,
  AddProduct,
  productsSelector,
  clearProdLoadingDatas,
  clearErrormsg,
} from "../../store/reducer/ProductReducer";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "../../App.css";
import { productValidationSchema } from "../../utils/Validation";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API_STATUS } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";

function ProductAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    productDatas,
    productCount,
    loading,
    addloading,
    deleteloading,
    updateloading,
    errorMessage,
  } = useSelector(productsSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(productValidationSchema),
  });

  const [activeTab, setActiveTab] = useState("5");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const onSubmitHandler = (formData) => {
    console.log({ formData });
    dispatch(AddProduct({ formData }));
    // reset();
  };

  useEffect(() => {
    if (addloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Product Added Successfully!");
      navigate('/product_list');
      dispatch(clearProdLoadingDatas());
      reset();
    }
    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [addloading, errorMessage]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="row">
            <div className="col-md-6">
              <h4>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <a href="product_list">Product</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Product Add</BreadcrumbItem>
                </Breadcrumb>
              </h4>
            </div>
            <div className="col-md-6">
              <a href="/product_list" className="flot-right">
                <button
                  type="button"
                  className="btn btn-secondary mr-1  float-right"
                >
                  {" "}
                  Back
                </button>
              </a>
            </div>
          </div>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Row>
                      <Col md="4">
                        <div className="input-container">
                          <input
                            placeholder=" "
                            type="text"
                            id="description"
                            name="description"
                            {...register("description")}
                          />
                          <label for="description" className="required">
                            Product Description
                          </label>
                        </div>
                        {errors?.description?.message && (
                          <p className="error">{errors.description.message}</p>
                        )}
                      </Col>
                      <Col md="4">
                        <div className="input-container">
                          <input
                            placeholder=" "
                            type="text"
                            id="product_category"
                            name="product_category"
                            {...register("product_category")}
                          />
                          
                          <label for="product_category" className="required">
                            Product Category
                          </label>
                        </div>
                        {errors?.product_category?.message && (
                          <p className="error">
                            {errors.product_category.message}
                          </p>
                        )}
                      </Col>
                      <Col md="4">
                        <div className="input-container">
                          <input
                            placeholder=" "
                            type="text"
                            id="product_type"
                            name="product_type"
                            {...register("product_type")}
                          />
                          <label for="product_type" className="required">
                            Product Type
                          </label>
                          {errors?.product_type?.message && (
                            <p className="error">
                              {errors.product_type.message}
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "5",
                          })}
                          onClick={() => {
                            toggleTab("5");
                          }}
                        >
                          General
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "6",
                          })}
                          onClick={() => {
                            toggleTab("6");
                          }}
                        >
                          Other Fields
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "7",
                          })}
                          onClick={() => {
                            toggleTab("7");
                          }}
                        >
                          Images
                        </NavLink>
                      </NavItem> */}
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "8",
                          })}
                          onClick={() => {
                            toggleTab("8");
                          }}
                        >
                          Warranty
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "9",
                          })}
                          onClick={() => {
                            toggleTab("9");
                          }}
                        >
                          Settings
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="5">
                        <Card className="mt-1 p-1">
                          <CardBody className="m-0">
                            <Row>
                              <Col sm="12">
                                <Row>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        name="stock_unit"
                                        id="stock_unit"
                                        {...register("stock_unit")}
                                      />
                                      <label
                                        for="stock_unit"
                                        className="required"
                                      >
                                        Stock Unit
                                      </label>
                                    </div>
                                    {errors?.stock_unit?.message && (
                                      <p className="error">
                                        {errors.stock_unit.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        name="selling_price"
                                        id="selling_price"
                                        {...register("selling_price")}
                                      />
                                      <label for="selling_price" className="">
                                        Selling Price
                                      </label>
                                    </div>
                                    {errors?.selling_price?.message && (
                                      <p className="error">
                                        {errors.selling_price.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        name="product_cost"
                                        id="product_cost"
                                        {...register("product_cost")}
                                      />
                                      <label for="product_cost" className="">
                                        Product Cost
                                      </label>
                                    </div>
                                    {errors?.product_cost?.message && (
                                      <p className="error">
                                        {errors.product_cost.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        name="market_cost"
                                        id="market_cost"
                                        {...register("market_cost")}
                                      />
                                      <label for="market_cost">
                                        Market Cost
                                      </label>
                                    </div>
                                    {errors?.market_cost?.message && (
                                      <p className="error">
                                        {errors.market_cost.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <Row>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="purchase_description"
                                        name="purchase_description"
                                        {...register("purchase_description")}
                                      />
                                      <label for="purchase_description">
                                        Purchase Description
                                      </label>
                                    </div>
                                    {errors?.purchase_description?.message && (
                                      <p className="error">
                                        {errors.purchase_description.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="product_barcode"
                                        name="product_barcode"
                                        {...register("product_barcode")}
                                      />
                                      <label for="product_barcode">
                                        Product Barcode
                                      </label>
                                    </div>
                                    {errors?.product_barcode?.message && (
                                      <p className="error">
                                        {errors.product_barcode.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="product_barpart_nocode"
                                        name="part_no"
                                        {...register("part_no")}
                                      />
                                      <label for="part_no">Part No</label>
                                    </div>
                                    {errors?.part_no?.message && (
                                      <p className="error">
                                        {errors.part_no.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <Row>
                                  <Col md="3">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="financial_category"
                                        name="financial_category"
                                        {...register("financial_category")}
                                      />
                                      <label
                                        for="financial_category"
                                        className="required"
                                      >
                                        Financial Category
                                      </label>
                                    </div>
                                    {errors?.financial_category?.message && (
                                      <p className="error">
                                        {errors.financial_category.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="6" className="p-3">
                        <Card className="mt-1 p-1">
                          <CardBody className="m-0">
                            <Row>
                              <Col sm="12">
                                <Row className="mt-4">
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="hs_code"
                                        name="hs_code"
                                        {...register("hs_code")}
                                      />
                                      <label for="hs_code">HS Code</label>
                                    </div>
                                    {errors?.hs_code?.message && (
                                      <p className="error">
                                        {errors.hs_code.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="brand"
                                        name="brand"
                                        {...register("brand")}
                                      />
                                      <label for="brand">Brand</label>
                                    </div>
                                    {errors?.brand?.message && (
                                      <p className="error">
                                        {errors.brand.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="manufacturer"
                                        name="manufacturer"
                                        {...register("manufacturer")}
                                      />
                                      <label for="manufacturer">
                                        Manufacturer
                                      </label>
                                    </div>
                                    {errors?.manufacturer?.message && (
                                      <p className="error">
                                        {errors.manufacturer.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="rack"
                                        name="rack"
                                        {...register("rack")}
                                      />
                                      <label for="rack">Rack</label>
                                    </div>
                                    {errors?.rack?.message && (
                                      <p className="error">
                                        {errors.rack.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="shelf_expiry"
                                        name="shelf_expiry"
                                        {...register("shelf_expiry")}
                                      />
                                      <label for="shelf_expiry">
                                        Shelf Expiry
                                      </label>
                                    </div>
                                    {errors?.shelf_expiry?.message && (
                                      <p className="error">
                                        {errors.shelf_expiry.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col sm="12">
                                <h3>Dimension</h3>
                                <Row className="mt-2">
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="length"
                                        name="length"
                                        {...register("length")}
                                      />
                                      <label for="length">Length</label>
                                    </div>
                                    {errors?.length?.message && (
                                      <p className="error">
                                        {errors.length.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="height"
                                        name="height"
                                        {...register("height")}
                                      />
                                      <label for="height">Height</label>
                                    </div>
                                    {errors?.height?.message && (
                                      <p className="error">
                                        {errors.height.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="width"
                                        name="width"
                                        {...register("width")}
                                      />
                                      <label for="width">Width</label>
                                    </div>
                                    {errors?.width?.message && (
                                      <p className="error">
                                        {errors.width.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="weight"
                                        name="weight"
                                        {...register("weight")}
                                      />
                                      <label for="weight">Weight</label>
                                    </div>
                                    {errors?.weight?.message && (
                                      <p className="error">
                                        {errors.weight.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="color"
                                        name="color"
                                        {...register("color")}
                                      />
                                      <label for="color">Color</label>
                                    </div>
                                    {errors?.color?.message && (
                                      <p className="error">
                                        {errors.color.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="2">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="temperature"
                                        name="temperature"
                                        {...register("temperature")}
                                      />
                                      <label for="temperature">
                                        Temperature
                                      </label>
                                    </div>
                                    {errors?.temperature?.message && (
                                      <p className="error">
                                        {errors.temperature.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col sm="12">
                                <Row className="mt-2">
                                  <Col md="8">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="long_description"
                                        name="long_description"
                                        {...register("long_description")}
                                      />
                                      <label for="long_description">
                                        Long Description
                                      </label>
                                    </div>
                                    {errors?.long_description?.message && (
                                      <p className="error">
                                        {errors.long_description.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="7" className="p-3">
                        <Card className="mt-1 p-1">
                          <CardBody className="m-0">
                            <Row>
                              <Col sm="12">
                                <Row className="mt-4">
                                  <Col md="6">
                                    <b>Image to Print Quotation</b>
                                    <div className="input-group">
                                      <input
                                        placeholder=" "
                                        type="file"
                                        id="quotation_image"
                                        // {...register("quotation_image")}
                                        name="quotation_image"
                                      />
                                    </div>
                                    {/* {errors?.quotation_image?.message && (
                                      <p className="error">
                                        {errors.quotation_image.message}
                                      </p>
                                    )} */}
                                  </Col>
                                  <Col md="6">
                                    <b>Image to Print POS</b>
                                    <div className="input-group">
                                      <input
                                        placeholder=" "
                                        type="file"
                                        id="pos_image"
                                        name="pos_image"
                                        // {...register("pos_image")}
                                      />
                                    </div>
                                    {/* {errors?.pos_image?.message && (
                                      <p className="error">
                                        {errors.pos_image.message}
                                      </p>
                                    )} */}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="8" className="p-3">
                        <Card className="mt-1 p-1">
                          <CardBody className="m-0">
                            <Row>
                              <Col sm="12">
                                <Row className="mt-4">
                                  <Col md="4">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="warrenty_days"
                                        name="warrenty_days"
                                        {...register("warrenty_days")}
                                      />
                                      <label for="warrenty_days">
                                        Warranty period in Days
                                      </label>
                                    </div>
                                    {errors?.warrenty_days?.message && (
                                      <p className="error">
                                        {errors.warrenty_days.message}
                                      </p>
                                    )}
                                  </Col>
                                  <Col md="4">
                                    <div className="input-container">
                                      <input
                                        placeholder=" "
                                        type="text"
                                        id="warrenty_type"
                                        name="warrenty_type"
                                        {...register("warrenty_type")}
                                      />
                                      <label for="warrenty_type">
                                        Warranty Type
                                      </label>
                                    </div>
                                    {errors?.warrenty_type?.message && (
                                      <p className="error">
                                        {errors.warrenty_type.message}
                                      </p>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col sm="12">
                                    <Row className="mt-2">
                                      <Col md="6">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            type="text"
                                            id="warrenty_remarks"
                                            name="warrenty_remarks"
                                            {...register("warrenty_remarks")}
                                          />
                                          <label for="warrenty_remarks">
                                            Remarks
                                          </label>
                                        </div>
                                        {errors?.warrenty_remarks?.message && (
                                          <p className="error">
                                            {errors.warrenty_remarks.message}
                                          </p>
                                        )}
                                      </Col>
                                      <Col md="6">
                                        <div className="input-container">
                                          <input
                                            placeholder=" "
                                            type="text"
                                            id="term_condition"
                                            name="term_condition"
                                            {...register("term_condition")}
                                          />
                                          <label for="term_condition">
                                            T&C
                                          </label>
                                        </div>
                                        {errors?.term_condition?.message && (
                                          <p className="error">
                                            {errors.term_condition.message}
                                          </p>
                                        )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="9" className="p-3">
                        <Card className="mt-1 p-1">
                          <CardBody className="m-0">
                            <Row>
                              <Col sm="12">
                                <Row className="mt-4">
                                  <Col md="4">
                                    <div className="form-check form-check-right">
                                      <label
                                        className="form-check-label"
                                        htmlFor="inactive"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Inactive
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="inactive"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="batch_code_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Use Batch code for this product
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="batch_code_status"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="awt_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Enable Awt
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="awt_status"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="barcode_print_purchase_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Print Barcode while Purchase
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="barcode_print_purchase_status"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="barcode_print_sales_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Print Barcode after Sales
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="barcode_print_sales_status"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="installation_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Installation required
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="installation_status"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="hide_in_invoice_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Hide in Invoice
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="hide_in_invoice_status"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="purchase_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Can be Purchased
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="purchase_status"
                                        defaultChecked
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="sold_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Can be Sold
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="sold_status"
                                        defaultChecked
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col md="4">
                                    <div className="form-check">
                                      <label
                                        className="form-check-label"
                                        htmlFor="raw_material_status"
                                        style={{ paddingLeft: "20px" }}
                                      >
                                        Can be Raw Material
                                      </label>
                                      <input
                                        placeholder=" "
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="raw_material_status"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                    </TabContent>
                    <div className="text-end">
                      <Button
                        color="primary"
                        className=" waves-effect waves-light me-1"
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default ProductAdd;
