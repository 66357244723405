import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";

import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    Collapse,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
    customerData,
    UpdateCustomer,
    DeleteCustomer,
    customersSelector,
    clearData,
    clearCustUpdateLoading,
    clearCustLoadingDatas,
    clearCustDeleteLoading,
    clearErrormsg,
} from "../../store/reducer/CustomerReducer";

const Report = () => {
    const dispatch = useDispatch();
    let [sortBy, setSortBy] = useState("customer_name");
    let [activesortBy, setActiveSortBy] = useState("customer_name");
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [state, setState] = useState({ modal_fullscreen: false });
    const [accord, setAccord] = useState({
        col1: false,
        col2: false,
        col3: false,
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(customerValidationSchema),
    });
    const {
        customerDatas,
        customerCount,
        loading,
        addloading,
        deleteloading,
        updateloading,
        errorMessage,
    } = useSelector(customersSelector);
    const navigate = useNavigate()
const [stockReport,setStockReport]=useState({
    from_date:'',
    to_date:"",
    id:0,
})
    const [quotationDetails, setQuotationDetails] = useState([]);

    console.log(quotationDetails, 'quotationDetails');

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
    };

    const showButton = () => {
        setQuotationDetails([{
            id: 1,
            order_no: 1211,
            customer_name: "Quinton",
            order_date: "01-11-2022",
            rfq_ref_date: "01-11-2022",
            bill_amount: 1645.00,
            rfq_doc_no: 1822,
            notes: "",
            status: 1,
        }])
    };
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };


    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value);
        setPage(0);
    };

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
    };
    useEffect(() => {
        dispatch(
            customerData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    }, []);

    useEffect(() => {
        console.log(customerDatas, "customerDatas");
    }, [customerDatas]);

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Position",
                accessor: "position",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Office",
                accessor: "office",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Age",
                accessor: "age",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Start Date",
                accessor: "startdate",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Salary",
                accessor: "salary",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );
    const t_col1 = () => {
        setAccord({ col1: !accord.col1, col2: false, col3: false });
    };
    const t_col2 = () => {
        setAccord({ col1: false, col2: !accord.col2, col3: false });
    };

    const onSubmitHandler = (formData) => {
        console.log({ formData });
        //dispatch(signInScheduler({ formData }));
        // reset();
    };
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    const breadcrumbItems = [
        { title: "Tables", link: "/" },
        { title: "Data Tables", link: "#" },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div>
                        <h2>Purchase Report</h2>
                    </div>
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="col-md-12">
                                        <div class="input-container">
                                            <input placeholder=" " type="date" className="required" defaultValue={dayjs(new Date())} id="from_date" onChange={(e) => setStockReport({...stockReport,from_date:e.target.value})} name="from_date" />
                                            <label for="from_date">From Date</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div class="input-container">
                                            <input placeholder=" " type="date" className="required" defaultValue={dayjs(new Date())} id="to_date" onChange={(e) => setStockReport({...stockReport,to_date:e.target.value})} name="to_date" />
                                            <label for="to_date">From Date</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-4 d-flex justify-content-start align-items-center">
                                    <Button color='primary' onClick={showButton}>
                                        Show
                                    </Button>
                                </div>
                                <div className="col-md-4 row">
                                    <input
                                        type="text"
                                        name="search"
                                        className="form-control align-self-center"
                                        value={searchQuery}
                                        style={{height:'50px'}}
                                        onChange={(e) => {
                                            searchData(e);
                                        }}
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                            <div className="table-responsive react-table">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead className="table-light table-nowrap">
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "oder_no" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("oder_no");
                                                        }}
                                                    >
                                                        Order No
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "customer_name" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("customer_name");
                                                        }}
                                                    >
                                                        Customer
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "oder_no" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("oder_no");
                                                        }}
                                                    >
                                                        Order Date
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "rfq_ref_date" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("rfq_ref_date");
                                                        }}
                                                    >
                                                        RFQ Ref Date
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "bill_amount" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("bill_amount");
                                                        }}
                                                    >
                                                        Bill Amount
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "rfq_doc_no" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("rfq_doc_no");
                                                        }}
                                                    >
                                                        RFQ Doc No
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "notes" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("notes");
                                                        }}
                                                    >
                                                        Notes
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "status" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("status");
                                                        }}
                                                    >
                                                        Status
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {quotationDetails &&
                                                quotationDetails.map((row) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row?.order_no}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.customer_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.order_date}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.rfq_ref_date}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.bill_amount}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.rfq_doc_no}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.notes}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.status === 1 ? "Active" : "In-active"}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={9}
                                                    count={quotationDetails.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>


                        </CardBody>
                    </Card>
                    <div className="text-end">
                        <Button color="primary">
                            Download File
                        </Button>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Report;
