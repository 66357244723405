import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";
import { assestURL } from "../../services/config";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Collapse,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
    Spinner,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
    salesinvoiceData,
    salesinvoiceSelector,
    clearSinvLoadingDatas,
    clearErrormsg,
    clearData,
    generatePdf,
    setProforma,
    setDraft,
    clearPdfData,
    setInvoiceStat,
    getSalesStat,
    getZatcaValidation,
    viewZatca,
    searchInvoiceDetails,
} from "../../store/reducer/SalesInvoiceReducer";
import { API_STATUS } from "../../utils/constants";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { companySelector, getCompanyDetails } from "../../store/reducer/CompanyReducer";
import { Box, Drawer, Modal, Typography } from "@mui/material";
import { debounce } from "lodash";
const SalesInvoiceList = () => {
    const dropdownValues = {
        0:
        {
            data: [{ handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setInvoice', label: 'Set To Invoice' },],
        },
        1: {
            data: [
                { handlerName: 'setInvoice', label: 'Set To Invoice' }
                // , { handlerName: 'setDraft', label: 'Set To Draft' }
            ],
        },
        2: {
            data: [
                // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' }
            ],
        },
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        boxShadow: 30,
        p: 4,
    };

    const infoStyle = {
        boxShadow: ' 2px 4px 2px 4px #d6e6ff ',
        padding: '10px'
    }

    const WarningStyle = {
        boxShadow: ' 2px 4px 2px 4px #ffd28e ',
        padding: '10px',
        marginTop: '16px'
    }

    const dispatch = useDispatch();
    let [sortBy, setSortBy] = useState("invoice_no");
    const [openDrawer, setOpenDrawer] = useState(false)
    let [activesortBy, setActiveSortBy] = useState("invoice_no");
    const [info, setInfo] = useState({ 0: false });
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");
    console.log(searchQuery, 'searchQuery')
    const [state, setState] = useState({ modal_fullscreen: false });
    let
        { company_details }
            = useSelector(companySelector);
    const [accord, setAccord] = useState({
        col1: false,
        col2: false,
        col3: false,
    });
    const {
        salesinvoiceDatas,
        salesinvoiceCount,
        loading,
        errorMessage,
        pdfMessage,
        setDraftLoading,
        generateLoading,
        getZatcaValidationloading,
        setProformaLoading,
        setInvoiceStatLoading,
        getSalesStatLoading,
        StatDatas,
        zatcaData
    } = useSelector(salesinvoiceSelector);
    const navigate = useNavigate();
    console.log(salesinvoiceDatas, 'salesinvoiceDatas');
    const tog_fullscreen = () => {
        navigate("/sales_invoice");
    };
    console.log(company_details, 'company_details')
    dropdownValues[2].data = company_details?.zatca_validation ? [
        // { handlerName: 'setProfoma', label: 'Set To Proforma' }, { handlerName: 'setDraft', label: 'Set To Draft' },
        { handlerName: 'validateZatca', label: 'Validate Zatca' }] : [{ handlerName: 'setProfoma', label: 'Set To Proforma' }
        //  , { handlerName: 'setDraft', label: 'Set To Draft' }
    ]

    useEffect(() => {
        console.log(generateLoading, "generateLoading");
        if (generateLoading === "Fulfilled") {
            window.open(`${assestURL}${pdfMessage}`, "_blank", "noreferrer");
        }
        dispatch(clearPdfData());
    }, [pdfMessage]);
    const handleDropDown = (data, type) => {
        console.log(data, "data row");
        if (type === "print") {
            dispatch(generatePdf(data.id));
        }
        if (type === "setProfoma") {
            dispatch(setProforma({ id: data.id }));
        }
        if (type === "setDraft") {
            dispatch(setDraft({ id: data.id }));
        }
        if (type === "setInvoice") {
            dispatch(setInvoiceStat({ id: data.id }));
        }
        if (type === "validateZatca") {
            dispatch(getZatcaValidation({ ID: data.id }))
        }
    };

    const salesDispatch = () => {
        dispatch(
            salesinvoiceData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
        dispatch(getSalesStat({}))
        dispatch(getCompanyDetails())
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpenDrawer(open)
    };



    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        salesDispatch();
    };
    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const searchData = (e) => {
        searchQuery = e;
        setSearchQuery(e);
        setPage(0);
        dispatch(searchInvoiceDetails({ e }))
    };

    const handleZatcaView = data => {
        setOpenDrawer(true);
        console.log('first')
        dispatch(viewZatca({ ID: data?.id }))
    }

    const debounceSearch = useCallback(debounce(searchData, 500), []);

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
    };
    useEffect(() => {
        salesDispatch();
    }, []);

    useEffect(() => {
        if (setDraftLoading === API_STATUS.FULFILLED) {
            toastr.success("Updated");
            salesDispatch();
            dispatch(clearSinvLoadingDatas());
        }
        if (getZatcaValidationloading === API_STATUS.FULFILLED) {
            toastr.success("Updated");
            salesDispatch();
            dispatch(clearSinvLoadingDatas());
        }
        if (setProformaLoading === API_STATUS.FULFILLED) {
            toastr.success("Updated");
            salesDispatch();
            dispatch(clearSinvLoadingDatas());
        }
        if (setInvoiceStatLoading === API_STATUS.FULFILLED) {
            toastr.success("Updated");
            salesDispatch();
            dispatch(clearSinvLoadingDatas());
        }
        console.log(setDraftLoading, "setDraftLoading");
    }, [setDraftLoading, setProformaLoading, setInvoiceStatLoading, getSalesStatLoading, getZatcaValidationloading]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-content-between">
                        <h2>Sales Invoice</h2>
                        <button
                            type="button"
                            className="waves-effect waves-light btn btn-primary mb-2"
                            onClick={tog_fullscreen}
                        >
                            Add
                        </button>
                    </div>
                    <Row>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">
                                                Total Invoices This Year
                                            </p>
                                            <h4 className="mb-0">{StatDatas?.invoice_this_year?.length}</h4>
                                        </div>
                                        <div className="text-primary">
                                            <i className={"ri-stack-line  font-size-24"}></i>
                                        </div>
                                    </div>
                                </CardBody>

                                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1">
                                            <i className="mdi mdi-menu-up"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">
                                                Total Invoices This Month
                                            </p>
                                            <h4 className="mb-0">{StatDatas?.invoice_this_month?.length}</h4>
                                        </div>
                                        <div className="text-primary">
                                            <i className={"ri-funds-line  font-size-24"}></i>
                                        </div>
                                    </div>
                                </CardBody>

                                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-danger font-size-11 me-1">
                                            <i className="mdi mdi-menu-down"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Decrease</span>
                                    </div>
                                </CardBody> */}
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">
                                                Number of Proforma
                                            </p>
                                            <h4 className="mb-0">{StatDatas?.no_of_proforma?.length}</h4>
                                        </div>
                                        <div className="text-primary">
                                            <i className={"ri-stack-line  font-size-24"}></i>
                                        </div>
                                    </div>
                                </CardBody>

                                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1">
                                            <i className="mdi mdi-menu-up"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
                            </Card>
                        </Col>
                        <Col md="3">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <p className="text-truncate font-size-14 mb-2">
                                                No of Drafted
                                            </p>
                                            <h4 className="mb-0">{StatDatas?.no_of_drafted?.length}</h4>
                                        </div>
                                        <div className="text-primary">
                                            <i className={"ri-funds-line  font-size-24"}></i>
                                        </div>
                                    </div>
                                </CardBody>

                                {/* <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge badge-soft-success font-size-11 me-1">
                                            <i className="mdi mdi-menu-up"> </i> 2
                                        </span>
                                        <span className="text-muted ms-2">Increase</span>
                                    </div>
                                </CardBody> */}
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-8">
                                    <h5>
                                        {" "}
                                        <span>{ }</span>
                                    </h5>
                                </div>
                                <div className="col-md-4 row">
                                    <input
                                        type="text"
                                        name="search"
                                        className="form-control"
                                        style={{ float: "right" }}
                                        // value={searchQuery}
                                        onChange={(e) => { debounceSearch(e.target.value) }}
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                            <div className="table-responsive react-table">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead className="table-light table-nowrap">
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "invoice_no" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("invoice_no");
                                                        }}
                                                    >
                                                        Invoice No
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">Customer</StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "invoice_date" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("invoice_date");
                                                        }}
                                                    >
                                                        Invoice Date
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "total" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("total");
                                                        }}
                                                    >
                                                        Invoice Amount
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "status" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("status");
                                                        }}
                                                    >
                                                        Status
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        {(generateLoading !== 'Pending' && getZatcaValidationloading !== 'Pending') ?
                                            <TableBody>
                                                {salesinvoiceDatas &&
                                                    salesinvoiceDatas.map((row, index) => (
                                                        <StyledTableRow>
                                                            <StyledTableCell align="">
                                                                {row?.invoice_no}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row?.customer?.customer_name}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {dayjs(row?.invoice_date).format("DD-MM-YYYY")}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row?.total}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.status === 0
                                                                    ? "Draft"
                                                                    : row.status === 1
                                                                        ? "Proforma"
                                                                        : "Invoice"}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="" className="button-items">
                                                                <div className="d-flex">
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        title="Edit"
                                                                        className="btn btn-primary  "
                                                                        onClick={() => {
                                                                            navigate(`edit/${row.id}`)
                                                                        }}
                                                                    >
                                                                        <i className=" ri-ball-pen-line"></i>
                                                                    </a>
                                                                    <div className="btn btn-secondary" title="print" onClick={() =>
                                                                        handleDropDown(row, 'print')
                                                                    }>
                                                                        <i className=" ri-printer-line"></i>
                                                                    </div>
                                                                    {/* <a
                                                                        href="javascript:void(0);"
                                                                        title="Validate Zatca"
                                                                        className="btn btn-success"
                                                                        onClick={handleZatca}
                                                                    >
                                                                        <i class="ri-send-plane-fill"></i>
                                                                    </a> */}
                                                                    {
                                                                        row.status != 3 ?
                                                                            <>
                                                                                <Dropdown
                                                                                    isOpen={info[index]}
                                                                                    direction="left"
                                                                                    toggle={() => {
                                                                                        info[index] = !info[index];
                                                                                        setInfo({ ...info });
                                                                                    }}
                                                                                >
                                                                                    <DropdownToggle color="danger" caret>
                                                                                        <i
                                                                                            class="ri-more-2-line"
                                                                                        ></i>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        {
                                                                                            dropdownValues[row.status].data.map((items) => {
                                                                                                return (
                                                                                                    <DropdownItem
                                                                                                        onClick={() =>
                                                                                                            handleDropDown(row, items.handlerName)
                                                                                                        }
                                                                                                    >
                                                                                                        {items.label}
                                                                                                    </DropdownItem>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </DropdownMenu>
                                                                                </Dropdown>
                                                                            </> : <>
                                                                                <div className="btn btn-success" title="View" onClick={() => handleZatcaView(row)}>
                                                                                    <i class="ri-eye-line"></i>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                            </TableBody>
                                            :
                                            <td colSpan={7}><div className="d-flex justify-content-center" style={{ width: '100%' }}><Spinner className="m-2" color="success" /></div></td>}
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={9}
                                                    count={salesinvoiceCount}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
                <Modal
                    open={openDrawer}
                    onClose={toggleDrawer(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <div className="d-flex justify-content-between">
                                <h3>Zatca Response</h3>
                                <p style={{ cursor: 'pointer' }} onClick={toggleDrawer(false)}><i class="ri-close-line"></i></p>
                            </div>
                        </Typography>
                        <hr />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div >
                                {
                                    zatcaData?.viewData && JSON.parse(zatcaData.viewData?.info).map((info) => {
                                        return (
                                            <div style={infoStyle}>
                                                <div>
                                                    <b>Type : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.type
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Code : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.code
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Category : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.category
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Message : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.message
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Status : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.status
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div >
                                {
                                    zatcaData?.viewData && JSON.parse(zatcaData.viewData?.warnings).map((info) => {
                                        return (
                                            <div style={WarningStyle}>
                                                <div>
                                                    <b>Type : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.type
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Code : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.code
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Category : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.category
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Message : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.message
                                                        }
                                                    </p>
                                                </div>
                                                <div>
                                                    <b>Status : </b>
                                                    <p style={{ textIndent: '16px' }}>
                                                        {
                                                            info?.status
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </>
    );
};
export default SalesInvoiceList;
