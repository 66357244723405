import * as yup from "yup";

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
    }),
  password: yup.string().required("Password is required"),
});

export const userValidationSchema = yup.object().shape({
  name: yup.string().required("User Name is required"),
  email: yup.string().required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value) || validatePhone(parseInt(value ?? "0"));
    }),
  password: yup.string().required("Password is required"),
  mobile: yup
    .number()
    .transform((_, val) => (val !== "" ? Number(val) : null)).
    required("Mobile is required"),
  // profile_url: yup.string().nullable(true),
});
export const salesQuotationValidation = yup.object().shape({
  customer_name: yup.string().required("Sales Name is required"),
  contact_name: yup.string().nullable(true),
  quotation_date: yup.string(),
  rfq_date: yup.string(),
  requested_by: yup.string(),
  valid_upto: yup.string(),
  product_desc: yup.string(),
  quantity: yup.number(),
  unit: yup.string(),
  unit_price: yup.number(),
  amount: yup.number(),
  customer_notes: yup.string(),
  total_amt: yup.number(),
  discount: yup.string(),
  vat: yup.string(),
  vat_amt: yup.number(),
  round_off: yup.string(),
  net_amount: yup.string(),
});

export const companyvalidator = yup.object().shape({
  company_name: yup.string().required(),
  email: yup.string().required(),
  tax_type: yup.string().required(),
  address: yup.string().required(),
  // financial_yr_from: yup.date().required(),
  // financial_yr_to: yup.date().required()
})

export const paymentValidator = yup.object().shape({
  // customer_id: yup.number().required(),
  // payment_type: yup.number(),
  // payment_date: yup.date(),
  sales_person: yup.string(),
  invoice_bal: yup.string(),
  pay_amount: yup.string(),
  // pay_mode: yup.number(),
  balance_after_payment: yup.string(),
  notes: yup.string(),
})

export const customerValidationSchema = yup.object().shape({
  customer_name: yup.string(),
  customer_name_arabic: yup.string().nullable(true),
  contact_person: yup.string().nullable(true),
  contact_number: yup
    .number()
    .nullable(true)
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  contact_email: yup.string().nullable(true),
  payment_method: yup.string().nullable(true),
  credit_limit: yup.string().nullable(true),
  duedays_limit: yup.string().nullable(true),
  invoice_due_days: yup.string().nullable(true),
  vat_no: yup.string().nullable(true),
  address: yup.string().nullable(true),
  zip_code: yup.string().nullable(true),
  city: yup.string().nullable(true),
  fax: yup.string().nullable(true),
});

export const productValidationSchema = yup.object().shape({
  description: yup.string().required("Product Description is required"),
  product_category: yup.string().nullable(true),
  product_type: yup.string().nullable(true),
  stock_unit: yup.string().required("Stock Unit is required"),
  purchase_description: yup.string().nullable(true),
  selling_price: yup.string().nullable(true),
  product_cost: yup.string().nullable(true),
  market_cost: yup.string().nullable(true),
  product_barcode: yup.string().nullable(true),
  part_no: yup.string().nullable(true),
  financial_category: yup
    .string()
    .nullable(true)
    .required("Financial Category is required"),
  hs_code: yup.string().nullable(true),
  brand: yup.string().nullable(true),
  manufacturer: yup.string().nullable(true),
  rack: yup.string().nullable(true),
  length: yup.string().nullable(true),
  height: yup.string().nullable(true),
  width: yup.string().nullable(true),
  weight: yup.string().nullable(true),
  color: yup.string().nullable(true),
  temperature: yup.string().nullable(true),
  long_description: yup.string().nullable(true),
  warrenty_days: yup.string().nullable(true),
  warrenty_type: yup.string().nullable(true),
  warrenty_remarks: yup.string().nullable(true),
  term_condition: yup.string().nullable(true),
});
// quotation_image: yup.string().nullable(true),
// pos_image: yup.string().nullable(true),
export const vendorValidationSchema = yup.object().shape({
  vendor_name: yup.string().required("Vendor Name is required"),
  vendor_name_arabic: yup.string().nullable(true),
  contact_person: yup.string().nullable(true),
  contact_number: yup
    .number()
    .nullable(true)
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  contact_email: yup.string().nullable(true),
  payment_method: yup.string().nullable(true),
  credit_limit: yup.string().nullable(true),
  duedays_limit: yup.string().nullable(true),
  invoice_due_days: yup.string().nullable(true),
  vat_no: yup.string().nullable(true),
  address: yup.string().nullable(true),
  zip_code: yup.string().nullable(true),
  city: yup.string().nullable(true),
  fax: yup.string().nullable(true),
});
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const signUpValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .test("email", "Email is invalid", (value) => {
      return validateEmail(value);
    }),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .test("phone", "Phone is invalid", (value) => {
      return validatePhone(parseInt(value ?? "0"));
    }),
  password: yup
    .string()
    .required("Password is required")
    .matches(passwordRules, { message: "Please create a stronger password" }),
});

const validateEmail = (email) => {
  return yup.string().email().isValidSync(email);
};

const validatePhone = (phone) => {
  return yup
    .number()
    .integer()
    .positive()
    .test((phone) => {
      return phone &&
        phone.toString().length >= 8 &&
        phone.toString().length <= 14
        ? true
        : false;
    })
    .isValidSync(phone);
};
