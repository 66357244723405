import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import classnames from "classnames";
import "../../App.css";
import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import dayjs from "dayjs";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../utils/pagination";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { salesQuotationValidation } from "../../utils/Validation";
import { useNavigate, useParams } from "react-router";
import _ from "lodash";
import {
  customerData,
  customersSelector,
} from "../../store/reducer/CustomerReducer";
import {
  productData,
  productsSelector,
} from "../../store/reducer/ProductReducer";
import {
  salesinvoiceSelector,
  AddSalesinvoice,
  clearErrormsg,
  clearSinvLoadingDatas,
  salesinvoiceDataByID,
  UpdateSalesinvoice,
} from "../../store/reducer/SalesInvoiceReducer";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API_STATUS } from "../../utils/constants";
const SalesInvoiceEdit = () => {
  const [activeTab, setActiveTab] = useState("5");
  const dispatch = useDispatch();
  const params = useParams();
  const [editID, setEditID] = useState(params?.id);
  const { customerDatas } = useSelector(customersSelector);
  const { productDatas } = useSelector(productsSelector);
  const { updateloading, errorMessage, salesinvoiceDatasByID } =
    useSelector(salesinvoiceSelector);
  console.log(productDatas, "data");
  let todayDate = new Date();
  const [companyDetails, setCompanyDetails] = useState({
    customer_id: "",
    invoice_date: dayjs(todayDate).format("MM-DD-YYYY"),
    // quotation_date: "",
    invoice_no: 0,
    // customer_po_no: "",
    // po_date: "",
    // delivery_no: "",
    products: [
      {
        product_id: "",
        qty: 0,
        price: 0,
        amount: 0,
        additional_description: "",
        // vat: 0,
        // vat_amt: 0,
      },
    ],
    customer_notes: "",
    sub_total: 0,
    discount: 0,
    // vat: "",
    tax: "",
    tax_amount: 0,
    round_amount: "",
    total: 0,
    delete_products: [],
  });
  console.log(companyDetails, "companyDetails");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(salesQuotationValidation),
  });
  const navigate = useNavigate();
  let [sortBy, setSortBy] = useState("customer_name");
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  //   let [result, setResult] = useState(0);
  //   let [vatresult, setvatresult] = useState(0);
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };

  const handleSave = () => {
    console.log(customerDatas, "companyDetails");
    console.log(companyDetails, "companyDetails");
    let postData = companyDetails;
    delete postData?.payment_status;
    delete postData?.payment_balance
    dispatch(UpdateSalesinvoice({ postData,editID }));
    // navigate("/sales");
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  const handleDeleteRow = (id, row_id) => {
    console.log(id,row_id, "id");
    if (companyDetails.products.length != 1) {
      companyDetails.products.splice(id, 1);
      if (row_id) {
        let deleteids = companyDetails.delete_products;
        console.log(deleteids);
        deleteids.push(row_id);
        console.log(deleteids);
        companyDetails.delete_products = deleteids;
      }
      setCompanyDetails({
        ...companyDetails,
      });
      calculateDetails()
    }
  };

  useEffect(() => {
    dispatch(productData({}));
    dispatch(customerData({}));
    dispatch(salesinvoiceDataByID({ editID }));
  }, []);

  useEffect(() => {
    if (salesinvoiceDatasByID) {
      let data = JSON.parse(JSON.stringify(salesinvoiceDatasByID));
      let {
        sales_invoice_details,
        updated_at,
        trash,
        status,
        created_at,
        ...others
      } = data;
      // let companydata =
      sales_invoice_details.map(function (item) {
        delete item.updated_at;
        delete item.created_at;
        delete item.invoice_id;
        return item;
      });
      console.log({...others},"others")
      setCompanyDetails({
        products: sales_invoice_details,
        ...others,
        delete_products: [],
      });
    //   calculateDetails()
    }
    console.log(salesinvoiceDatasByID, "salesinvoiceDatasByID");
  }, [salesinvoiceDatasByID]);

  useEffect(() => {
    const index = companyDetails.products.length - 1;
    if (
      companyDetails.products[index]?.product_id !== "" &&
      companyDetails.products[index]?.product_id !== undefined
    ) {
      companyDetails.products.push({
        product_id: "",
        qty: 0,
        price: 0,
        amount: 0,
        // vat: "",
        // vat_amt: 0.0,
        additional_description: "",
      });
      setCompanyDetails({
        ...companyDetails,
      });
    }
  }, [companyDetails]);

  const calculateDetails = () => {
    let result = 0;
    let vatresult = 0;
    if (companyDetails.tax !== "") {
      vatresult = companyDetails.tax;
    }
    console.log(companyDetails.products,"sadsad");
    for (let index = 0; index < companyDetails.products.length; index++) {
      const element = companyDetails.products[index];
        result += parseFloat(parseFloat(element?.price) * parseFloat(element?.qty));
    console.log(parseFloat(element?.price) * parseFloat(element?.qty), result,"sadsad");
      // if (element?.vat_amt !== "") { vat_result += parseFloat(element?.vat_amt) };
    }
    let vat_amt = parseFloat((result * vatresult) / 100).toFixed(2);
    setCompanyDetails({
      ...companyDetails,
      sub_total: result,
      tax_amount: vat_amt,
      total: parseFloat(result) + parseFloat(vat_amt),
      round_amount: 0,
    });
  };

  //   let result = 0;
  //   let vatresult = 0;
  //   for (let index = 0; index < companyDetails.products.length; index++) {
  //     const element = companyDetails.products[index];
  //     if (typeof element?.amount == "number")
  //       result += parseFloat(element?.amount);
  //     // if (element?.vat_amt !== "") { vat_result += parseFloat(element?.vat_amt) };
  //   }
  //   useEffect(() => {
  //     let vat_amt=parseFloat(result*vatresult/100).toFixed(2);
  //     setCompanyDetails({
  //       ...companyDetails,
  //       total_amt: result,
  //       vat_amt: vat_amt,
  //       net_amount: parseFloat(result) + parseFloat(vat_amt),
  //       round_off: 0,
  //     });
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [result, vatresult]);
  
  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    companyDetails[dest] = type !== "" ? Number(value) : value;
    setCompanyDetails({
      ...companyDetails,
    });
  };

  useEffect(() => {
    if (updateloading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Sales Invoice Updated Successfully!");
      navigate("/sales_invoicelist");
      dispatch(clearSinvLoadingDatas());
      // reset();
    }
    if (errorMessage) {
      toastr.clear();
      if (errorMessage.message) toastr.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toastr.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [updateloading]);

  const Customerdrop = () => {
    if (companyDetails.customer_id) {
      let fdata = _.filter(customerDatas, { id: companyDetails.customer_id });
      console.log(fdata, "fdata");
      return fdata;
    } else {
      return [];
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h4 className="page-title">Invoice</h4>
            <div
              className="d-flex justify-content-between"
              style={{ minWidth: "400px" }}
            >
              <div></div>
              <h5>
                Net Amount: <span>{companyDetails?.net_amount}</span>
              </h5>
            </div>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col md="4">
                  <div className="d-flex align-items-end">
                    <div class="input-container w-100">
                      <Select
                        placeholder="Customer"
                        className="js-states select-control"
                        options={customerDatas ? customerDatas : []}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        labelField={"customer_name"}
                        valueField={"id"}
                        values={Customerdrop()}
                        name="country_name"
                        onChange={(value) => {
                          companyDetails.customer_id = value[0].id;
                          setCompanyDetails({
                            ...companyDetails,
                          });
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="d-flex align-items-end">
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="date"
                        value={dayjs(companyDetails.invoice_date).format(
                          "YYYY-MM-DD"
                        )}
                        id="date"
                        onChange={(e) => handleInput(e, "invoice_date", "")}
                        name="date"
                      />
                      <label for="date">Date</label>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div class="input-container">
                    <input
                      placeholder=" "
                      type="text"
                      value={companyDetails.invoice_no}
                      onChange={(e) => handleInput(e, "invoice_no", "")}
                      name="invoice_no"
                    />
                    <label className="required" for="invoice_no">
                      Invoice No
                    </label>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                                <Col md="4">
                                    <div class="input-container">
                                        <input
                                            placeholder=" "
                                            type="text"
                                            value={companyDetails.customer_po_no}
                                            onChange={(e) => handleInput(e, "customer_po_no", "")}
                                            name="customer_po_no"
                                        />
                                        <label className="required" for="customer_po_no">Customer PO No</label>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div class="input-container">
                                        <input
                                            placeholder=" "
                                            type="date"
                                            className="required"
                                            defaultValue="2020-03-19"
                                            value={companyDetails.po_date}
                                            id="po_date"
                                            onChange={(e) => handleInput(e, "po_date", "")}
                                            name="po_date"
                                        />
                                        <label for="po_date">PO Date</label>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="d-flex align-items-end">
                                        <div class="input-container w-100">
                                            <input
                                                placeholder=" "
                                                type="text"
                                                id="delivery_no"
                                                name="delivery_no"
                                                value={companyDetails.delivery_no}
                                                onChange={(e) => handleInput(e, "delivery_no", "")}
                                            />
                                            <label for="delivery_no">Delivery No</label>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="table-responsive react-table">
                <TableContainer
                  component={Paper}
                  style={{ minHeight: "300px" }}
                >
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "product_desc" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("product_desc");
                            }}
                          >
                            Product Description
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "product_add_desc" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("product_add_desc");
                            }}
                          >
                            Additional Description
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "qty" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("qty");
                            }}
                          >
                            Qty
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "unit_price" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("unit_price");
                            }}
                          >
                            Unit Price
                          </TableSortLabel>
                        </StyledTableCell>
                        {/* <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "vat" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("vat");
                                                        }}
                                                    >
                                                        VAT
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "vat_amt" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("vat_amt");
                                                        }}
                                                    >
                                                        VAT Amount
                                                    </TableSortLabel>
                                                </StyledTableCell> */}
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "amount" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("amount");
                            }}
                          >
                            Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyDetails.products &&
                        companyDetails.products.map((row, index) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              <Controller
                                name="Product Description"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className="js-states select-control"
                                    options={productDatas ? productDatas : []}
                                    {...field}
                                    disabled={false}
                                    dropdownHandle={true}
                                    searchable={true}
                                    labelField={"description"}
                                    valueField={"id"}
                                    values={
                                      row.product_id
                                        ? _.filter(productDatas, {
                                            id: row.product_id,
                                          })
                                        : []
                                    }
                                    name="country_name"
                                    onChange={(value) => {
                                      console.log(value, "products");
                                      if (value.length > 0) {
                                        companyDetails.products[
                                          index
                                        ].product_id = value[0].id;
                                        setCompanyDetails({
                                          ...companyDetails,
                                        });
                                      }
                                    }}
                                  />
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`additional_description${index}`}
                                  name={`additional_description${index}`}
                                  value={row.additional_description}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    companyDetails.products[
                                      index
                                    ].additional_description = value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`quantity${index}`}
                                  name={`quantity${index}`}
                                  value={row.qty}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    let data = companyDetails.products[index];
                                    companyDetails.products[index].qty = value;
                                    companyDetails.products[index].amount =
                                      data.price * value;
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  placeholder=" "
                                  type="text"
                                  id={`unit_price${index}`}
                                  name={`unit_price${index}`}
                                  value={row.price}
                                  onChange={(e) => {
                                    const { value } = e.target;
                                    let data = companyDetails.products[index];
                                    companyDetails.products[index].price =
                                      value;
                                    companyDetails.products[index].amount =
                                      value * data.qty;
                                    // companyDetails.products[index].vat_amt = (data.quantity * (data.vat / 100) * value).toFixed(4)
                                    setCompanyDetails({
                                      ...companyDetails,
                                    });
                                    calculateDetails();
                                  }}
                                />
                                {/* <label for={`${index}`}></label> */}
                              </div>
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                                                            <div class="input-container w-100 m-1">
                                                                <Select
                                                                    className="js-states select-control"
                                                                    options={[{ id: 15, name: "VAT 15%" }]}
                                                                    placeholder="VAT"
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="country_name"
                                                                    onChange={(value) => {
                                                                        let data = companyDetails.products[index];
                                                                        companyDetails.products[index].vat = value[0].id
                                                                        let result = (data.unit_price * (value[0].id / 100)) * data.quantity;
                                                                        companyDetails.products[index].vat_amt = result.toFixed(4);
                                                                        // let ans =
                                                                        //     companyDetails.products[index].unit_price * 
                                                                        setCompanyDetails({
                                                                            ...companyDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            <div class="input-container w-100 m-1">
                                                                <input
                                                                    value={companyDetails.products[index]?.vat_amt}
                                                                    placeholder=" "
                                                                    disabled
                                                                    type="text"
                                                                    id={`vat_amount${index}`}
                                                                    name={`vat_amount${index}`}
                                                                />
                                                            </div>
                                                        </StyledTableCell> */}
                            <StyledTableCell align="">
                              <div class="input-container w-100 m-1">
                                <input
                                  value={companyDetails.products[index]?.amount}
                                  placeholder=" "
                                  disabled
                                  type="text"
                                  id={`amount${index}`}
                                  name={`amount${index}`}
                                  onChange={(e) => {
                                    console.log("object");
                                    calculateDetails();
                                  }}
                                />
                                {/* <label for={`${index}`}></label> */}
                              </div>
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                                                              {dayjs(row.created_at).format(
                                                                  "MM-DD-YYYY hh:mm A"
                                                              )}
                                                          </StyledTableCell> */}
                            <StyledTableCell align="" className="button-items">
                              <a
                                href="javascript:void(0);"
                                title="Delete"
                                className="btn btn-danger delete-btn"
                                onClick={() => {
                                  handleDeleteRow(index, row?.id);
                                }}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </a>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="invoice-width">
                  <div class="input-container w-100">
                    <textarea
                      id="net_amount"
                      rows={3}
                      name="net_amount"
                      onChange={(e) => handleInput(e, "customer_notes", "")}
                      required
                    />
                    <label for="net_amount">Customer Notes</label>
                  </div>
                </div>
                <div className="invoice-width">
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="total_amount"
                      name="total_amount"
                      value={companyDetails.sub_total}
                      disabled
                      required
                    />
                    <label for="total_amount">Total Amount</label>
                  </div>
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="discount"
                      name="discount"
                      disabled
                    />
                    <label for="discount">Discount</label>
                  </div>
                  <div className="input-container w-100"></div>
                  <div class="input-container w-100">
                    <Select
                      className="js-states select-control"
                      options={[{ id: 15, name: "VAT 15%" }]}
                      placeholder="VAT Percent"
                      values={
                        companyDetails.tax
                          ? _.filter([{ id: 15, name: "VAT 15%" }], {
                              id: companyDetails.tax,
                            })
                          : []
                      }
                      disabled={false}
                      dropdownHandle={true}
                      searchable={true}
                      labelField={"name"}
                      valueField={"id"}
                      name="country_name"
                      onChange={(value) => {
                        companyDetails.tax = value[0].id;
                        setCompanyDetails({
                          ...companyDetails,
                        });
                        calculateDetails();
                      }}
                    />
                  </div>
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="vat_amount"
                      name="vat_amount"
                      disabled
                      value={companyDetails?.tax_amount}
                    />
                    <label for="vat_amount">VAT Amount</label>
                  </div>
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="round_off"
                      name="round_off"
                      disabled
                      value={companyDetails.round_amount}
                    />
                    <label for="round_off">Round Off</label>
                  </div>
                  <div class="input-container w-100">
                    <input
                      placeholder=" "
                      type="text"
                      id="net_amount"
                      name="net_amount"
                      disabled
                      value={companyDetails.total}
                    />
                    <label for="net_amount">Net Amount</label>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className="text-end">
            <Button
              color="primary"
              className=" waves-effect waves-light me-1"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};
export default SalesInvoiceEdit;
