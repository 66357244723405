import { Card, CardBody, Col, Row, Container, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import classnames from "classnames";
import "../App.css"
import { companySelector, getCompanyDetails, postCompanyDetails } from "../store/reducer/CompanyReducer"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
function Check() {
  const [activeTab, setActiveTab] = useState("5")
  const dispatch = useDispatch()
  const [companyDetails, setCompanyDetails] = useState({
    id: 1,
    name: '',
    locale_name: '',
    short_name: "",
    responsible_person: "",
    phone1: null,
    phone2: null,
    email: "",
    fax: "",
    address: "",
    postal_code: null,
    country: "",
    currency: "",
    tax_registration_name: "",
    tax_registration_no: null,
    tax_type: "",
    tax_category: "",
    cr_number: null,
    company_slogan: "",
    website: "",
    history: "",
    since: null,
    certifications: "",
    shipping_name: "",
    shipping_street: "",
    shipping_appartment: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zip: null,
    shipping_phone: null,
    financial_yr_from: dayjs(new Date()),
    financial_yr_to: dayjs(new Date()),
    setting_tz: "",
    decimal_places: null,
    company_banks: "",
    language_setting: ""
  })
  let
    { company_details }
      = useSelector(companySelector);
  console.log(company_details, 'company_details');
  useEffect(() => {
    dispatch(getCompanyDetails(1))
  }, [])

  useEffect(() => {
    if (company_details)
      setCompanyDetails({
        name: company_details?.company_name,
        locale_name: company_details?.lacale_name,
        short_name: company_details?.short_name,
        responsible_person: company_details?.responsible_person,
        phone1: Number(company_details?.phone1),
        phone2: Number(company_details?.phone2),
        email: company_details?.email,
        fax: company_details?.fax,
        address: company_details?.address,
        postal_code: company_details?.postal_code,
        country: company_details?.country,
        currency: company_details?.currency,
        tax_registration_name: company_details?.tax_registration_name,
        tax_registration_no: company_details?.tax_registration_no,
        tax_type: company_details?.tax_type,
        tax_category: company_details?.tax_category,
        cr_number: company_details?.cr_number,
        company_slogan: company_details?.company_slogan,
        website: company_details?.website,
        history: company_details?.history,
        since: company_details?.since,
        certifications: company_details?.certifications,
        shipping_name: company_details?.shipping_name,
        shipping_street: company_details?.shipping_street,
        shipping_appartment: company_details?.shipping_appartment,
        shipping_city: company_details?.shipping_city,
        shipping_state: company_details?.shipping_state,
        shipping_zip: company_details?.shipping_zip,
        shipping_phone: Number(company_details?.shipping_phone),
        financial_yr_from: dayjs(new Date()),
        financial_yr_to: dayjs(new Date()),
        setting_tz: company_details?.setting_tz,
        decimal_places: company_details?.decimal_places,
        company_banks: company_details?.company_banks,
        language_setting: company_details?.language_setting,
      })
  }, [company_details])
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const footer = (prev) => {
    console.log(prev, 'prev');
    return (
      <>
        <div className={prev === 'np' ? "d-flex justify-content-end" : "d-flex justify-content-between mt-2"}>
          <Button className={prev === 'np' ? "d-none" : "d-block"} onClick={() => {
            setActiveTab((Number(activeTab) - 1).toString())
          }}>Previous</Button>
          <Button className={prev === 'nn' ? "d-none" : "d-block"} onClick={() => {
            setActiveTab((Number(activeTab) + 1).toString())
          }}>{'Next'}</Button>

        </div>
      </>
    )
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileIcon, setFileIcon] = useState(null);
  console.log(selectedFile, 'selectedFile');
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      // document.getElementById('fileIcon').src = reader.result;
      setFileIcon(reader.result)
    };
    reader.readAsDataURL(file);
  };

  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    companyDetails[dest] = type !== '' ? Number(value) : value;
    setCompanyDetails({
      ...companyDetails,
    })
  }
  return (
    <>
      <div className="page-content">
        <Container fluid>

          <h4 className="page-title">Company Details</h4>
          <Card>
            <CardBody>

              <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "5"
                    })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Company Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "6"
                    })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                    About
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "7"
                    })}
                    onClick={() => {
                      toggleTab("7");
                    }}
                  >
                    Shipping Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "8"
                    })}
                    onClick={() => {
                      toggleTab("8");
                    }}
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="5">
                  <Card className="mt-1 p-1">
                    <CardBody className="m-0">
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" value={companyDetails?.name} id="companyname" name="companyname" onChange={(e) => handleInput(e, 'name', '')} required />
                            <label for="companyname">Company Name</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" id="name" value={companyDetails?.locale_name} name="name" onChange={(e) => handleInput(e, 'locale_name', '')} required />
                            <label for="name">Name (Locale)</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" id="shortname" name="shortname" value={companyDetails?.short_name} onChange={(e) => handleInput(e, 'short_name', '')} required />
                            <label for="shortname">Short Name</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" id="rsp" value={companyDetails?.responsible_person} onChange={(e) => handleInput(e, 'responsible_person', '')} name="rsp" required />
                            <label for="rsp">Responsible Person</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.phone1} onChange={(e) => handleInput(e, 'phone1', 'number')} type="text" id="phone" name="phone" required />
                            <label for="phone">Phone</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.phone2} onChange={(e) => handleInput(e, 'phone2', 'number')} type="number" id="phone1" name="phone1" required />
                            <label for="phone1">Phone1</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.email} onChange={(e) => handleInput(e, 'email', '')} type="text" id="email" name="email" required />
                            <label for="email">Email</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.fax} onChange={(e) => handleInput(e, 'fax', '')} type="text" id="fax" name="fax" required />
                            <label for="fax">Fax</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.tax_category} type="text" onChange={(e) => handleInput(e, 'tax_category', '')} id="taxcat" name="taxcat" required />
                            <label for="taxcat">tax Category</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.postal_code} onChange={(e) => handleInput(e, 'postal_code', 'number')} type="text" id="postal" name="postal" required />
                            <label for="postal">Postal Code</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.country} onChange={(e) => handleInput(e, 'country', '')} type="text" id="country" name="country" required />
                            <label for="country">Country</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.currency} onChange={(e) => handleInput(e, 'currency', '')} type="text" id="currency" name="currency" required />
                            <label for="currency">Currency</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.tax_registration_name} onChange={(e) => handleInput(e, 'tax_registration_name', '')} type="text" id="Taxregname" name="Taxregname" required />
                            <label for="Taxregname">Tax Registration Name</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.tax_registration_no} onChange={(e) => handleInput(e, 'tax_registration_no', 'number')} type="text" id="taxregno" name="taxregno" required />
                            <label for="taxregno">Tax Registration Number</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.tax_type} onChange={(e) => handleInput(e, 'tax_type', '')} type="text" id="taxtype" name="taxtype" required />
                            <label for="taxtype">Tax Type</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <textarea placeholder=" " value={companyDetails?.address} className="textarea" onChange={(e) => handleInput(e, 'address', '')}  name="address" rows={2}  id="net_amount" required />
                            <label for="address">Address</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.cr_number} onChange={(e) => handleInput(e, 'cr_number', 'number')} type="text" id="crnumber" name="crnumber" required />
                            <label for="crnumber">CR Number</label>
                          </div>
                        </Col>
                      </Row>

                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId="6" className="p-3">
                  <Card className="mt-1 p-1">
                    <CardBody className="m-0">
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" value={companyDetails.company_slogan} id="company_slogan" name="company_slogan" onChange={(e) => handleInput(e, 'company_slogan', '')} required />
                            <label for="company_slogan">Company Slogan</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails.website} type="text" id="website" name="website" onChange={(e) => handleInput(e, 'website', '')} required />
                            <label for="website">Website</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails.history} type="text" id="history" name="history" onChange={(e) => handleInput(e, 'history', '')} required />
                            <label for="history">History</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" value={companyDetails.since} id="since" name="since" onChange={(e) => handleInput(e, 'since', 'number')} required />
                            <label for="since">Since</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails.certifications} type="text" id="certifications" name="certifications" onChange={(e) => handleInput(e, 'certifications', '')} required />
                            <label for="certifications">Certifications</label>
                          </div>
                        </Col>

                      </Row>
                      <Row className="mt-4">
                        <Col md="4">
                          <b>Logo Image</b>
                          <div className="input-group">
                            <input placeholder=" "
                              type="file"
                              className="form-control"
                              id="customFile"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <b>Header Image</b>
                          <div className="input-group">
                            <input placeholder=" "
                              type="file"
                              className="form-control"
                              id="customFile"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <b>Footer Image</b>
                          <div className="input-group">
                            <input placeholder=" "
                              type="file"
                              className="form-control"
                              id="customFile"
                            />
                          </div>
                        </Col>
                      </Row>

                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId="7" className="p-3">

                  <Card className="mt-1 p-1">
                    <CardBody className="m-0">
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" id="shipping_name" value={companyDetails?.shipping_name} onChange={(e) => handleInput(e, 'shipping_name', '')} name="shipping_name" required />
                            <label for="shipping_name">Full Name</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.shipping_street} onChange={(e) => handleInput(e, 'shipping_street', '')} type="text" id="shipping_street" name="shipping_street" required />
                            <label for="shipping_street">Street</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " value={companyDetails?.shipping_appartment} onChange={(e) => handleInput(e, 'shipping_appartment', '')} type="text" id="shipping_appartment" name="shipping_appartment" required />
                            <label for="shipping_appartment">Appartment</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" id="shipping_city" value={companyDetails?.shipping_city} onChange={(e) => handleInput(e, 'shipping_city', '')} name="shipping_city" required />
                            <label for="shipping_city">City</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'shipping_state', '')} value={companyDetails?.shipping_state} type="text" id="shipping_state" name="shipping_state" required />
                            <label for="shipping_state">State</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'shipping_zip', '')} value={companyDetails?.shipping_zip} type="text" id="shipping_zip" name="shipping_zip" required />
                            <label for="shipping_zip">ZIP</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'shipping_phone', 'number')} value={companyDetails?.shippshipping_phoneing_zip} type="text" id="shipping_phone" name="shipping_phone" required />
                            <label for="shipping_phone">Phone</label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>

                <TabPane tabId="8" className="p-3">
                  <Card className="mt-1 p-1">
                    <CardBody className="m-0">
                      <h4>Financial Year</h4>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="date" defaultValue="2020-03-19" id="financial_yr_from" value={companyDetails?.financial_yr_from} onChange={(e) => handleInput(e, 'financial_yr_from', '')} name="financial_yr_from" required />
                            <label for="financial_yr_from">Date From</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'financial_yr_to', '')} value={companyDetails?.financial_yr_to} type="date" defaultValue="2020-03-19" id="financial_yr_to" name="financial_yr_to" required />
                            <label for="financial_yr_to">Date To</label>
                          </div>
                        </Col>
                      </Row>
                      <h4>Settings</h4>
                      <Row>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " type="text" id="setting_tz" onChange={(e) => handleInput(e, 'setting_tz', '')} value={companyDetails?.setting_tz} name="setting_tz" required />
                            <label for="setting_tz">Time Zone</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'decimal_places', 'number')} value={companyDetails?.decimal_places} type="text" id="decimal_places" name="decimal_places" required />
                            <label for="decimal_places">Decimal Places</label>
                          </div>
                        </Col>
                        <Col md='4'>
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'company_banks', '')} value={companyDetails?.company_banks} type="text" id="company_banks" name="company_banks" required />
                            <label for="company_banks">Company Banks</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'bank_code', '')} value={companyDetails?.bank_code} type="text" id="bank_code" name="bank_code" required />
                            <label for="bank_code">Bank Code</label>
                          </div>
                        </Col>
                        <Col md="4">  
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'account_name', '')} value={companyDetails?.account_name} type="text" id="account_name" name="account_name" required />
                            <label for="account_name">Account Name</label>
                          </div>
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'bank_address', '')} value={companyDetails?.bank_address} type="text" id="bank_address" name="bank_address" required />
                            <label for="bank_address">Bank Address</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'account_no', '')} value={companyDetails?.account_no} type="text" id="account_no" name="account_no" required />
                            <label for="account_no">Account Number</label>
                          </div>
                        </Col>
                        <Col md="4">  
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'swift_code', '')} value={companyDetails?.swift_code} type="text" id="swift_code" name="swift_code" required />
                            <label for="swift_code">Swift Code</label>
                          </div>
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'iban', '')} value={companyDetails?.iban} type="text" id="iban" name="iban" required />
                            <label for="iban">IBAN</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'gl_account', '')} value={companyDetails?.gl_account} type="text" id="gl_account" name="gl_account" required />
                            <label for="gl_account">GL Account</label>
                          </div>
                        </Col>
                        <Col md="4">
                          <div class="input-container">
                            <input placeholder=" " onChange={(e) => handleInput(e, 'language_setting', '')} value={companyDetails?.language_setting} type="text" id="language_setting" name="language_setting" required />
                            <label for="language_setting">GL Account</label>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
          <div className="text-end">
            {
              activeTab === '8' ? <Button color='primary' onClick={() => {
                dispatch(postCompanyDetails({ companyDetails }))
              }}>
                Save
              </Button> : <></>
            }

          </div>
        </Container>
      </div>
    </>
  );
}
export default Check;
