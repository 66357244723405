import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
  customerData,
  UpdateCustomer,
  DeleteCustomer,
  customersSelector,
  clearData,
  clearCustUpdateLoading,
  clearCustLoadingDatas,
  clearCustDeleteLoading,
  clearErrormsg,
} from "../../store/reducer/CustomerReducer";

const SalesQuotation = () => {
  const dispatch = useDispatch();
  let [sortBy, setSortBy] = useState("customer_name");
  const [info, setInfo] = useState({ 0: false });
  let [activesortBy, setActiveSortBy] = useState("customer_name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  let [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({ modal_fullscreen: false });
  const [accord, setAccord] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customerValidationSchema),
  });
  const {
    customerDatas,
    customerCount,
    loading,
    addloading,
    deleteloading,
    updateloading,
    errorMessage,
  } = useSelector(customersSelector);
  const navigate = useNavigate();
  const [quotationDetails, setQuotationDetails] = useState([
    {
      id: 1,
      quotation_no: 1211,
      customer_name: "Quinton",
      quotation_date: "01-11-2022",
      rfq_ref_date: "01-11-2022",
      bill_amount: 1645.0,
      rfq_doc_no: 1822,
      notes: "",
      status: 1,
    },
  ]);

  let totBillAmt = 0;
  for (let index = 0; index < quotationDetails.length; index++) {
    const element = quotationDetails[index];
    totBillAmt += element.bill_amount;
  }

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };

  // Demo purpose
  useEffect(() => {
    quotationDetails.push(
      {
        id: 2,
        quotation_no: 1713,
        customer_name: "Denver",
        quotation_date: "01-11-2022",
        rfq_ref_date: "01-11-2022",
        bill_amount: 19985.0,
        rfq_doc_no: 4222,
        notes: "",
        status: 2,
      },
      {
        id: 1,
        quotation_no: 2011,
        customer_name: "laura",
        quotation_date: "01-07-2023",
        rfq_ref_date: "01-11-2023",
        bill_amount: 109.0,
        rfq_doc_no: 6877,
        notes: "",
        status: 1,
      }
    );
    setQuotationDetails([...quotationDetails]);
  }, []);

  const tog_fullscreen = () => {
    navigate("/sales/new");
  };
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const handleInput = (e, dest, type) => {
    const { value } = e.target;
    console.log(value);
    quotationDetails[dest] = type !== "" ? Number(value) : value;
    setQuotationDetails({
      ...quotationDetails,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const searchData = (e) => {
    searchQuery = e.target.value;
    setSearchQuery(e.target.value);
    setPage(0);
  };

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };
  useEffect(() => {
    dispatch(
      customerData({
        query: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sortby: sortBy,
        order: order,
      })
    );
  }, []);

  useEffect(() => {
    console.log(customerDatas, "customerDatas");
  }, [customerDatas]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Position",
        accessor: "position",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Office",
        accessor: "office",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Age",
        accessor: "age",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Start Date",
        accessor: "startdate",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Salary",
        accessor: "salary",
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );
  const t_col1 = () => {
    setAccord({ col1: !accord.col1, col2: false, col3: false });
  };
  const t_col2 = () => {
    setAccord({ col1: false, col2: !accord.col2, col3: false });
  };

  const onSubmitHandler = (formData) => {
    console.log({ formData });
    //dispatch(signInScheduler({ formData }));
    // reset();
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const breadcrumbItems = [
    { title: "Tables", link: "/" },
    { title: "Data Tables", link: "#" },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <h2>Sales Quotation</h2>
            <button
              type="button"
              className="waves-effect waves-light btn btn-primary mb-2"
              onClick={tog_fullscreen}
            >
              Add
            </button>
          </div>
          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Number of Quotation Today
                      </p>
                      <h4 className="mb-0">1076</h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                <CardBody className="border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11 me-1">
                      <i className="mdi mdi-menu-up"> </i> 2
                    </span>
                    <span className="text-muted ms-2">Increase</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Quotation total Today
                      </p>
                      <h4 className="mb-0">107634</h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                <CardBody className="border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-danger font-size-11 me-1">
                      <i className="mdi mdi-menu-down"> </i> 2
                    </span>
                    <span className="text-muted ms-2">Decrease</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Number of Quotation This Month
                      </p>
                      <h4 className="mb-0">12276</h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-stack-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                <CardBody className="border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11 me-1">
                      <i className="mdi mdi-menu-up"> </i> 2
                    </span>
                    <span className="text-muted ms-2">Increase</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Quotation Total This Month
                      </p>
                      <h4 className="mb-0">1076324</h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>

                <CardBody className="border-top py-3">
                  <div className="text-truncate">
                    <span className="badge badge-soft-success font-size-11 me-1">
                      <i className="mdi mdi-menu-up"> </i> 2
                    </span>
                    <span className="text-muted ms-2">Increase</span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-8">
                  <h5>
                    Total <span>{totBillAmt.toFixed(2)}</span>
                  </h5>
                </div>
                <div className="col-md-4 row">
                  <input
                    type="text"
                    name="search"
                    className="form-control"
                    style={{ float: "right" }}
                    value={searchQuery}
                    onChange={(e) => {
                      searchData(e);
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Quotation No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "customer_name" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("customer_name");
                            }}
                          >
                            Customer
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "quotation_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("quotation_no");
                            }}
                          >
                            Quotation Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "rfq_ref_date" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("rfq_ref_date");
                            }}
                          >
                            RFQ Ref Date
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "bill_amount" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("bill_amount");
                            }}
                          >
                            Bill Amount
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "rfq_doc_no" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("rfq_doc_no");
                            }}
                          >
                            RFQ Doc No
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "notes" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("notes");
                            }}
                          >
                            Notes
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">
                          <TableSortLabel
                            active={sortBy == "status" ? true : false}
                            direction={order}
                            className="table-header-column-name"
                            onClick={(e) => {
                              sortByKey("status");
                            }}
                          >
                            Status
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {quotationDetails &&
                        quotationDetails.map((row, index) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.quotation_no}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.customer_name}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.quotation_date}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.rfq_ref_date}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.bill_amount}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.rfq_doc_no}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.notes}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row.status === 1 ? "Active" : "In-active"}
                            </StyledTableCell>
                            <StyledTableCell
                              align=""
                              className="button-items d-flex"
                            >
                              <a
                                href="javascript:void(0);"
                                title="Edit"
                                className="btn btn-primary  "
                              >
                                <i className=" ri-ball-pen-line"></i>
                              </a>
                              <a
                                href="javascript:void(0);"
                                title="Delete"
                                className="btn btn-danger"
                              >
                                <i className="ri-delete-bin-line"></i>
                              </a>
                              <a
                                href="javascript:void(0);"
                                title="Delete"
                                className="btn btn-secondary"
                              >
                                <i class="ri-send-plane-fill"></i>
                              </a>
                              <Dropdown
                                isOpen={info[index]}
                                direction="left"
                                toggle={() => {
                                  info[index] = !info[index];
                                  setInfo({ ...info });
                                }}
                              >
                                <DropdownToggle color="light" caret>
                                  <i class="ri-more-2-line" style={{fontSize:'18px'}}></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={()=> console.log(row,'row')}>Print</DropdownItem>
                                  <DropdownItem>Set to Proforma</DropdownItem>
                                  <DropdownItem>
                                    Set to Draft
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={9}
                          count={quotationDetails.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default SalesQuotation;
