import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
  } from "reactstrap";
  import classnames from "classnames";
  import "../../App.css";
  import { useEffect, useState } from "react";
  import Select from "react-dropdown-select";
  import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
  import { useDispatch, useSelector } from "react-redux";
  import { useForm, Controller } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import dayjs from "dayjs";
  import TableRow from "@mui/material/TableRow";
  import TablePaginationActions from "../../utils/pagination";
  import Paper from "@mui/material/Paper";
  import TableFooter from "@mui/material/TableFooter";
  import TablePagination from "@mui/material/TablePagination";
  import TableSortLabel from "@mui/material/TableSortLabel";
  import { salesQuotationValidation } from "../../utils/Validation";
  import { useNavigate } from "react-router";
  import {
    customerData,
    customersSelector,
  } from "../../store/reducer/CustomerReducer";
  import {
    productData,
    productsSelector,
  } from "../../store/reducer/ProductReducer";
  const PurchaseInvoice = () => {
    const [activeTab, setActiveTab] = useState("5");
    const dispatch = useDispatch();
    const {
      customerDatas,
      customerCount,
      loading,
      addloading,
      deleteloading,
      updateloading,
      errorMessage,
    } = useSelector(customersSelector);
    const { productDatas } = useSelector(productsSelector);
    console.log(productDatas, "data");
    const [companyDetails, setCompanyDetails] = useState({
      id: 1,
      customer_name: "",
      contact_name: "",
      quotation_date: "",
      rfq_date: "",
      rfq_doc_no: "",
      requested_by: "",
      valid_upto: "",
      products: [
        {
          product_desc: "",
          quantity: 0,
          unit: "",
          unit_price: 0,
          amount: 0,
        },
      ],
      customer_notes: "",
      total_amt: 0,
      discount: "",
      vat: "",
      vat_amt: 0,
      round_off: "",
      net_amount: 0,
    });
    console.log(companyDetails, "companyDetails");
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      reset,
    } = useForm({
      resolver: yupResolver(salesQuotationValidation),
    });
    const navigate = useNavigate();
    let [sortBy, setSortBy] = useState("customer_name");
    let [activesortBy, setActiveSortBy] = useState("customer_name");
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [billAmount, setBillAmount] = useState("0.00");
    const handleChangePage = (event, newPage) => {
      page = newPage;
      setPage(newPage);
    };
  
    const handleSave = () => {
      navigate("/sales");
    };
  
    const handleChangeRowsPerPage = (event) => {
      page = 0;
      rowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const sortByKey = (key) => {
      sortBy = key;
      if (activesortBy == key) {
        if (order == "desc") {
          order = "asc";
        } else {
          order = "desc";
        }
        setOrder(order);
      } else {
        order = "desc";
        activesortBy = key;
        setActiveSortBy(key);
        setOrder(order);
      }
      setSortBy(key);
    };
  
    useEffect(() => {
      dispatch(productData({}));
      dispatch(customerData({}));
    }, []);
  
    useEffect(() => {
      const index = companyDetails.products.length - 1;
      if (companyDetails.products[index]?.product_desc !== "") {
        companyDetails.products.push({
          product_desc: "",
          quantity: "",
          unit: "",
          unit_price: "",
          amount: "",
        });
        setCompanyDetails({
          ...companyDetails,
        });
      }
    }, [companyDetails]);
    let result = 0;
    for (let index = 0; index < companyDetails.products.length; index++) {
      const element = companyDetails.products[index];
      if (typeof element.amount == "number") result += element.amount;
    }
    useEffect(() => {
      setCompanyDetails({
        ...companyDetails,
        total_amt: result,
        round_off: Math.round(result),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);
  
    const toggleTab = (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    };
    const footer = (prev) => {
      console.log(prev, "prev");
      return (
        <>
          <div
            className={
              prev === "np"
                ? "d-flex justify-content-end"
                : "d-flex justify-content-between mt-2"
            }
          >
            <Button
              className={prev === "np" ? "d-none" : "d-block"}
              onClick={() => {
                setActiveTab((Number(activeTab) - 1).toString());
              }}
            >
              Previous
            </Button>
            {prev === "nn" ? (
              <Button onClick={() => {}}>Save</Button>
            ) : (
              <Button
                onClick={() => {
                  setActiveTab((Number(activeTab) + 1).toString());
                }}
              >
                {prev === "nn" ? "Save" : "Next"}
              </Button>
            )}
          </div>
        </>
      );
    };
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileIcon, setFileIcon] = useState(null);
    console.log(selectedFile, "selectedFile");
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        // document.getElementById('fileIcon').src = reader.result;
        setFileIcon(reader.result);
      };
      reader.readAsDataURL(file);
    };
  
    const handleInput = (e, dest, type) => {
      const { value } = e.target;
      companyDetails[dest] = type !== "" ? Number(value) : value;
      setCompanyDetails({
        ...companyDetails,
      });
    };
  
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <div className="d-flex justify-content-between">
              <h4 className="page-title">Purchase Invoice</h4>
              <h5>
                Bill Amount: <span>{billAmount}</span>
              </h5>
            </div>
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <Select
                          placeholder="Vendor"
                          className="js-states select-control"
                          options={[]}
                          disabled={false}
                          dropdownHandle={true}
                          searchable={true}
                          labelField={"customer_name"}
                          valueField={"id"}
                          name="country_name"
                          // onChange={(value) => {
                          //     console.log(value, 'products');
                          //     companyDetails.products[index].product_desc = value[0].name
                          //     setCompanyDetails({
                          //         ...companyDetails
                          //     })
                          // }}
                        />
                        {/* <input placeholder=" " type="text" id="customer_name" name="customer_name" onChange={(e) => handleInput(e, 'customer_name', '')} required />
                                              <label for="customer_name">Customer</label> */}
                      </div>
                      <i className=" ri-add-circle-line mb-3"></i>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="date"
                        defaultValue="2020-03-19"
                        id="quotation_date"
                        onChange={(e) => handleInput(e, "quotation_date", "")}
                        name="quotation_date"
                      />
                      <label for="quotation_date">Purchase Date</label>
                    </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div class="input-container">
                    <input
                        placeholder=" "
                        type="text"
                        onChange={(e) => handleInput(e, "rfq_date", "")}
                        name="rfq_date"
                      />
                      <label className="required"  for="quotation_date">Pay Mode</label>
                    </div>
                  </Col>
                  <Col md="4">
                    <div class="input-container">
                      <input
                        placeholder=" "
                        type="date"
                        className="required"
                        defaultValue="2020-03-19"
                        id="rfq_date"
                        onChange={(e) => handleInput(e, "rfq_date", "")}
                        name="rfq_date"
                      />
                      <label for="rfq_date">RFQ Date</label>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <input
                          placeholder=" "
                          type="text"
                          id="rfq_doc_no"
                          name="rfq_doc_no"
                          onChange={(e) => handleInput(e, "short_name", "")}
                          required
                        />
                        <label for="rfq_doc_no">RFQ DOC No</label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <input
                          placeholder=" "
                          type="text"
                          id="requested_by"
                          name="requested_by"
                          onChange={(e) => handleInput(e, "short_name", "")}
                          required
                        />
                        <label for="requested_by">Requested By</label>
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="d-flex align-items-end">
                      <div class="input-container w-100">
                        <input
                          placeholder=" "
                          type="text"
                          id="valid_upto"
                          name="valid_upto"
                          onChange={(e) => handleInput(e, "short_name", "")}
                          required
                        />
                        <label for="valid_upto">Valid Upto</label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
  
            <Card>
              <CardBody>
                <div className="table-responsive react-table">
                  <TableContainer
                    component={Paper}
                    style={{ minHeight: "300px" }}
                  >
                    <Table sx={{ minWidth: 700 }} stickyHeader>
                      <TableHead className="table-light table-nowrap">
                        <TableRow>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "product_desc" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("product_desc");
                              }}
                            >
                              Product Description
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "qty" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("qty");
                              }}
                            >
                              Qty
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "unit" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("unit");
                              }}
                            >
                              Unit
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "unit_price" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("unit_price");
                              }}
                            >
                              Unit Price
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">
                            <TableSortLabel
                              active={sortBy == "amount" ? true : false}
                              direction={order}
                              className="table-header-column-name"
                              onClick={(e) => {
                                sortByKey("amount");
                              }}
                            >
                              Amount
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell align="">Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {companyDetails.products &&
                          companyDetails.products.map((row, index) => (
                            <StyledTableRow>
                              <StyledTableCell align="">
                                <Controller
                                  name="country"
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      className="js-states select-control"
                                      options={productDatas ? productDatas : []}
                                      {...field}
                                      disabled={false}
                                      dropdownHandle={true}
                                      searchable={true}
                                      labelField={"description"}
                                      valueField={"id"}
                                      name="country_name"
                                      onChange={(value) => {
                                        console.log(value, "products");
                                        companyDetails.products[
                                          index
                                        ].product_desc = value[0].name;
                                        setCompanyDetails({
                                          ...companyDetails,
                                        });
                                      }}
                                    />
                                  )}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id={`quantity${index}`}
                                    name={`quantity${index}`}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      companyDetails.products[index].quantity =
                                        value;
                                      let result =
                                        companyDetails.products[index].quantity *
                                        companyDetails.products[index].unit_price;
                                      companyDetails.products[index].amount =
                                        result;
                                      setCompanyDetails({
                                        ...companyDetails,
                                      });
                                    }}
                                  />
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id={`unit${index}`}
                                    name={`unit${index}`}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      companyDetails.products[index].unit = value;
                                      setCompanyDetails({
                                        ...companyDetails,
                                      });
                                    }}
                                  />
                                  {/* <label for={`${index}`}></label> */}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    placeholder=" "
                                    type="text"
                                    id={`unit_price${index}`}
                                    name={`unit_price${index}`}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      companyDetails.products[index].unit_price =
                                        value;
                                      let result =
                                        companyDetails.products[index].quantity *
                                        companyDetails.products[index].unit_price;
                                      companyDetails.products[index].amount =
                                        result;
                                      setCompanyDetails({
                                        ...companyDetails,
                                      });
                                    }}
                                  />
                                  {/* <label for={`${index}`}></label> */}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="">
                                <div class="input-container w-100 m-1">
                                  <input
                                    value={companyDetails.products[index].amount}
                                    placeholder=" "
                                    disabled
                                    type="text"
                                    id={`amount${index}`}
                                    name={`amount${index}`}
                                    onChange={(e) => {
                                      console.log("object");
                                    }}
                                  />
                                  {/* <label for={`${index}`}></label> */}
                                </div>
                              </StyledTableCell>
                              {/* <StyledTableCell align="">
                                                              {dayjs(row.created_at).format(
                                                                  "MM-DD-YYYY hh:mm A"
                                                              )}
                                                          </StyledTableCell> */}
                              <StyledTableCell align="" className="button-items">
                                <a
                                  href="javascript:void(0);"
                                  title="Delete"
                                  className="btn btn-danger delete-btn"
                                >
                                  <i className="ri-delete-bin-line"></i>
                                </a>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="invoice-width">
                    <div class="input-container w-100">
                      <textarea
                        id="net_amount"
                        rows={3}
                        name="net_amount"
                        onChange={(e) => handleInput(e, "short_name", "")}
                        required
                      />
                      <label for="net_amount">Customer Notes</label>
                    </div>
                  </div>
                  <div className="invoice-width">
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="total_amount"
                        name="total_amount"
                        value={companyDetails.total_amt}
                        disabled
                        required
                      />
                      <label for="total_amount">Total Amount</label>
                    </div>
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="discount"
                        name="discount"
                        disabled
                      />
                      <label for="discount">Discount</label>
                    </div>
                    <div className="input-container w-100">
                      <Select
                        className="js-states select-control"
                        options={[{ id: 15, name: "VAT 15%" }]}
                        placeholder="VAT"
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        labelField={"name"}
                        valueField={"id"}
                        name="country_name"
                        onChange={(value) => {
                          let ans =
                            (companyDetails.total_amt / value[0].id) * 100 +
                            companyDetails.total_amt;
                          companyDetails.vat = value[0].id;
                          companyDetails.vat_amt =
                            (companyDetails.total_amt / value[0].id) * 100;
                          companyDetails.net_amount = ans.toFixed(2);
                          setCompanyDetails({
                            ...companyDetails,
                          });
                          setBillAmount(ans.toFixed(2));
                        }}
                      />
                    </div>
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="vat_amount"
                        name="vat_amount"
                        disabled
                        value={companyDetails.vat_amt}
                      />
                      <label for="vat_amount">VAT Amount</label>
                    </div>
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="round_off"
                        name="round_off"
                        disabled
                        value={companyDetails.round_off}
                      />
                      <label for="round_off">Round Off</label>
                    </div>
                    <div class="input-container w-100">
                      <input
                        placeholder=" "
                        type="text"
                        id="net_amount"
                        name="net_amount"
                        disabled
                        value={companyDetails.net_amount}
                      />
                      <label for="net_amount">Net Amount</label>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="text-end">
              <Button
                color="primary"
                className=" waves-effect waves-light me-1"
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </Container>
        </div>
      </>
    );
  };
  export default PurchaseInvoice;
  