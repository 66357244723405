import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from './Common/Breadcrumb';
//Import Chart
import PurchasePieChart from './Dashboard/PurchasePieChart'
import InventoryPieChart from './Dashboard/InventoryPieChart'

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import ReactApexChart from 'react-apexcharts';
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import dayjs from "dayjs";

import {
    productData,
    dashboardinvoiceData,
    dashboardChartData,
    dashboardSelector,
    clearProdLoadingDatas,
} from "../store/reducer/DashboardReducer";

import { API_STATUS } from "../utils/constants";
import SalesPieChart from "./Dashboard/SalesPieChart";

function Dashboard() {
    const dispatch = useDispatch();
    const { cardDatas, dashboardinvoiceDatas, dashboardChartDatas } = useSelector(dashboardSelector);

    let [sortBy, setSortBy] = useState("invoice_no");
    const dashboardDispatch = () => {
        dispatch(
            dashboardinvoiceData({
                query: 2,
                page: page + 1,
                limit: 5,
                sortby: "created_at",
                order: order,
            })
        );
        dispatch(
            productData({})
        );
        dispatch(
            dashboardChartData({})
        )
    }
    let [activesortBy, setActiveSortBy] = useState("invoice_no");
    let [page, setPage] = useState(0);
    // let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("desc");
    let [searchQuery, setSearchQuery] = useState("");
    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        // dashboardDispatch();
    };
    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
    };
    useEffect(() => {
        dashboardDispatch();
    }, []);
    useEffect(() => {
        console.log(dashboardinvoiceDatas, "dashboardinvoiceDatas");
    }, [dashboardinvoiceDatas]);

    useEffect(() => {
        console.log(cardDatas, "cardDatas");
    }, [cardDatas]);


    return (
        <>
            < div className="page-content" >
                <Container fluid>
                    <Row>
                        {/* Sales */}
                        <Row>
                            {/* <div className="">
                                <h2>Sales</h2>
                            </div> */}
                            <Col md="3">
                                <Card color="primary">
                                    <CardBody>
                                        <div className="d-flex text-light">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2 ">Revenue This Month</p>
                                                <h4 className="mb-0 text-light">{cardDatas?.invoice_this_month}</h4>
                                            </div>
                                            <div className="text-light">
                                                <i className="ri-bar-chart-fill font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="success">
                                    <CardBody>
                                        <div className="d-flex text-light">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Received Revenue This Month</p>
                                                <h4 className="mb-0 text-light">{cardDatas?.received_rev?.length}</h4>
                                            </div>
                                            <div className="text-light">
                                                <i className="ri-line-chart-fill font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="warning">
                                    <CardBody>
                                        <div className="d-flex text-light">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Drafted Sales Invoices</p>
                                                <h4 className="mb-0 text-light">{cardDatas?.no_of_drafted}</h4>
                                            </div>
                                            <div className="text-primary text-light">
                                                <i className="dripicons-archive font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="light">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Active Products</p>
                                                <h4 className="mb-0">{cardDatas?.product_count}</h4>
                                            </div>
                                            <div className="">
                                                <i className="ri-gift-fill font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* Purchase */}
                        <Row style={{ display: "none" }}>
                            <div className="">
                                <h2>Purchase</h2>
                            </div>
                            <Col md="3">
                                <Card color="primary">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Purchases Order This Month</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="">
                                                <i className="fas fa-door-open font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="success">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Purchases Booked This Month</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="text-primary">
                                                <i className="ri-book-read-fill font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="warning">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Expected Delivery This Month</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="text-primary">
                                                <i className="fas fa-truck-moving font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="3">
                                <Card color="">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Delayed Orders</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="text-primary">
                                                <i className="fas fa-dolly-flatbed font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* Inventory */}
                        <Row style={{ display: "none" }}>
                            <div className="">
                                <h2>Inventory</h2>
                            </div>
                            <Col md="3">
                                <Card color="primary">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Active Products</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="">
                                                <i className="ri-gift-fill font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="success">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Minimum Level</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="text-primary">
                                                <i className="fab fa-buffer font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="warning">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Reorder Level</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="">
                                                <i className="fas fa-recycle font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card color="">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-1 overflow-hidden">
                                                <p className="text-truncate font-size-14 mb-2">Zero stock</p>
                                                <h4 className="mb-0">0</h4>
                                            </div>
                                            <div className="text-primary">
                                                <i className="fab fa-creative-commons-zero font-size-24"></i>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <Row>

                                    <Col md="4">
                                        <div className="">
                                            <h2>TOP 5 CUSTOMERS</h2>
                                        </div>
                                        <SalesPieChart props={dashboardChartDatas ? dashboardChartDatas : []} />
                                    </Col>
                                    <Col>
                                        <div className="">
                                            <h2>LATEST 5 SALES INVOICE LIST</h2>
                                        </div>
                                        <div className="table-responsive react-table">
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                                    <TableHead className="table-light table-nowrap">
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "invoice_no" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("invoice_no");
                                                                    }}
                                                                >
                                                                    Invoice No
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "customer" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("customer");
                                                                    }}
                                                                >
                                                                    Customer
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "invoice_date" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("invoice_date");
                                                                    }}
                                                                >
                                                                    Invoice Date
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "invoice_amount" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("invoice_amount");
                                                                    }}
                                                                >
                                                                    Invoice Amount
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "status" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("status");
                                                                    }}
                                                                >
                                                                    Status
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {dashboardinvoiceDatas &&
                                                            dashboardinvoiceDatas.map((row) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="">
                                                                        {row?.invoice_no}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row?.customer?.customer_name}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {dayjs(row?.invoice_date).format("DD-MM-YYYY")}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row?.total}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.status === 0 ? "Draft" : row.status === 1 ? "Proforma" : "Invoice"}
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </Col>
                                    <Col md="4" style={{ display: "None" }}>
                                        <div className="">
                                            <h2>Purchase</h2>
                                        </div>
                                        <PurchasePieChart />
                                    </Col>
                                    <Col md="4" style={{ display: "None" }}>
                                        <div className="">
                                            <h2>Inventory</h2>
                                        </div>
                                        <InventoryPieChart />
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div >
        </>
    );
}
export default Dashboard;
