import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";

import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    Collapse,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import {
    customersSelector,
} from "../../store/reducer/CustomerReducer";

const AddStock = () => {
    const [stock, setStock] = useState({
        product: '',
        quantity: 0,
        location: ""
    })
    const navigate = useNavigate()
    console.log(stock, 'products');
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(customerValidationSchema),
    });
    const {
        customerDatas
    } = useSelector(customersSelector);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div>
                        <h2>Stock</h2>
                    </div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md='6'>
                                    <div class="input-container">
                                        <input placeholder=" " type="text" id="product" onChange={(e) => setStock({ ...stock, product: e.target.value })} name="product" />
                                        <label for="product">Product</label>
                                    </div>
                                </Col>
                                <Col md='6'>
                                    <div class="input-container">
                                        <input placeholder=" " type="text" id="quantity" onChange={(e) => setStock({ ...stock, quantity: e.target.value })} name="quantity" />
                                        <label for="quantity">Quantity</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <div class="input-container">
                                        <Select
                                            placeholder="Location"
                                            className="js-states select-control"
                                            options={[]}
                                            disabled={false}
                                            dropdownHandle={true}
                                            searchable={true}
                                            labelField={"customer_name"}
                                            valueField={"id"}
                                            name="country_name"
                                            onChange={(value) => {
                                                setStock({
                                                    ...stock,
                                                    location: value.name
                                                })
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                    <div className="text-end">
                        <Button color='primary' onClick={() => navigate(-1)}>
                            Save
                        </Button>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default AddStock;
