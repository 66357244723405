import React, { useEffect, useState } from "react";
import "../../App.css";
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Table,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "../../App.css";
import { paymentValidator } from "../../utils/Validation";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API_STATUS, paymentType } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-dropdown-select";
import dayjs from "dayjs";
import { companySelector, getCompanyDetails, postCompanyDetails } from "../../store/reducer/CompanyReducer";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { Paper, TableBody, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import {
    customerData,
    customersSelector,
} from "../../store/reducer/CustomerReducer";
import {
    clearSinvLoadingDatas,
    createPaymentDetails,
    getPaymentList,
    salesinvoiceSelector
} from "../../store/reducer/SalesInvoiceReducer";
import { getInvoiceDetails } from "../../store/reducer/SalesInvoiceReducer";
function AddPayment() {
    const _ = require("lodash");
    let [sortBy, setSortBy] = useState("created_at");
    let [invoiceTotal, setInvoiceTotal] = useState(0);
    let [activesortBy, setActiveSortBy] = useState("customer_name");
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [settingTz, setSettingTz] = useState([])
    const [from_date, setFromDate] = useState({ from: null, to: null })
    const [Currency, setCurrency] = useState([])
    console.log(settingTz, 'settingTz');

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(paymentValidator),
    });
    const {
        customerDatas,
    } = useSelector(customersSelector);
    const {
        invoiceDetails,
        createPaymentDetailsloading
    } = useSelector(salesinvoiceSelector);
    let company_details = [{ payment_type: '1' }]
    //     { company_details }
    //         = useSelector(companySelector);
    console.log(invoiceDetails, 'invoiceDetails');
    const [activeTab, setActiveTab] = useState("5");
    const [total, setTotal] = useState(0);
    const [isWarning, setIsWarning] = useState(false);
    const [ids, setIds] = useState([]);
    console.log(ids, 'selected Ids');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const settings = [
        { id: '1', name: "Saudi Arabia" },
        { id: '2', name: "Qatar" },
    ]
    const currencies = [
        { id: '1', name: "SAR" },
        { id: '2', name: "RS" },
    ]

    useEffect(() => {
        dispatch(getCompanyDetails())
        dispatch(customerData({}));
    }, [])


    const onSubmitHandler = (formData) => {
        console.log(formData, 'formData');
        console.log(settingTz, 'settingTz');
        const unwantedPayload = ['created_at', 'updated_at', 'user_id', 'status', 'id']
        let keys = Object.keys(formData);
        keys.forEach((ele) => {
            if (formData[ele] === "" || formData[ele] === null) {
                delete formData[ele]
            }
        })
        formData.customer_id = Number(settingTz[0].id)
        formData.invoice_bal = (parseFloat(invoiceTotal))?.toString()
        formData.payment_type = Number(formData.payment_type[0].id)
        formData.payment_date = dayjs()
        formData.invoice_ids = ids
        console.log(formData, 'formData after');
        unwantedPayload.forEach((ele) => {
            delete formData[ele]
        })
        dispatch(createPaymentDetails({ formData }))
        // reset();
    };
    const SelectedSetting = (dest, countrId) => {
        console.log(countrId, 'countryId');
        return _.filter(dest, ['id', countrId]);
    }

    useEffect(() => {
        let sum = 0;
        let selected_datas = invoiceDetails?.filter((items) => ids?.some((isData) => isData == items.id))
        selected_datas?.forEach(num => {
            if (num.payment_balance != 0) {
                sum += parseFloat(num.payment_balance)
            } else {
                sum += parseFloat(num.total);
            }
        })
        setTotal(sum)
    }, [ids])

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
    };


    useEffect(() => {
        if (createPaymentDetailsloading === API_STATUS.FULFILLED) {
            toastr.clear();
            navigate("/sales/payment");
            dispatch(clearSinvLoadingDatas());
            toastr.success('Done')
            reset();
        }
    }, [createPaymentDetailsloading]);


    const handleChange = event => {
        setSettingTz(event);
        console.log(event[0].id, 'on change');
        dispatch(
            getInvoiceDetails({
                query: event[0].id,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };
    const handleCurrency = event => {
        setCurrency(event);
    };

    const handleAmountValidation = event => {
        const { value } = event.target;


        if (parseFloat(value) > total) {
            toastr.warning('Amount Should be equal or lesser than total amount')
            setIsWarning(true)
        }else{
            setIsWarning(false)
        }
        console.log(total, invoiceDetails, 'TOTAL DATA');
    }
    useEffect(()=>{
        let sum = 0;
        invoiceDetails?.forEach(num => {
            if (num.payment_balance != 0) {
                sum += parseFloat(num.payment_balance)
            } else {
                sum += parseFloat(num.total);
            }
        })
        setInvoiceTotal(sum)
    },[invoiceDetails])


    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-6">
                            <h4>
                                <Breadcrumb>
                                    <BreadcrumbItem>
                                        Customer Payment / New
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </h4>
                        </div>
                    </div>
                    <Row>
                        <Col xs={12}>
                            <form onSubmit={handleSubmit(onSubmitHandler)}>

                                <Card>
                                    <CardBody>
                                        <div>
                                            <Row>

                                                <Col md="4">
                                                    <div className="input-container">
                                                        <Controller
                                                            name="customer_id"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder={"Customer"}
                                                                    options={customerDatas}
                                                                    {...field}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    // values={settingTz}
                                                                    searchable={true}
                                                                    labelField={"customer_name"}
                                                                    valueField={"id"}
                                                                    name="customer_id"
                                                                    onChange={handleChange}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    {errors?.customer_id?.message && (
                                                        <p className="error">
                                                            {errors.customer_id.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div className="input-container">
                                                        <Controller
                                                            name="payment_type"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder={"Payment Type"}
                                                                    options={paymentType}
                                                                    {...field}
                                                                    // defaultValue={company_details?.payment_type ? company_details?.payment_type : []}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    // values={settingTz}
                                                                    searchable={true}
                                                                    labelField={"mode"}
                                                                    valueField={"id"}
                                                                    name="payment_type"
                                                                // onChange={handleChange}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="input-container">
                                                        <input
                                                            placeholder=" "
                                                            type="date"
                                                            defaultValue={dayjs().format('YYYY-MM-DD')}
                                                            id="payment_date"
                                                            // disabled
                                                            name="payment_date"
                                                            {...register("payment_date")}
                                                        />
                                                        <label for="payment_date">
                                                            Payment Date
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* 
                                                <Col md="4">
                                                    <div className="input-container">
                                                        <Controller
                                                            name="customer_id"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder={"Sales Person"}
                                                                    options={settings}
                                                                    {...field}
                                                                    defaultValue={company_details?.sales_person ? company_details?.sales_person : []}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    values={settingTz}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="sales_person"
                                                                // onChange={handleChange}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </Col> */}
                                                <Col md="4">
                                                    <div className="input-container">
                                                        <input
                                                            placeholder=" "
                                                            type="number"
                                                            value={invoiceTotal}
                                                            disabled
                                                            id="invoice_bal"
                                                            name="invoice_bal"
                                                            {...register("invoice_bal")}
                                                        />
                                                        <label for="invoice_bal">
                                                            Invoice Balance
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="input-container">
                                                        <input
                                                            placeholder=" "
                                                            type="number"
                                                            defaultValue={company_details?.pay_amt}
                                                            id="pay_amt"
                                                            name="pay_amt"
                                                            {...register("pay_amt")}
                                                            onChange={handleAmountValidation}
                                                        />
                                                        <label for="pay_amt">
                                                            Pay Amount
                                                        </label>
                                                    </div>
                                                </Col>


                                                {/* <Col md="4">
                                                    <div className="input-container">
                                                        <Controller
                                                            name="pay_mode"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder={"Pay Mode"}
                                                                    options={settings}
                                                                    {...field}
                                                                    defaultValue={company_details?.pay_mode ? company_details?.pay_mode : []}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    values={settingTz}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="pay_mode"
                                                                // onChange={handleChange}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </Col> */}
                                                {/* <Col md="4">
                                                    <div className="input-container">
                                                        <input
                                                            placeholder=" "
                                                            type="number"
                                                            defaultValue={company_details?.balance_after_payment}
                                                            id="balance_after_payment"
                                                            name="balance_after_payment"
                                                            {...register("balance_after_payment")}
                                                        />
                                                        <label for="balance_after_payment">
                                                            Balance After Payment
                                                        </label>
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        </div>
                                        <div className="d-flex  justify-content-end">
                                            <div className="label label-cont">
                                                <label htmlFor="pay_all">Pay All</label>
                                                <div>
                                                    <input type="checkbox" disabled={!invoiceDetails?.length > 0} checked={ids != 0 ? ids.length === invoiceDetails?.length : false} name="pay_all" id="pay_all" onChange={(e) => {
                                                        let id = [];
                                                        if (e.target.checked) {
                                                            invoiceDetails?.forEach(element => {
                                                                id.push(element?.id)
                                                            });
                                                        }
                                                        setIds(id)
                                                    }} /></div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive react-table">
                                            <TableContainer
                                                component={Paper}
                                                style={{ minHeight: "300px" }}
                                            >
                                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                                    <TableHead className="table-light table-nowrap">
                                                        <TableRow>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "payment_type" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("payment_type");
                                                                    }}
                                                                >
                                                                    Type
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "invoice_no" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("invoice_no");
                                                                    }}
                                                                >
                                                                    Invoice No
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "date" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("date");
                                                                    }}
                                                                >
                                                                    Date
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell align="">
                                                            <TableSortLabel
                                                                active={sortBy == "customer_po" ? true : false}
                                                                direction={order}
                                                                className="table-header-column-name"
                                                                onClick={(e) => {
                                                                    sortByKey("customer_po");
                                                                }}
                                                            >
                                                                Customer PO
                                                            </TableSortLabel>
                                                        </StyledTableCell> */}
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "description" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("description");
                                                                    }}
                                                                >
                                                                    Description
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "project_name" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("project_name");
                                                                    }}
                                                                >
                                                                    Project Name
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "amount_due" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("amount_due");
                                                                    }}
                                                                >
                                                                    Amount Due
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "notes" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("notes");
                                                                    }}
                                                                >
                                                                    Notes
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "discount" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("discount");
                                                                    }}
                                                                >
                                                                    Discount
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                <TableSortLabel
                                                                    active={sortBy == "amount_received" ? true : false}
                                                                    direction={order}
                                                                    className="table-header-column-name"
                                                                    onClick={(e) => {
                                                                        sortByKey("amount_received");
                                                                    }}
                                                                >
                                                                    Amount Received
                                                                </TableSortLabel>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">Pay</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {invoiceDetails?.length > 0 &&
                                                            invoiceDetails?.map((row, index) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell align="">
                                                                        {row?.type}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row?.invoice_no}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.invoice_date && dayjs(row.invoice_date).format('YYYY-MM-DD')}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row?.desc}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row?.project_name}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {(row?.payment_balance != 0 && row?.payment_balance != null) ? row?.payment_balance : row?.total}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.notes}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.discount}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="">
                                                                        {row.amount_received}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="" className="button-items">
                                                                        <div>
                                                                            <label htmlFor={`pay${row?.id}`}></label>
                                                                            <div>
                                                                                <input type="checkbox" name={`pay${row?.id}`} id={`pay${row?.id}`} checked={ids.includes(row.id)} onChange={(e) => e.target.checked ? setIds(prev => [...prev, row.id]) : setIds(
                                                                                    ids?.filter((items) => items != row?.id)
                                                                                )} />
                                                                            </div>
                                                                        </div>
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                                {
                                                    total > 0 ? <>  <div className="text-end">
                                                        <h6><b>Selected Amount : {total}</b></h6>
                                                    </div></> : <></>
                                                }

                                            </TableContainer>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <div className="invoice-width">
                                            <div class="input-container w-100">
                                                <textarea
                                                    id="net_amount"
                                                    rows={3}
                                                    name="notes"
                                                />
                                                <label for="notes">Notes</label>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="text-end">
                                    <Button color="primary" disabled={isWarning}>
                                        Save
                                    </Button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default AddPayment;
