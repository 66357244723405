import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userList, userUpdate, userDelete, userAdd, userEdit, getCountry, getCurrency } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from '../../utils/alert';
const namespace = "users";

const initialState = {
  loading: "initial",
  addloading: "initial",
  editloading: "initial",
  updateloading: "initial",
  deleteloading: "initial",
  errorMessage: null,
  userDatas: null,
  userDataById: null,
  user_details: null,
  userCount: 0,
  countryListloading: "initial",
  countries: [],
  currencies: [],
  currencyListloading: "initial"
};

export const userData = createAsyncThunk(
  `${namespace}/userData`,
  async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
    try {
      console.log("interdata123");
      //let payload = EncryptDecrypt.encryptdata(formData, secretKey)
      const data = await userList(query, page, limit, sortby, order);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const EditUser = createAsyncThunk(
  `${namespace}/EditUser`,
  async ({ userID }, { rejectWithValue, dispatch }) => {
    try {
      // let payload = EncryptDecrypt.encryptdata(userID, secretKey);
      const data = await userEdit(userID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);
export const UpdateUser = createAsyncThunk(
  `${namespace}/UpdateUser`,
  async ({ formData, updateID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(updateID, "interdata");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey)
      const data = await userUpdate({ payload: payload }, updateID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const countryList = createAsyncThunk(
  `${namespace}/countryList`,
  async ({ query }, { rejectWithValue, dispatch }) => {
    try {
      const data = await getCountry(query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const currencyList = createAsyncThunk(
  `${namespace}/currencyList`,
  async ({ query }, { rejectWithValue, dispatch }) => {
    try {
      const data = await getCurrency(query);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const AddUser = createAsyncThunk(
  `${namespace}/AddUser`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "interdata");
      let payload = EncryptDecrypt.encryptdata(formData, secretKey);
      const data = await userAdd({ payload: payload });
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

export const DeleteUser = createAsyncThunk(
  `${namespace}/DeleteUser`,
  async ({ deleteID }, { rejectWithValue, dispatch }) => {
    try {
      console.log(deleteID, "interdata");
      const data = await userDelete(deleteID);
      console.log("getCount--> ", data);
      return data;
    } catch (error) {
      console.log("getCount error--->", error);
      return rejectWithValue(error.response);
    }
  }
);

const usersSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
    clearUserLoadingDatas: (state) => {
      state.loading = "initial";
      state.addloading = "initial";
      state.deleteloading = "initial";
      state.countryListloading = "initial";
      state.currencyListloading = "initial";
      state.countries = [];
      state.currencies = [];
    },
    clearUserEditLoading: (state) => {
      state.editloading = "initial";
    },
    clearUserUpdateLoading: (state) => {
      state.updateloading = "initial";
    },
    clearUserDeleteLoading: (state) => {
      state.deleteloading = "initial";
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userData.pending, (state) => {
      state.loading = API_STATUS.PENDING;
    });
    builder.addCase(userData.fulfilled, (state, { payload }) => {
      state.loading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey)
      );
      console.log(payloaddatas, "payloaddatas")
      state.userCount = payloaddatas?.count;
      state.userDatas = payloaddatas?.data;
    });
    builder.addCase(userData.rejected, (state, action) => {
      state.loading = API_STATUS.REJECTED;
      if (action?.payload?.data?.auth === "deleted") {
        alertmessage.sweetalert(action?.payload?.data?.message);
      }
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(AddUser.pending, (state) => {
      state.addloading = API_STATUS.PENDING;
    });
    builder.addCase(AddUser.fulfilled, (state, { payload }) => {
      state.addloading = API_STATUS.FULFILLED;
    });
    builder.addCase(AddUser.rejected, (state, action) => {
      state.addloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(EditUser.pending, (state) => {
      state.editloading = API_STATUS.PENDING;
    });
    builder.addCase(EditUser.fulfilled, (state, { payload }) => {
      state.editloading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data, secretKey)
      );
      state.user_details = payloaddatas?.data;
    });
    builder.addCase(EditUser.rejected, (state, action) => {
      state.editloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(UpdateUser.pending, (state) => {
      state.updateloading = API_STATUS.PENDING;
    });
    builder.addCase(UpdateUser.fulfilled, (state, { payload }) => {
      state.updateloading = API_STATUS.FULFILLED;
    });
    builder.addCase(UpdateUser.rejected, (state, action) => {
      state.updateloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(DeleteUser.pending, (state) => {
      state.deleteloading = API_STATUS.PENDING;
    });
    builder.addCase(DeleteUser.fulfilled, (state, { payload }) => {
      state.deleteloading = API_STATUS.FULFILLED;
    });
    builder.addCase(DeleteUser.rejected, (state, action) => {
      state.deleteloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(countryList.pending, (state) => {
      state.countryListloading = API_STATUS.PENDING;
    });
    builder.addCase(countryList.fulfilled, (state, { payload }) => {
      state.countryListloading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data, secretKey)
      );
      state.countries = payloaddatas?.data;
      console.log('country Payload', payloaddatas)
    });
    builder.addCase(countryList.rejected, (state, action) => {
      state.countryListloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(currencyList.pending, (state) => {
      state.currencyListloading = API_STATUS.PENDING;
    });
    builder.addCase(currencyList.fulfilled, (state, { payload }) => {
      state.currencyListloading = API_STATUS.FULFILLED;
      let payloaddatas = JSON.parse(
        EncryptDecrypt.decryptdata(payload?.data, secretKey)
      );
      state.currencies = payloaddatas?.data;
      console.log('country Payload', payloaddatas)
    });
    builder.addCase(currencyList.rejected, (state, action) => {
      state.currencyListloading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});


export const {
  clearData,
  clearUserUpdateLoading,
  clearUserLoadingDatas,
  clearUserDeleteLoading,
  clearErrormsg,
} = usersSlice.actions;

export const usersSelector = (state) => state.users;

export default usersSlice.reducer;
