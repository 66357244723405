import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import ProductList from "../components/products/ProductList";
import ProductAdd from "../components/products/ProductAdd";
import ProductEdit from "../components/products/ProductEdit";
import UserList from "../components/users/UserList";
import VendorList from "../components/vendor/VendorList";
import CustomerList from "../components/customers/CustomerList";
import Company1 from "../components/Company1";
import AddSales from "../components/sales/AddSales";
import SalesQuotation from "../components/sales/Sales";
import SignIn from "../components/Signin";
import SalesPayment from "../components/sales/Payment";
import AddPayment from "../components/sales/AddPayment";
import Stock from "../components/stock/Stock";
import AddStock from "../components/stock/AddStock";
import Reports from "../components/sales/Reports";
import Report from "../components/Purchase/Reports";
import PurchaseInvoice from "../components/Purchase/PurchaseInvoice";
import SalesInvoice from "../components/sales/SalesInvoice";
import SalesInvoiceList from "../components/sales/SalesInvoiceList";
import SalesInvoiceEdit from "../components/sales/SalesInvoiceEdit";
import Company from "../components/company/Company";
import Check from "../components/Company";
import SalesInvoiceReport from "../components/sales/SalesInvoiceReport";
const authProtectedRoutes = [

	{ path: "/userlist", component: UserList },
	{ path: "/vendorlist", component: VendorList },
	// { path: "/company1", component: Check },
	{ path: "/company", component: Company },
	{ path: "/sales/new", component: AddSales },
	{ path: "/sales/payment/new", component: AddPayment },
	{ path: "/customerlist", component: CustomerList },
	{ path: "/stock", component: Stock },
	{ path: "/sales", component: SalesQuotation },
	{ path: "/sales/payment", component: SalesPayment },
	{ path: "/stock/new", component: AddStock },
	{ path: "/sales_invoice", component: SalesInvoice },
	{ path: "/sales_invoicelist", component: SalesInvoiceList },
	{ path: "/sales_invoicelist/edit/:id", component: SalesInvoiceEdit },
	{ path: "/reports/sales", component: Reports },
	{ path: "/reports/purchase", component: Report },
	// { path: "/dashboard", component: Dashboard },
	// { path: "/login", component: Login },
	{ path: "/dashboard", component: Dashboard },
	{ path: "/product_add", component: ProductAdd },
	{ path: "/product_list", component: ProductList },
	{ path: "/product_edit", component: ProductEdit },
	{ path: "/purchase_invoice", component: PurchaseInvoice },
	{ path: "/product_list/edit/:productId", component: ProductEdit },
	{ path: "/sales_invoice_report", component: SalesInvoiceReport },
		{ path: "/", exact: true, component: () => <Navigate to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/login", component: SignIn },
	// { path: "/forgot-password", component: ForgetPwd },
	// { path: "/register", component: Register },
	// { path: "/lock-screen", component: AuthLockScreen },

	// // Authentication Inner
	// { path: "/auth-login", component: Login1 },
	// { path: "/auth-register", component: Register1 },
	// { path: "/auth-recoverpw", component: ForgetPwd1 },

	// { path: "/maintenance", component: Maintenance },
	// { path: "/comingsoon", component: CommingSoon },
	// { path: "/404", component: Error404 },
	// { path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
