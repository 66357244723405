import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";

import { customerValidationSchema } from "../../utils/Validation";

import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    Collapse,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
//Import Breadcrumb
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
    customerData,
    UpdateCustomer,
    DeleteCustomer,
    customersSelector,
    clearData,
    clearCustUpdateLoading,
    clearCustLoadingDatas,
    clearCustDeleteLoading,
    clearErrormsg,
} from "../../store/reducer/CustomerReducer";

const Stock = () => {
    const dispatch = useDispatch();
    let [sortBy, setSortBy] = useState("customer_name");
    let [activesortBy, setActiveSortBy] = useState("customer_name");
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [state, setState] = useState({ modal_fullscreen: false });
    const [accord, setAccord] = useState({
        col1: false,
        col2: false,
        col3: false,
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(customerValidationSchema),
    });
    const {
        customerDatas,
        customerCount,
        loading,
        addloading,
        deleteloading,
        updateloading,
        errorMessage,
    } = useSelector(customersSelector);
    const navigate = useNavigate()
    const [stockDtails, setStockDtails] = useState([{
        id: 1,
        product: 'Item',
        quantity: 5,
        location: "uk",
    }]);

    let totBillAmt = 0;
    for (let index = 0; index < stockDtails.length; index++) {
        const element = stockDtails[index];
        totBillAmt += element.bill_amount
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
    };


    const tog_fullscreen = () => {
        navigate('/stock/new')
    };


    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value);
        setPage(0);
    };

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
    };
    useEffect(() => {
        dispatch(
            customerData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    }, []);

    useEffect(() => {
        console.log(customerDatas, "customerDatas");
    }, [customerDatas]);
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    const breadcrumbItems = [
        { title: "Tables", link: "/" },
        { title: "Data Tables", link: "#" },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-content-between">
                        <h2>Stocks</h2>
                        <button
                            type="button"
                            className="waves-effect waves-light btn btn-primary mb-2"
                            onClick={tog_fullscreen}
                        >
                            Add
                        </button>
                    </div>
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4 row">
                                    <input
                                        type="text"
                                        name="search"
                                        className="form-control"
                                        style={{ float: "right" }}
                                        value={searchQuery}
                                        onChange={(e) => {
                                            searchData(e);
                                        }}
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                            <div className="table-responsive react-table">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead className="table-light table-nowrap">
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "product" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("product");
                                                        }}
                                                    >
                                                        Product
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "quantity" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("quantity");
                                                        }}
                                                    >
                                                        Quantity
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "location" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("location");
                                                        }}
                                                    >
                                                        Location
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                              
                                                <StyledTableCell align="">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {stockDtails &&
                                                stockDtails.map((row) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row?.product}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.quantity}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.location}
                                                        </StyledTableCell>
                                                       
                                                        <StyledTableCell align="" className="button-items">
                                                            <a
                                                                href="javascript:void(0);"
                                                                title="Edit"
                                                                className="btn btn-primary  "
                                                            >
                                                                <i className=" ri-ball-pen-line"></i>
                                                            </a>
                                                            <a
                                                                href="javascript:void(0);"
                                                                title="Delete"
                                                                className="btn btn-danger"
                                                            >
                                                                <i className="ri-delete-bin-line"></i>
                                                            </a>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={9}
                                                    count={stockDtails.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
};

export default Stock;
