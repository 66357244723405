import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import toastr from 'toastr'
import EncryptDecrypt, { decryptdata } from "./../../utils/encrypt&decrypt";
import 'toastr/build/toastr.min.css'
import { companyAdd, companyList } from '../../services/api';
import { secretKey } from '../../services/config';
const namespace = 'company'

const initialState = {
  company_details: null,
  status: 'idle',
  error: null,
};

export const postCompanyDetails = createAsyncThunk(
  `${namespace}/create`,
  async ( formdata , { rejectWithValue }) => {
    try {
      console.log(formdata,'formdata-->');
      const response = await companyAdd(formdata);
      console.log(response, 'response');
      toastr.success(response.data.message)
      return response;
    } catch (error) {
      toastr.error(error.message)
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyDetails = createAsyncThunk(
  `${namespace}/`,
  async (companyDetails, { rejectWithValue }) => {
    console.log(companyDetails, 'companyDetails');
    try {
      const response = await companyList(companyDetails);
      // toastr.success(response.data.message)
      console.log(response.data,'response.data');
      let payload = EncryptDecrypt.decryptdata(response.data.company_details, secretKey);
      return payload;
    } catch (error) {
      toastr.error(error.message)
      return rejectWithValue(error.response.data);
    }
  }
);

const companyDetailsSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postCompanyDetails.pending, (state) => {
        state.status = 'loading';
      });
    builder.addCase(postCompanyDetails.fulfilled, (state, action) => {
      state.status = 'succeeded';

    });
    builder.addCase(postCompanyDetails.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
    builder
      .addCase(getCompanyDetails.pending, (state) => {
        state.status = 'loading'
      });
    builder.addCase(getCompanyDetails.fulfilled, (state, {payload}) => {
      state.status = 'succeeded';
      console.log(payload,'payload');
      state.company_details = JSON.parse(payload)[0];
    });
    builder.addCase(getCompanyDetails.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});


export const companySelector = (state) => state.company;
export default companyDetailsSlice.reducer;
