import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { vendorValidationSchema } from "../../utils/Validation";

import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    Collapse,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
//Import Breadcrumb
import { products } from "../../common/data/ecommerce";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import Breadcrumb from "../Common/Breadcrumb";
import dayjs from "dayjs";
import {
    vendorData,
    UpdateVendor,
    EditVendor,
    DeleteVendor,
    AddVendor,
    vendorsSelector,
    clearData,
    clearVendorUpdateLoading,
    clearVendorAddLoading,
    clearVendorLoadingDatas,
    clearVendorDeleteLoading,
    clearErrormsg,
} from "../../store/reducer/VendorReducer";
import { API_STATUS } from "../../utils/constants";

const VendorList = () => {
    const dispatch = useDispatch();
    let [sortBy, setSortBy] = useState("vendor_name");
    let [activesortBy, setActiveSortBy] = useState("vendor_name");
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [state, setState] = useState({ modal_fullscreen: false });
    const [edit_state, setEditState] = useState({ edit_modal_fullscreen: false });
    const MySwal = withReactContent(Swal);
    const [accord, setAccord] = useState({
        col1: false,
        col2: false,
        col3: false,
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        resolver: yupResolver(vendorValidationSchema),
    });
    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        control: control2,
        reset: reset2,
    } = useForm({
        resolver: yupResolver(vendorValidationSchema),
    });
    const {
        vendorDatas,
        vendorCount,
        loading,
        addloading,
        deleteloading,
        updateloading,
        vendor_details,
        editloading,
        errorMessage,
    } = useSelector(vendorsSelector);

console.log(vendor_details,"vendor_details");
    const countries = [
        { id: 1, name: "Saudi Arabia" },
        { id: 2, name: "Qatar" },
    ]

    const currency = [
        { id: 1, name: "SAR" },
        { id: 2, name: "AED" },
    ]

    const payment = [
        { id: 1, name: "Cash" },
        { id: 2, name: "Credit" },
    ]
    const _ = require("lodash");
    const SelectedCountry = (countrId) => {
        return _.filter(countries, ['id', countrId]);
    }
    const SelectedCurrency = (currencyId) => {
        return _.filter(currency, ['id', currencyId]);
    }

    const SelectedPayment = (paymentId) => {
        return _.filter(payment, ['id', paymentId]);
    }

    const [selectedCountryVal, setCountry] = useState([]);
    const [selectedCurrencyVal, setCurrency] = useState([]);
    const [selectedPaymentVal, setPayment] = useState([]);

    const handleChange = event => {
        setCountry(event);
    };

    const handleCurrencyChange = event => {
        setCurrency(event);

    };
    const handlePayChange = event => {
        setPayment(event);
    };
    useEffect(() => {
        setCountry(SelectedCountry(vendor_details?.country_name))
        setCurrency(SelectedCurrency(vendor_details?.currency_type))
        setPayment(SelectedPayment(vendor_details?.payment_method))
    }, [vendor_details])

    

    const [vendorDetails, setVendorDetails] = useState({
        id: 1,
        vendor_name: "",
        vendor_name_arabic: "",
        contact_person: "",
        contact_number: "",
        contact_email: null,
        country_name: null,
        currency_type: "",
        payment_method: "",
        pay_payments_from: "",
        vendor_type: "",
        partner_type: "",
        cr_no: "",
        reminder: "",
        vat_no: "",
        address: "",
        zip_code: null,
        city: "",
        fax: "",
    });

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
    };

    const tog_fullscreen = () => {
        setState((prevState) => ({
            modal_fullscreen: !prevState.modal_fullscreen,
        }));
        removeBodyCss();
    };
    const edit_tog_fullscreen = (row) => {
        setEditState((prevState) => ({
            edit_modal_fullscreen: !prevState.edit_modal_fullscreen,
        }));
        const vendorID = row.id;
        dispatch(EditVendor({ vendorID }));
        removeBodyCss();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const handleInput = (e, dest, type) => {
        const { value } = e.target;
        console.log(value);
        vendorDetails[dest] = type !== "" ? Number(value) : value;
        setVendorDetails({
            ...vendorDetails,
        });
    };
    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value);
        setPage(0);
        vendorDispatch();
    };

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        vendorDispatch();
    };
    useEffect(() => {
        vendorDispatch();
    }, []);

    const vendorDispatch = () => {
        dispatch(
            vendorData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };
    useEffect(() => {
        if (vendor_details) {
            reset2(vendor_details);
        }
    }, [vendor_details])


    useEffect(() => {
        if (addloading === API_STATUS.FULFILLED) {
            toastr.clear();
            toastr.success("Vendor Added Successfully!");
            vendorDispatch();
            dispatch(clearVendorLoadingDatas());
            setState((prevState) => ({
                modal_fullscreen: !prevState.modal_fullscreen,
            }));
        }
        if (updateloading === API_STATUS.FULFILLED) {
            toastr.clear();
            toastr.success("Vendor Updated Successfully!");
            vendorDispatch();
            dispatch(clearVendorLoadingDatas());
            reset2();
            setEditState((prevState) => ({
                edit_modal_fullscreen: !prevState.edit_modal_fullscreen,
            }));
        }
        if (deleteloading === API_STATUS.FULFILLED) {
            toastr.clear();
            toastr.success("Vendor Deleted Successfully!");
            vendorDispatch();
            dispatch(clearVendorLoadingDatas());
        }
        if (errorMessage) {
            toastr.clear();
            if (errorMessage.message) toastr.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                toastr.error(data);
            }
            dispatch(clearErrormsg());
        }
    }, [addloading, errorMessage, deleteloading]);

    const t_col1 = () => {
        setAccord({ col1: !accord.col1, col2: false, col3: false });
    };
    const t_col2 = () => {
        setAccord({ col1: false, col2: !accord.col2, col3: false });
    };

    const onSubmitHandler = (formData) => {
        console.log({ formData });
        dispatch(AddVendor({ formData }));
        // reset();
    };
    const onEditSubmitHandler = (formData) => {
        const updateID = vendor_details.id;
        formData.country_name = selectedCountryVal[0]?.id;
        formData.currency_type = selectedCurrencyVal[0]?.id;
        formData.payment_method = selectedPaymentVal[0]?.id;
        formData.phone = (formData?.phone.toString());
        console.log(formData, 'FormData');
        dispatch(UpdateVendor({ formData, updateID }));
        reset2();
    };
    useEffect(() => {
        console.log(errors);
    }, [errors]);
    /** Vendor Delete */
    const deleteVendor = (row) => {
        //setshowAdd(true);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: "Are You Sure to Delete the Vendor?",
            icon: "warning",
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(DeleteVendor({ deleteID }));
            }
        });
    };
    const breadcrumbItems = [
        { title: "Tables", link: "/" },
        { title: "Data Tables", link: "#" },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-content-between">
                        <h2>Vendors</h2>
                        <button
                            type="button"
                            className="waves-effect waves-light btn btn-primary mb-2"
                            onClick={tog_fullscreen}
                        > Add </button>
                    </div>
                    <Card>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-8 float-right page-title-right">
                                        <input
                                            type="text"
                                            name="search"
                                            className="form-control"
                                            value={searchQuery}
                                            onChange={(e) => {
                                                searchData(e);
                                            }}
                                            placeholder="Search Vendor"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive react-table">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead className="table-light table-nowrap">
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "vendor_name" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("vendor_name");
                                                        }}
                                                    >
                                                        Name
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "contact_person" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("contact_person");
                                                        }}
                                                    >
                                                        Contact Person
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "contact_number" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("contact_number");
                                                        }}
                                                    >
                                                        Mobile
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "contact_email" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("contact_email");
                                                        }}
                                                    >
                                                        Email
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "status" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("status");
                                                        }}
                                                    >
                                                        Status
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "created_at" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("created_at");
                                                        }}
                                                    >
                                                        Created At
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {vendorDatas &&
                                                vendorDatas.map((row) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row?.vendor_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.contact_person}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.contact_number}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.contact_email}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.status === 1 ? "Active" : "In-active"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {dayjs(row.created_at).format(
                                                                "MM-DD-YYYY hh:mm A"
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="" className="button-items">
                                                            <a
                                                                href="javascript:void(0);"
                                                                title="Edit"
                                                                className="btn btn-primary  "
                                                                onClick={(e) => { edit_tog_fullscreen(row) }}
                                                            >
                                                                <i className=" ri-ball-pen-line"></i>
                                                            </a>
                                                            <a
                                                                href="javascript:void(0);"
                                                                title="Delete"
                                                                className="btn btn-danger"
                                                                onClick={(e) => { deleteVendor(row) }}
                                                            >
                                                                <i className="ri-delete-bin-line"></i>
                                                            </a>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={8}
                                                    count={vendorCount}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>

                            <Col sm={6} md={4} xl={3} className="mt-4">
                                <Modal
                                    size="xl"
                                    isOpen={state.modal_fullscreen}
                                    toggle={tog_fullscreen}
                                >
                                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                                        <div className="modal-header">
                                            <h5
                                                className="modal-title mt-0"
                                                id="exampleModalFullscreenLabel"
                                            >
                                                Vendor
                                            </h5>
                                            <button
                                                onClick={() => setState({ modal_fullscreen: false })}
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="vendor_name"
                                                            name="vendor_name"
                                                            {...register("vendor_name")}
                                                            placeholder=" "
                                                        />
                                                        <label for="vendor_name" className="required">Vendor Name</label>
                                                    </div>
                                                    {errors?.vendor_name?.message && (
                                                        <p className="error">
                                                            {errors.vendor_name.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="vendor_name_arabic"
                                                            name="vendor_name_arabic"
                                                            {...register("vendor_name_arabic")}
                                                            placeholder=" "
                                                        />
                                                        <label for="vendor_name_arabic">
                                                            Vendor Name (Arabic)
                                                        </label>
                                                    </div>
                                                    {errors?.vendor_name_arabic?.message && (
                                                        <p className="error">
                                                            {errors.vendor_name_arabic.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="contact_person"
                                                            name="contact_person"
                                                            {...register("contact_person")}
                                                            placeholder=" "
                                                        />
                                                        <label for="contact_person">Contact Person</label>
                                                    </div>
                                                    {errors?.contact_person?.message && (
                                                        <p className="error">
                                                            {errors.contact_person.message}
                                                        </p>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="contact_number"
                                                            name="contact_number"
                                                            {...register("contact_number")}
                                                            placeholder=" "
                                                        />
                                                        <label for="contact_number">Contact Number</label>
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="fax"
                                                            name="fax"
                                                            {...register("fax")}
                                                            placeholder=" "
                                                        />
                                                        <label for="fax">Fax</label>
                                                    </div>
                                                    {errors?.fax?.message && (
                                                        <p className="error">
                                                            {errors.fax.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="contact_email"
                                                            name="contact_email"
                                                            {...register("contact_email")}
                                                            placeholder=" "
                                                        />
                                                        <label for="contact_email">Email</label>
                                                    </div>
                                                    {errors?.contact_email?.message && (
                                                        <p className="error">
                                                            {errors.contact_email.message}
                                                        </p>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <Controller
                                                            name="country_name"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder="Country"
                                                                    options={[
                                                                        { id: 1, name: "Saudi Arabia" },
                                                                        { id: 2, name: "Qatar" },
                                                                    ]}
                                                                    {...field}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="country_name"
                                                                />
                                                            )}
                                                        />
                                                        {/* <label for="country">Country</label> */}
                                                    </div>
                                                    {errors?.country?.message && (
                                                        <p className="error">{errors.country.message}</p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <Controller
                                                            name="currency_type"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    className="js-states select-control"
                                                                    placeholder="Currency Type"
                                                                    options={[
                                                                        { id: 1, name: "SAR" },
                                                                        { id: 2, name: "AED" },
                                                                    ]}
                                                                    values={[]}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                />
                                                            )}
                                                        />
                                                        {/* <label for="currency_type">Currency Type</label> */}
                                                    </div>
                                                    {errors?.currency_type?.message && (
                                                        <p className="error">
                                                            {errors.currency_type.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <Controller
                                                            name="payment_method"
                                                            control={control}
                                                            placeholder="Payment Method"
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder="Payment Method"
                                                                    options={[
                                                                        { id: 1, name: "Cash" },
                                                                        { id: 2, name: "Credit" },
                                                                    ]}
                                                                    values={[]}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="payment_method"
                                                                />
                                                            )}
                                                        />
                                                        {/* <label for="payment_method">Payment Method</label> */}
                                                    </div>
                                                    {errors?.payment_method?.message && (
                                                        <p className="error">
                                                            {errors.payment_method.message}
                                                        </p>
                                                    )}
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="web_address"
                                                            name="web_address"
                                                            {...register("web_address")}
                                                            placeholder=" "
                                                        />
                                                        <label for="web_address">Website</label>
                                                    </div>
                                                    {errors?.web_address?.message && (
                                                        <p className="error">
                                                            {errors.web_address.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="pay_payments_from"
                                                            name="pay_payments_from"
                                                            {...register("pay_payments_from")}
                                                            placeholder=" "
                                                        />
                                                        <label for="pay_payments_from">Pay All Payments From</label>
                                                    </div>
                                                    {errors?.pay_payments_from?.message && (
                                                        <p className="error">
                                                            {errors.pay_payments_from.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4" className="">
                                                    <div className="input-container">
                                                        <label className="form-check-label label" htmlFor="cash_pay_status" style={{ paddingLeft: "20px", marginTop: '4px', marginRight: '10px' }}> Cash Pay</label>
                                                        <input className="form-check-input" type="checkbox" value="1" id="cash_pay_status" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="vendor_type"
                                                            name="vendor_type"
                                                            {...register("vendor_type")}
                                                        />
                                                        <label for="vendor_type">Vendor Type</label>
                                                    </div>
                                                    {errors?.vendor_type?.message && (
                                                        <p className="error">
                                                            {errors.vendor_type.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="partner_type"
                                                            name="partner_type"
                                                            {...register("partner_type")}
                                                            placeholder=" "
                                                        />
                                                        <label for="partner_type">
                                                            Partner Type
                                                        </label>
                                                    </div>
                                                    {errors?.partner_type?.message && (
                                                        <p className="error">
                                                            {errors.partner_type.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="cr_no"
                                                            name="cr_no"
                                                            {...register("cr_no")}
                                                            placeholder=" "
                                                        />
                                                        <label for="cr_no">
                                                            CR Nnumber
                                                        </label>
                                                    </div>
                                                    {errors?.cr_no?.message && (
                                                        <p className="error">
                                                            {errors.cr_no.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            onChange={(e) => handleInput(e, "vat_no", "")}
                                                            type="text"
                                                            id="vat_no"
                                                            name="vat_no"
                                                            {...register("vat_no")}
                                                            placeholder=" "
                                                        />
                                                        <label for="vat_no">VAT No</label>
                                                    </div>
                                                    {errors?.vat_no?.message && (
                                                        <p className="error">{errors.vat_no.message}</p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            onChange={(e) => handleInput(e, "remainder", "")}
                                                            type="text"
                                                            id="remainder"
                                                            name="remainder"
                                                            {...register("remainder")}
                                                            placeholder=" "
                                                        />
                                                        <label for="remainder">Remainder</label>
                                                    </div>
                                                    {errors?.remainder?.message && (
                                                        <p className="error">{errors.remainder.message}</p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div className="input-container">
                                                        <label className="form-check-label label" htmlFor="inactive_status" style={{ paddingLeft: "20px", marginTop: '4px', marginRight: '10px' }}>
                                                            Inactive
                                                        </label>
                                                        <input className="form-check-input" type="checkbox" value="0" id="inactive_status" />
                                                    </div>
                                                </Col>
                                                <Row>
                                                    <Col md="6">
                                                        <div class="input-container">
                                                            <textarea
                                                                type="text"
                                                                id="address"
                                                                name="address"
                                                                {...register("address")}
                                                                rows={1}
                                                                placeholder=" "
                                                            />
                                                            <label for="address">Address</label>
                                                        </div>
                                                        {errors?.address?.message && (
                                                            <p className="error">
                                                                {errors.address.message}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col md="6">
                                                        <Row>
                                                            <Col md="6">
                                                                <div class="input-container">
                                                                    <input
                                                                        type="text"
                                                                        id="zip_code"
                                                                        name="zip_code"
                                                                        {...register("zip_code")}
                                                                        placeholder=" "
                                                                    />
                                                                    <label for="zip_code">Zipcode</label>
                                                                </div>
                                                                {errors?.zip_code?.message && (
                                                                    <p className="error">
                                                                        {errors.zip_code.message}
                                                                    </p>
                                                                )}
                                                            </Col>
                                                            <Col md="6">
                                                                <div class="input-container">
                                                                    <input
                                                                        type="text"
                                                                        id="phone"
                                                                        name="phone"
                                                                        {...register("phone")}
                                                                        placeholder=" "
                                                                    />
                                                                    <label for="phone">Phone</label>
                                                                </div>
                                                                {errors?.phone?.message && (
                                                                    <p className="error">
                                                                        {errors.phone.message}
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                onClick={() => setState({ modal_fullscreen: false })}
                                                className="btn btn-secondary "
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button type="submit" className="btn btn-primary ">
                                                Save changes
                                            </button>
                                        </div>
                                    </form>
                                </Modal>
                            </Col>

                            {/* Vendor Edit */}
                            <Col sm={6} md={4} xl={3} className="mt-4">
                                <Modal
                                    size="xl"
                                    isOpen={edit_state.edit_modal_fullscreen}
                                    toggle={edit_tog_fullscreen}
                                >
                                    <form onSubmit={handleSubmit2(onEditSubmitHandler)}>
                                        <div className="modal-header">
                                            <h5
                                                className="modal-title mt-0"
                                                id="exampleModalFullscreenLabel"
                                            >
                                                Vendor Edit
                                            </h5>
                                            <button
                                                onClick={() => setEditState({ edit_modal_fullscreen: false })}
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_vendor_name"                                                            
                                                            defaultValue={vendor_details?.vendor_name}
                                                            {...register2("vendor_name")}
                                                            placeholder=" "
                                                        />
                                                        <label for="vendor_name" className="required">Vendor Name</label>
                                                    </div>
                                                    {errors?.vendor_name?.message && (
                                                        <p className="error">
                                                            {errors.vendor_name.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_vendor_name_arabic"
                                                            name="vendor_name_arabic"
                                                            defaultValue={vendor_details?.vendor_name_arabic}
                                                            {...register2("vendor_name_arabic")}
                                                            placeholder=" "
                                                        />
                                                        <label for="vendor_name_arabic">
                                                            Vendor Name (Arabic)
                                                        </label>
                                                    </div>
                                                    {errors?.vendor_name_arabic?.message && (
                                                        <p className="error">
                                                            {errors.vendor_name_arabic.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_contact_person"
                                                            name="contact_person"
                                                            defaultValue={vendor_details?.contact_person}
                                                            {...register2("contact_person")}
                                                            placeholder=" "
                                                        />
                                                        <label for="contact_person">Contact Person</label>
                                                    </div>
                                                    {errors?.contact_person?.message && (
                                                        <p className="error">
                                                            {errors.contact_person.message}
                                                        </p>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_contact_number"
                                                            name="contact_number"
                                                            defaultValue={vendor_details?.contact_number}
                                                            {...register2("contact_number")}
                                                            placeholder=" "
                                                        />
                                                        <label for="contact_number">Contact Number</label>
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_fax"
                                                            name="fax"
                                                            defaultValue={vendor_details?.fax}
                                                            {...register2("fax")}
                                                            placeholder=" "
                                                        />
                                                        <label for="fax">Fax</label>
                                                    </div>
                                                    {errors?.fax?.message && (
                                                        <p className="error">
                                                            {errors.fax.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_contact_email"
                                                            name="contact_email"
                                                            defaultValue={vendor_details?.contact_email}
                                                            {...register2("contact_email")}
                                                            placeholder=" "
                                                        />
                                                        <label for="contact_email">Email</label>
                                                    </div>
                                                    {errors?.contact_email?.message && (
                                                        <p className="error">
                                                            {errors.contact_email.message}
                                                        </p>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <Controller
                                                            name="country_name"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder="Country"
                                                                    options={countries}
                                                                    {...field}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    values={selectedCountryVal}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="country_name"
                                                                    onChange={handleChange}
                                                                />
                                                            )}
                                                        />
                                                        {/* <label for="country">Country</label> */}
                                                    </div>
                                                    {errors?.country?.message && (
                                                        <p className="error">{errors.country.message}</p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <Controller
                                                            name="currency_type"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    className="js-states select-control"
                                                                    placeholder="Currency Type"
                                                                    options={currency}
                                                                    {...field}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    values={selectedCurrencyVal}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="currency_name"
                                                                    onChange={handleCurrencyChange}
                                                                />
                                                            )}
                                                        />
                                                        {/* <label for="currency_type">Currency Type</label> */}
                                                    </div>
                                                    {errors?.currency_type?.message && (
                                                        <p className="error">
                                                            {errors.currency_type.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <Controller
                                                            name="payment_method"
                                                            control={control}
                                                            placeholder="Payment Method"
                                                            render={({ field }) => (
                                                                <Select
                                                                    className="js-states select-control"
                                                                    placeholder="Payment Method"
                                                                    options={payment}
                                                                    {...field}
                                                                    disabled={false}
                                                                    dropdownHandle={true}
                                                                    values={selectedPaymentVal}
                                                                    searchable={true}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    name="payment_method"
                                                                    onChange={handlePayChange}
                                                                />
                                                            )}
                                                        />
                                                        {/* <label for="payment_method">Payment Method</label> */}
                                                    </div>
                                                    {errors?.payment_method?.message && (
                                                        <p className="error">
                                                            {errors.payment_method.message}
                                                        </p>
                                                    )}
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_web_address"
                                                            name="web_address"
                                                            {...register2("web_address")}
                                                            defaultValue={vendor_details?.web_address}
                                                            placeholder=" "
                                                        />
                                                        <label for="web_address">Website</label>
                                                    </div>
                                                    {errors?.web_address?.message && (
                                                        <p className="error">
                                                            {errors.web_address.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_pay_payments_from"
                                                            name="pay_payments_from"
                                                            {...register2("pay_payments_from")}
                                                            defaultValue={vendor_details?.pay_payments_from}
                                                            placeholder=" "
                                                        />
                                                        <label for="pay_payments_from">Pay All Payments From</label>
                                                    </div>
                                                    {errors?.pay_payments_from?.message && (
                                                        <p className="error">
                                                            {errors.pay_payments_from.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4" className="">
                                                    <div className="input-container">
                                                        <label className="form-check-label label" htmlFor="cash_pay_status" style={{ paddingLeft: "20px", marginTop: '4px', marginRight: '10px' }}> Cash Pay</label>
                                                        <input className="form-check-input" type="checkbox" value="1" id="edit_cash_pay_status" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_vendor_type"
                                                            name="vendor_type"
                                                            {...register2("vendor_type")}
                                                            defaultValue={vendor_details?.vendor_type}
                                                            placeholder=""
                                                        />
                                                        <label for="vendor_type">Vendor Type</label>
                                                    </div>
                                                    {errors?.vendor_type?.message && (
                                                        <p className="error">
                                                            {errors.vendor_type.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_partner_type"
                                                            name="partner_type"
                                                            {...register2("partner_type")}
                                                            defaultValue={vendor_details?.partner_type}
                                                            placeholder=" "
                                                        />
                                                        <label for="partner_type">
                                                            Partner Type
                                                        </label>
                                                    </div>
                                                    {errors?.partner_type?.message && (
                                                        <p className="error">
                                                            {errors.partner_type.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            type="text"
                                                            id="edit_cr_no"
                                                            name="cr_no"
                                                            {...register2("cr_no")}
                                                            defaultValue={vendor_details?.cr_no}
                                                            placeholder=" "
                                                        />
                                                        <label for="cr_no">
                                                            CR Nnumber
                                                        </label>
                                                    </div>
                                                    {errors?.cr_no?.message && (
                                                        <p className="error">
                                                            {errors.cr_no.message}
                                                        </p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            onChange={(e) => handleInput(e, "vat_no", "")}
                                                            type="text"
                                                            id="edit_vat_no"
                                                            name="vat_no"
                                                            {...register2("vat_no")}
                                                            defaultValue={vendor_details?.vat_no}
                                                            placeholder=" "
                                                        />
                                                        <label for="vat_no">VAT No</label>
                                                    </div>
                                                    {errors?.vat_no?.message && (
                                                        <p className="error">{errors.vat_no.message}</p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div class="input-container">
                                                        <input
                                                            onChange={(e) => handleInput(e, "remainder", "")}
                                                            type="text"
                                                            id="edit_remainder"
                                                            name="remainder"
                                                            {...register2("remainder")}
                                                            defaultValue={vendor_details?.remainder}
                                                            placeholder=" "
                                                        />
                                                        <label for="remainder">Remainder</label>
                                                    </div>
                                                    {errors?.remainder?.message && (
                                                        <p className="error">{errors.remainder.message}</p>
                                                    )}
                                                </Col>
                                                <Col md="4">
                                                    <div className="input-container">
                                                        <label className="form-check-label label" htmlFor="inactive_status" style={{ paddingLeft: "20px", marginTop: '4px', marginRight: '10px' }}>
                                                            Inactive
                                                        </label>
                                                        <input className="form-check-input" type="checkbox" value="0" id="edit_inactive_status" />
                                                    </div>
                                                </Col>
                                                <Row>
                                                    <Col md="6">
                                                        <div class="input-container">
                                                            <textarea
                                                                type="text"
                                                                id="edit_address"
                                                                name="address"
                                                                {...register2("address")}
                                                                defaultValue={vendor_details?.address}
                                                                rows={1}
                                                                placeholder=" "
                                                            />
                                                            <label for="address">Address</label>
                                                        </div>
                                                        {errors?.address?.message && (
                                                            <p className="error">
                                                                {errors.address.message}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col md="6">
                                                        <Row>
                                                            <Col md="6">
                                                                <div class="input-container">
                                                                    <input
                                                                        type="text"
                                                                        id="edit_zip_code"
                                                                        name="zip_code"
                                                                        {...register2("zip_code")}
                                                                        defaultValue={vendor_details?.zip_code}
                                                                        placeholder=" "
                                                                    />
                                                                    <label for="zip_code">Zipcode</label>
                                                                </div>
                                                                {errors?.zip_code?.message && (
                                                                    <p className="error">
                                                                        {errors.zip_code.message}
                                                                    </p>
                                                                )}
                                                            </Col>
                                                            <Col md="6">
                                                                <div class="input-container">
                                                                    <input
                                                                        type="text"
                                                                        id="edit_phone"
                                                                        name="phone"
                                                                        {...register2("phone")}
                                                                        defaultValue={vendor_details?.phone}
                                                                        placeholder=" "
                                                                    />
                                                                    <label for="phone">Phone</label>
                                                                </div>
                                                                {errors?.phone?.message && (
                                                                    <p className="error">
                                                                        {errors.phone.message}
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                onClick={() => setEditState({ edit_modal_fullscreen: false })}
                                                className="btn btn-secondary "
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button type="submit" className="btn btn-primary ">
                                                Update changes
                                            </button>
                                        </div>
                                    </form>
                                </Modal>
                            </Col>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    );
};

export default VendorList;
