import ReactApexChart from "react-apexcharts"
import React, { Component, useEffect, useState } from "react";


const SalesPieChart = ({ props }) => {
    console.log(props, 'props')
    const [Options, setOptions] = useState({
        labels: [],
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10
        },
        responsive: [{
            breakpoint: 600,
            options: {
                chart: {
                    height: 240
                },
                legend: {
                    show: false
                },
            }
        }],
    })
    const [Series, setSeries] = useState([])


    useEffect(() => {
        setOptions({
            ...Options,
            labels: props.map((items) => {
                return items.name
            })
        })
        setSeries(
            props.map((items) => {
                return items.amount
            })
        )
    }, [props])
    console.log(Options, 'Options')
    return (
        <>
            <ReactApexChart options={Options} series={Series} type="pie" height="320" />
        </>
    )
}

export default SalesPieChart;