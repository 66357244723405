import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import Layout from "./components/layout/Layout";
import Company from "./components/Company";
import ProductAdd from "./components/products/ProductAdd";
import ProductEdit from "./components/products/ProductEdit";
import ProductList from "./components/products/ProductList";
import "./assets/scss/theme.scss";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppRoute />}>
          {authProtectedRoutes.map((route, idx) => (
            <Route exact path={route.path} element={<route.component />} />
          ))}
        </Route>
        {publicRoutes.map((route, idx) => (
          <Route exact path={route.path} element={<route.component />} />
        ))}
        <Route path="*" element={<div className="text-center d-flex justify-content-center align-items-center s100vh"> <b> Not Found !! </b></div>} />
      </Routes>
    </Router>
  );
}

export default App;
